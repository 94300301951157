/*
 *   INSPINIA - Responsive Admin Theme
 *   Copyright 2014 Webapplayers.com
 */
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(../../assets/fonts/DXI1ORHCpsQm3Vp6mXoaTa-j2U0lmluP9RWlSytm3ho.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(../../assets/fonts/DXI1ORHCpsQm3Vp6mXoaTZX5f-9o1vgP2EXwfjgl7AY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(../../assets/fonts/DXI1ORHCpsQm3Vp6mXoaTRWV49_lSm1NYrwo-zkhivY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(../../assets/fonts/DXI1ORHCpsQm3Vp6mXoaTaaRobkAwv3vxw3jMhVENGA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(../../assets/fonts/DXI1ORHCpsQm3Vp6mXoaTf8zf_FOSsgRmwsS7Aa9k2w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(../../assets/fonts/DXI1ORHCpsQm3Vp6mXoaTT0LW-43aMEzIO6XUTLjad8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(../../assets/fonts/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(../../assets/fonts/K88pR3goAWT7BTt32Z01mxJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(../../assets/fonts/RjgO7rYTmqiVp7vzi-Q5URJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(../../assets/fonts/LWCjsQkB6EMdfHrEVqA1KRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(../../assets/fonts/xozscpT2726on7jbcb_pAhJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(../../assets/fonts/59ZRklaO5bWGqF5A9baEERJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(../../assets/fonts/u-WUoqrET9fUeobQW7jkRRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(../../assets/fonts/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(../../assets/fonts/MTP_ySUJH_bn48VBG8sNSq-j2U0lmluP9RWlSytm3ho.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(../../assets/fonts/MTP_ySUJH_bn48VBG8sNSpX5f-9o1vgP2EXwfjgl7AY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(../../assets/fonts/MTP_ySUJH_bn48VBG8sNShWV49_lSm1NYrwo-zkhivY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(../../assets/fonts/MTP_ySUJH_bn48VBG8sNSqaRobkAwv3vxw3jMhVENGA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(../../assets/fonts/MTP_ySUJH_bn48VBG8sNSv8zf_FOSsgRmwsS7Aa9k2w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(../../assets/fonts/MTP_ySUJH_bn48VBG8sNSj0LW-43aMEzIO6XUTLjad8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(../../assets/fonts/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(../../assets/fonts/k3k702ZOKiLJc3WVjuplzK-j2U0lmluP9RWlSytm3ho.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(../../assets/fonts/k3k702ZOKiLJc3WVjuplzJX5f-9o1vgP2EXwfjgl7AY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(../../assets/fonts/k3k702ZOKiLJc3WVjuplzBWV49_lSm1NYrwo-zkhivY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(../../assets/fonts/k3k702ZOKiLJc3WVjuplzKaRobkAwv3vxw3jMhVENGA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(../../assets/fonts/k3k702ZOKiLJc3WVjuplzP8zf_FOSsgRmwsS7Aa9k2w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(../../assets/fonts/k3k702ZOKiLJc3WVjuplzD0LW-43aMEzIO6XUTLjad8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(../../assets/fonts/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/** SF Pro Text Regular */
@font-face {
  font-family: "SF Display Light";
  src: url(../../fonts/SFProText-Regular.woff2);
}

/** SF Pro Text Medium */
@font-face {
  font-family: "SF Display Medium";
  src: url(../../fonts/SFProText-Medium.woff2);
}

/** SF Pro Display Bold */
@font-face {
  font-family: "SF Display Bold";
  src: url(../../fonts/SFProDisplay-Bold.woff2);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;
}

.nav > li > a {
  color: #a7b1c2;
  font-weight: 600;
  padding: 14px 20px 14px 25px;
}

.nav.navbar-right > li > a {
  color: #999c9e;
}

.nav > li.active > a {
  color: white;
}

.navbar-default .nav > li > a:hover, .navbar-default .nav > li > a:focus {
  background-color: #293846;
  color: white;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background: #fff;
}

.nav.navbar-top-links > li > a:hover, .nav.navbar-top-links > li > a:focus {
  background-color: transparent;
}

.nav > li > a i {
  margin-right: 6px;
}

.navbar {
  border: 0;
}

.navbar-default {
  background-color: transparent;
  border-color: #2f4050;
}

.navbar-top-links li {
  display: inline-block;
}

.navbar-top-links li:last-child {
  margin-right: 20px;
}

.navbar-top-links li a {
  padding: 15px 10px;
  min-height: 50px;
}

.dropdown-menu {
  border: medium none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  display: none;
  float: left;
  font-size: 12px;
  left: 0;
  list-style: none outside none;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
  border-radius: 2px;
}

.search-dropdown-list-and-button .dropdown-menu > li > a {
  border-radius: 3px;
  color: inherit;
  line-height: 25px;
  margin: 4px;
  text-align: left;
  font-weight: normal;
}

.dropdown-menu > li > a {
  border-radius: 3px;
  color: inherit;
  line-height: 25px;
  margin: 4px;
  text-align: left;
  font-weight: normal;
}

.dropdown-menu > li > a.font-bold {
  font-weight: 600;
}

.navbar-top-links .dropdown-menu li {
  display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
  width: 310px;
  min-width: 0;
}

.navbar-top-links .dropdown-messages {
  margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
}

.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
}

.dropdown-messages, .dropdown-alerts {
  padding: 10px 10px 10px 10px;
}

.dropdown-messages li a, .dropdown-alerts li a {
  font-size: 12px;
}

.dropdown-messages li em, .dropdown-alerts li em {
  font-size: 10px;
}

.nav.navbar-top-links .dropdown-alerts a {
  font-size: 12px;
}

.nav-header {
  padding: 33px 25px;
}

.pace-done .nav-header {
  transition-duration: 0.5s;
}

.nav > li.active {
  border-left: 4px solid #19aa8d;
  background: #293846;
}

.nav.nav-second-level > li.active {
  border: none;
}

.nav.nav-second-level.collapse[style] {
  height: auto !important;
}

.nav-header a {
  color: #dfe4ed;
}

.nav-header .text-muted {
  color: #8095a8;
}

.minimalize-styl-2 {
  padding: 4px 12px;
  margin: 14px 5px 5px 20px;
  font-size: 14px;
  float: left;
}

.navbar-form-custom {
  float: left;
  height: 50px;
  padding: 0;
  width: 200px;
  display: inline-table;
}

.navbar-form-custom .form-group {
  margin-bottom: 0;
}

.nav.navbar-top-links a {
  font-size: 14px;
}

.nav.navbar-top-links a.logout-icon {
  font-size: 20px;
}

.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  font-size: 14px;
  height: 60px;
  margin: 0;
  z-index: 2000;
}

.count-info .label {
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 6px;
  top: 5px;
}

.arrow {
  float: right;
}

.fa.arrow:before {
  content: "\f104";
}

.active > a > .fa.arrow:before {
  content: "\f107";
}

.nav-second-level li,
.nav-third-level li {
  border-bottom: none !important;
}

.nav-second-level li a {
  padding: 7px 10px 7px 10px;
  padding-left: 52px;
}

.nav-third-level li a {
  padding-left: 62px;
}

.nav-second-level li:last-child {
  margin-bottom: 10px;
}

body:not(.fixed-sidebar).mini-navbar .nav li:hover > .nav-second-level, .mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  border-radius: 0 2px 2px 0;
  min-width: 140px;
  height: auto;
}

body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 12px;
  border-radius: 3px;
}

.fixed-nav .slimScrollDiv #side-menu {
  padding-bottom: 60px;
}

.mini-navbar .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}

.mini-navbar .nav-second-level {
  position: absolute;
  left: 70px;
  background-color: #2f4050 !important;
  font-size: 12px;
  z-index: 1;
  padding: 10px 0px 0px 0px;
  bottom: 0%;
}

.mini-navbar li.active .nav-second-level {
  left: 65px;
}

.navbar-default .special_link a {
  background: #1ab394;
  color: white;
}

.navbar-default .special_link a:hover {
  background: #17987e !important;
  color: white;
}

.navbar-default .special_link a span.label {
  background: white;
  color: #1ab394;
}

.navbar-default .landing_link a {
  background: #1cc09f;
  color: white;
}

.navbar-default .landing_link a:hover {
  background: #1ab394 !important;
  color: white;
}

.navbar-default .landing_link a span.label {
  background: white;
  color: #1cc09f;
}

.logo-element {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  display: none;
  padding: 18px 0;
}

.pace-done .navbar-static-side, .pace-done .nav-header, .pace-done li.active, .pace-done #page-wrapper, .pace-done .footer {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.navbar-fixed-top {
  background: white;
  transition-duration: 0.5s;
  border-bottom: 1px solid #e7eaec !important;
}

.navbar-fixed-top, .navbar-static-top {
  background: #f3f3f4;
}

.fixed-nav #wrapper {
  margin-top: 60px;
}

.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}

.body-small .navbar-fixed-top {
  margin-left: 0px;
}

body.mini-navbar .navbar-static-side {
  width: 70px;
}

body.mini-navbar .nav-label, body.mini-navbar .navbar-default .nav li a span {
  display: none;
}

body.mini-navbar .nav-label, body.mini-navbar .navbar-default .nav li a span.label {
  display: inline;
}

body.mini-navbar .profile-element {
  padding: 8px;
}

body.mini-navbar .profile-element .profile-photo {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.profile-element .profile-photo {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

body:not(.fixed-sidebar).mini-navbar .nav-second-level {
  display: none;
}

body.mini-navbar .navbar-default .nav > li > a {
  font-size: 16px;
}

body.mini-navbar .logo-element {
  display: block;
}

body.mini-navbar .nav-header-logo {
  padding: 0;
  background-color: #1ab394;
}

body.mini-navbar .nav-header {
  padding: 0;
}

body.mini-navbar #page-wrapper {
  margin: 0 0 0 70px;
}

body.fixed-sidebar.mini-navbar .footer {
  margin: 0 0 0 0px;
}

body.mini-navbar #page-wrapper {
  margin: 0 0 0 70px;
}

body.fixed-sidebar .navbar-static-side {
  position: fixed;
  width: 220px;
  z-index: 101;
  height: 100%;
}

body.fixed-sidebar.mini-navbar .navbar-static-side {
  width: 0px;
}

body.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0 0 0 0px;
}

body.body-small.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0 0 0 220px;
}

body.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
  width: 220px;
}

.fixed-sidebar.mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  height: auto;
}

body.fixed-sidebar.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 12px;
  border-radius: 3px;
}

.fixed-sidebar.mini-navbar .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}

.fixed-sidebar.mini-navbar .nav-second-level {
  position: relative;
  padding: 0;
  font-size: 13px;
}

.fixed-sidebar.mini-navbar li.active .nav-second-level {
  left: 0px;
}

body.fixed-sidebar.mini-navbar .navbar-default .nav > li > a {
  font-size: 13px;
}

body.fixed-sidebar.mini-navbar .nav-label,
body.fixed-sidebar.mini-navbar .navbar-default .nav li a span {
  display: inline;
}

.fixed-sidebar.mini-navbar .nav-second-level li a {
  padding: 7px 10px 7px 52px;
}

.fixed-sidebar.mini-navbar .nav-second-level {
  left: 0px;
}

.top-navigation #page-wrapper {
  margin-left: 0;
}

.top-navigation .pace .pace-progress {
  left: 0;
}

.top-navigation .navbar-nav .dropdown-menu > .active > a {
  background: white;
  color: #1ab394;
  font-weight: bold;
}

.white-bg .navbar-fixed-top, .white-bg .navbar-static-top {
  background: #fff;
}

.top-navigation .navbar {
  margin-bottom: 0;
}

.top-navigation .nav > li > a {
  padding: 15px 20px;
  color: #676a6c;
}

.top-navigation .nav > li a:hover, .top-navigation .nav > li a:focus {
  background: #fff;
  color: #1ab394;
}

.top-navigation .nav > li.active {
  background: #fff;
  border: none;
}

.top-navigation .nav > li.active > a {
  color: #1ab394;
}

.top-navigation .navbar-right {
  margin-right: 10px;
}

.top-navigation .navbar-nav .dropdown-menu {
  box-shadow: none;
  border: 1px solid #e7eaec;
}

.top-navigation .dropdown-menu > li > a {
  margin: 0;
  padding: 7px 20px;
}

.navbar .dropdown-menu {
  margin-top: 0px;
}

.top-navigation .navbar-brand {
  background: #1ab394;
  color: #fff;
  padding: 15px 25px;
}

.top-navigation .navbar-top-links li:last-child {
  margin-right: 0;
}

.top-navigation.mini-navbar #page-wrapper, .top-navigation.body-small.fixed-sidebar.mini-navbar #page-wrapper, .mini-navbar .top-navigation #page-wrapper, body-small.fixed-sidebar.mini-navbar .top-navigation #page-wrapper {
  margin: 0;
}

.top-navigation.fixed-nav #wrapper, .fixed-nav #wrapper.top-navigation {
  margin-top: 50px;
}

.top-navigation .footer.fixed {
  margin-left: 0 !important;
}

.top-navigation .wrapper.wrapper-content {
  padding: 40px;
}

.top-navigation.body-small .wrapper.wrapper-content, .body-small .top-navigation .wrapper.wrapper-content {
  padding: 40px 0px 40px 0px;
}

.navbar-toggle {
  background-color: #1ab394;
  color: #fff;
  padding: 6px 12px;
  font-size: 14px;
}

.top-navigation .navbar-nav .open .dropdown-menu > li > a, .top-navigation .navbar-nav .open .dropdown-menu .dropdown-header {
  padding: 10px 15px 10px 20px;
}

@media (max-width: 768px) {
  .top-navigation .navbar-header {
    display: block;
    float: none;
  }
}

.menu-visible-lg, .menu-visible-md {
  display: none !important;
}

@media (min-width: 1200px) {
  .menu-visible-lg {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .menu-visible-md {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .menu-visible-md {
    display: block !important;
  }
  .menu-visible-lg {
    display: block !important;
  }
}

.btn {
  border-radius: 3px;
}

.float-e-margins .btn {
  margin-bottom: 5px;
}

.btn-w-m {
  min-width: 120px;
}

.btn-primary.btn-outline {
  color: #1ab394;
}

.btn-success.btn-outline {
  color: #1c84c6;
}

.btn-info.btn-outline {
  color: #23c6c8;
}

.btn-warning.btn-outline {
  color: #f8ac59;
}

.btn-danger.btn-outline {
  color: #ed5565;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: white;
}

.btn-primary-pr {
  width: 150px;
  height: 40px;
  background-color: #329d9c;
  color: white;
  border-radius: 7px;
  margin: 14px 1em 2px;
}

.btn-danger-pr {
  width: 150px;
  height: 40px;
  background-color: red;
  color: white;
  border-radius: 7px;
  margin: 14px 1em 2px;
}

.btn-print {
  width: 100px;
  height: inherit;
  color: black;
  background-color: whitesmoke;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.btn-more {
  width: 80px;
  height: inherit;
  background-color: whitesmoke;
  color: black;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.btn-hold {
  width: 80px;
  height: inherit;
  background-color: #FFD300;
  color: white;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.btn-primary {
  background-color: #329d9c;
  border-color: #329d9c;
  color: white;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
  background-color: #18a689;
  border-color: #18a689;
  color: white;
}

.btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: #1dc5a3;
  border-color: #1dc5a3;
}

.btn-secondary {
  background-color: white;
  border-color: #329d9c;
  color: #329d9c;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-style: solid !important;
  border-width: 1px;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .open .dropdown-toggle.btn-secondary {
  background-color: #f7f7f7;
  border-color: #1ab394;
  color: #1ab394;
}

.btn-secondary:active, .btn-secondary.active, .open .dropdown-toggle.btn-secondary {
  background-image: none;
}

.btn-secondary.disabled, .btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled:active, .btn-secondary.disabled.active, .btn-secondary[disabled], .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled]:active, .btn-secondary.active[disabled], fieldset[disabled] .btn-secondary, fieldset[disabled] .btn-secondary:hover, fieldset[disabled] .btn-secondary:focus, fieldset[disabled] .btn-secondary:active, fieldset[disabled] .btn-secondary.active {
  background-color: lightgrey;
}

.btn-success {
  background-color: #1c84c6;
  border-color: #1c84c6;
  color: white;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
  background-color: #1a7bb9;
  border-color: #1a7bb9;
  color: white;
}

.btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success.active[disabled], fieldset[disabled] .btn-success, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success.active {
  background-color: #1f90d8;
  border-color: #1f90d8;
}

.btn-info {
  background-color: #23c6c8;
  border-color: #23c6c8;
  color: white;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
  background-color: #21b9bb;
  border-color: #21b9bb;
  color: white;
}

.btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info.active[disabled], fieldset[disabled] .btn-info, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info.active {
  background-color: #26d7d9;
  border-color: #26d7d9;
}

.btn-default {
  background-color: #c2c2c2;
  border-color: #c2c2c2;
  color: white;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
  background-color: #bababa;
  border-color: #bababa;
  color: white;
}

.btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default.active[disabled], fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-warning {
  background-color: #f8ac59;
  border-color: #f8ac59;
  color: white;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
  background-color: #f7a54a;
  border-color: #f7a54a;
  color: white;
}

.btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning.active[disabled], fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning.active {
  background-color: #f9b66d;
  border-color: #f9b66d;
}

.btn-danger {
  background-color: #ed5565;
  border-color: #ed5565;
  color: white;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
  background-color: #ec4758;
  border-color: #ec4758;
  color: white;
}

.btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger.active[disabled], fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
  background-color: #ef6776;
  border-color: #ef6776;
}

.btn-link {
  color: inherit;
}

.btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active, .open .dropdown-toggle.btn-link {
  color: #1c84c6;
}

.btn-link:active, .btn-link.active, .open .dropdown-toggle.btn-link {
  background-image: none;
}

.btn-link.disabled, .btn-link.disabled:hover, .btn-link.disabled:focus, .btn-link.disabled:active, .btn-link.disabled.active, .btn-link[disabled], .btn-link[disabled]:hover, .btn-link[disabled]:focus, .btn-link[disabled]:active, .btn-link.active[disabled], fieldset[disabled] .btn-link, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus, fieldset[disabled] .btn-link:active, fieldset[disabled] .btn-link.active {
  color: #cacaca;
}

.btn-white {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}

.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white {
  color: inherit;
  border: 1px solid #d2d2d2;
}

.btn-white:active, .btn-white.active {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15) inset;
}

.btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white {
  background-image: none;
}

.btn-white.disabled, .btn-white.disabled:hover, .btn-white.disabled:focus, .btn-white.disabled:active, .btn-white.disabled.active, .btn-white[disabled], .btn-white[disabled]:hover, .btn-white[disabled]:focus, .btn-white[disabled]:active, .btn-white.active[disabled], fieldset[disabled] .btn-white, fieldset[disabled] .btn-white:hover, fieldset[disabled] .btn-white:focus, fieldset[disabled] .btn-white:active, fieldset[disabled] .btn-white.active {
  color: #cacaca;
}

.form-control, .form-control:focus, .has-error .form-control:focus, .has-success .form-control:focus, .has-warning .form-control:focus, .navbar-collapse, .navbar-form, .navbar-form-custom .form-control:focus, .navbar-form-custom .form-control:hover, .open .btn.dropdown-toggle, .panel, .popover, .progress, .progress-bar {
  box-shadow: none;
}

.search-dropdown-list-and-button .btn-white, .open .filters-dropdown.btn-white {
  border-radius: 0px;
  border-right: 1px solid #329d9c;
  border-top: 1px solid #329d9c;
  border-bottom: 1px solid #329d9c;
  border-left: none;
  font-size: 14.2px;
  float: left;
  text-transform: uppercase;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}

.btn-rounded {
  border-radius: 50px;
}

.btn-large-dim {
  width: 90px;
  height: 90px;
  font-size: 42px;
}

button.dim {
  display: inline-block;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px !important;
}

button.dim:active {
  top: 3px;
}

button.btn-primary.dim {
  box-shadow: inset 0px 0px 0px #16987e, 0px 5px 0px 0px #16987e, 0px 10px 5px #999999;
}

button.btn-primary.dim:active {
  box-shadow: inset 0px 0px 0px #16987e, 0px 2px 0px 0px #16987e, 0px 5px 3px #999999;
}

button.btn-default.dim {
  box-shadow: inset 0px 0px 0px #b3b3b3, 0px 5px 0px 0px #b3b3b3, 0px 10px 5px #999999;
}

button.btn-default.dim:active {
  box-shadow: inset 0px 0px 0px #b3b3b3, 0px 2px 0px 0px #b3b3b3, 0px 5px 3px #999999;
}

button.btn-warning.dim {
  box-shadow: inset 0px 0px 0px #f79d3c, 0px 5px 0px 0px #f79d3c, 0px 10px 5px #999999;
}

button.btn-warning.dim:active {
  box-shadow: inset 0px 0px 0px #f79d3c, 0px 2px 0px 0px #f79d3c, 0px 5px 3px #999999;
}

button.btn-info.dim {
  box-shadow: inset 0px 0px 0px #1eacae, 0px 5px 0px 0px #1eacae, 0px 10px 5px #999999;
}

button.btn-info.dim:active {
  box-shadow: inset 0px 0px 0px #1eacae, 0px 2px 0px 0px #1eacae, 0px 5px 3px #999999;
}

button.btn-success.dim {
  box-shadow: inset 0px 0px 0px #1872ab, 0px 5px 0px 0px #1872ab, 0px 10px 5px #999999;
}

button.btn-success.dim:active {
  box-shadow: inset 0px 0px 0px #1872ab, 0px 2px 0px 0px #1872ab, 0px 5px 3px #999999;
}

button.btn-danger.dim {
  box-shadow: inset 0px 0px 0px #ea394c, 0px 5px 0px 0px #ea394c, 0px 10px 5px #999999;
}

button.btn-danger.dim:active {
  box-shadow: inset 0px 0px 0px #ea394c, 0px 2px 0px 0px #ea394c, 0px 5px 3px #999999;
}

button.dim:before {
  font-size: 50px;
  line-height: 1em;
  font-weight: normal;
  color: white;
  display: block;
  padding-top: 10px;
}

button.dim:active:before {
  top: 7px;
  font-size: 50px;
}

.label {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
}

.badge {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: "Open Sans";
  font-size: 11px;
  font-weight: 600;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  text-shadow: none;
}

.label-primary, .badge-primary {
  background-color: #329d9c;
  color: white;
}

.label-success, .badge-success {
  background-color: #1c84c6;
  color: white;
}

.label-warning, .badge-warning {
  background-color: #329d9c;
  color: white;
}

.label-warning-light, .badge-warning-light {
  background-color: #329d9c;
  color: white;
}

.label-danger, .badge-danger {
  background-color: #ed5565;
  color: white;
}

.label-info, .badge-info {
  background-color: #23c6c8;
  color: white;
}

.label-inverse, .badge-inverse {
  background-color: #262626;
  color: white;
}

.label-white, .badge-white {
  background-color: white;
  color: #5e5e5e;
}

.label-white, .badge-disable {
  background-color: #2a2e36;
  color: #8b91a0;
}

/* TOOGLE SWICH */
.onoffswitch {
  position: relative;
  width: 64px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #1ab394;
  border-radius: 2px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: #1ab394;
  color: white;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: white;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  width: 20px;
  margin: 0px;
  background: white;
  border: 2px solid #1ab394;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/* CHOSEN PLUGIN */
.chosen-container-single .chosen-single {
  background: white;
  box-shadow: none;
  -moz-box-sizing: border-box;
  background-color: white;
  border: 1px solid #cbd5dd;
  border-radius: 2px;
  cursor: text;
  height: auto !important;
  margin: 0;
  min-height: 30px;
  overflow: hidden;
  padding: 4px 12px;
  position: relative;
  width: 100%;
}

.chosen-container-multi .chosen-choices li.search-choice {
  background: #f1f1f1;
  border: 1px solid #ededed;
  border-radius: 2px;
  box-shadow: none;
  color: #333333;
  cursor: default;
  line-height: 13px;
  margin: 3px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  position: relative;
}

/* PAGINATIN */
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #f4f4f4;
  border-color: #dddddd;
  color: inherit;
  cursor: default;
  z-index: 2;
}

.pagination > li > a, .pagination > li > span {
  background-color: white;
  border: 1px solid #dddddd;
  color: inherit;
  float: left;
  line-height: 1.42857;
  margin-left: -1px;
  padding: 4px 10px;
  position: relative;
  text-decoration: none;
}

/* TOOLTIPS */
.tooltip-inner {
  background-color: #2f4050;
}

.otherSuppliersToolTip .tooltip-inner {
  background-color: #2f4050;
  max-width: max-content;
  margin: auto;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #2f4050;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #2f4050;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #2f4050;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #2f4050;
}

/* EASY PIE CHART */
.easypiechart {
  position: relative;
  text-align: center;
}

.easypiechart .h2 {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
}

.easypiechart canvas {
  top: 0;
  left: 0;
}

.easypiechart .easypie-text {
  line-height: 1;
  position: absolute;
  top: 33px;
  width: 100%;
  z-index: 1;
}

.easypiechart img {
  margin-top: -4px;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* FULLCALENDAR */
.fc-state-default {
  background-color: white;
  background-image: none;
  background-repeat: repeat-x;
  box-shadow: none;
  color: #333333;
  text-shadow: none;
}

.fc-state-default {
  border: 1px solid;
}

.fc-button {
  color: inherit;
  border: 1px solid #e7eaec;
  cursor: pointer;
  display: inline-block;
  height: 1.9em;
  line-height: 1.9em;
  overflow: hidden;
  padding: 0 0.6em;
  position: relative;
  white-space: nowrap;
}

.fc-state-active {
  background-color: #1ab394;
  border-color: #1ab394;
  color: white;
}

.fc-header-title h2 {
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}

.fc-content .fc-widget-header, .fc-content .fc-widget-content {
  border-color: #e7eaec;
  font-weight: normal;
}

.fc-border-separate tbody {
  background-color: #f8f8f8;
}

.fc-state-highlight {
  background: none repeat scroll 0 0 #fcf8e3;
}

.external-event {
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 5px;
}

.fc-ltr .fc-event-hori.fc-event-end, .fc-rtl .fc-event-hori.fc-event-start {
  border-radius: 2px;
}

.fc-event,
.fc-agenda .fc-event-time,
.fc-event a {
  padding: 4px 6px;
  background-color: #1ab394;
  border-color: #1ab394;
}

.fc-event-time, .fc-event-title {
  color: #717171;
  padding: 0 1px;
}

.ui-calendar .fc-event-time, .ui-calendar .fc-event-title {
  color: white;
}

/* Chat */
.chat-activity-list .chat-element {
  border-bottom: 1px solid #e7eaec;
}

.chat-element:first-child {
  margin-top: 0;
}

.chat-element {
  padding-bottom: 15px;
}

.chat-element, .chat-element .media {
  margin-top: 15px;
}

.chat-element, .media-body {
  overflow: hidden;
}

.media-body {
  display: block;
}

.chat-element > .pull-left {
  margin-right: 10px;
}

.chat-element img.img-circle, .dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}

.chat-element .well {
  border: 1px solid #e7eaec;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}

.chat-element .actions {
  margin-top: 10px;
}

.chat-element .photos {
  margin: 10px 0;
}

.right.chat-element > .pull-right {
  margin-left: 10px;
}

.chat-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

.chat {
  margin: 0;
  padding: 0;
  list-style: none;
}

.chat li {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #b3a9a9;
}

.chat li.left .chat-body {
  margin-left: 60px;
}

.chat li.right .chat-body {
  margin-right: 60px;
}

.chat li .chat-body p {
  margin: 0;
  color: #777777;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
  margin-right: 5px;
}

.chat-panel .panel-body {
  height: 350px;
  overflow-y: scroll;
}

/* LIST GROUP */
a.list-group-item.active, a.list-group-item.active:hover, a.list-group-item.active:focus {
  background-color: #1ab394;
  border-color: #1ab394;
  color: white;
  z-index: 2;
}

.list-group-item-heading {
  margin-top: 10px;
}

.list-group-item-text {
  margin: 0 0 10px;
  color: inherit;
  font-size: 12px;
  line-height: inherit;
}

.no-padding .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-padding .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
}

.no-padding .list-group {
  margin-bottom: 0;
}

.list-group-item {
  background-color: inherit;
  border: 1px solid #e7eaec;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}

/* FLOT CHART  */
.flot-chart {
  display: block;
  height: 200px;
}

.widget .flot-chart.dashboard-chart {
  display: block;
  height: 120px;
  margin-top: 40px;
}

.flot-chart.dashboard-chart {
  display: block;
  height: 180px;
  margin-top: 40px;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

.flot-chart-pie-content {
  width: 200px;
  height: 200px;
  margin: auto;
}

.jqstooltip {
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  visibility: hidden;
  background: #2b303a;
  background-color: rgba(43, 48, 58, 0.8);
  color: white;
  text-align: left;
  white-space: nowrap;
  z-index: 10000;
  padding: 5px 5px 5px 5px;
  min-height: 22px;
  border-radius: 3px;
}

.jqsfield {
  color: white;
  text-align: left;
}

.h-200 {
  min-height: 200px;
}

.legendLabel {
  padding-left: 5px;
}

.stat-list li:first-child {
  margin-top: 0;
}

.stat-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stat-percent {
  float: right;
}

.stat-list li {
  margin-top: 15px;
  position: relative;
}

/* DATATABLES */
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}

table.dataTable thead .sorting_asc:after {
  float: right;
  font-family: fontawesome;
}

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  float: right;
  font-family: fontawesome;
}

table.dataTable thead .sorting:after {
  content: "\f0dc";
  float: right;
  font-family: fontawesome;
  color: rgba(50, 50, 50, 0.5);
}

.dataTables_wrapper {
  padding-bottom: 30px;
}

/* CIRCLE */
.img-circle {
  border-radius: 50%;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #dddddd;
  background-color: #eeeeee !important;
}

.show-grid {
  margin: 15px 0;
}

/* ANIMATION */
.css-animation-box h1 {
  font-size: 44px;
}

.animation-efect-links a {
  padding: 4px 6px;
  font-size: 12px;
}

#animation_box {
  background-color: #f9f8f8;
  border-radius: 16px;
  width: 80%;
  margin: 0 auto;
  padding-top: 80px;
}

.animation-text-box {
  position: absolute;
  margin-top: 40px;
  left: 50%;
  margin-left: -100px;
  width: 200px;
}

.animation-text-info {
  position: absolute;
  margin-top: -60px;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  font-size: 10px;
}

.animation-text-box h2 {
  font-size: 54px;
  font-weight: 600;
  margin-bottom: 5px;
}

.animation-text-box p {
  font-size: 12px;
  text-transform: uppercase;
}

/* PEACE */
.pace .pace-progress {
  background: #1ab394;
  position: fixed;
  z-index: 2000;
  top: 0px;
  left: 220px;
  height: 2px;
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
}

.body-small .pace .pace-progress {
  left: 0px;
}

.pace-inactive {
  display: none;
}

/* WIDGETS */
.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.widget.style1 h2 {
  font-size: 30px;
}

.widget h2, .widget h3 {
  margin-top: 5px;
  margin-bottom: 0;
}

.widget-text-box {
  padding: 20px;
  border: 1px solid #e7eaec;
  background: white;
}

.widget-head-color-box {
  border-radius: 5px 5px 0px 0px;
  margin-top: 10px;
}

.widget .flot-chart {
  height: 100px;
}

.vertical-align div {
  display: inline-block;
  vertical-align: middle;
}

.vertical-align h2, .vertical-align h3 {
  margin: 0;
}

.todo-list {
  list-style: none outside none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.todo-list.small-list {
  font-size: 12px;
}

.todo-list.small-list > li {
  background: #f3f3f4;
  border-left: none;
  border-right: none;
  border-radius: 4px;
  color: inherit;
  margin-bottom: 2px;
  padding: 6px 6px 6px 12px;
}

.todo-list.small-list .btn-xs, .todo-list.small-list .btn-group-xs > .btn {
  border-radius: 5px;
  font-size: 10px;
  line-height: 1.5;
  padding: 1px 2px 1px 5px;
}

.todo-list > li {
  background: #f3f3f4;
  border-left: 6px solid #e7eaec;
  border-right: 6px solid #e7eaec;
  border-radius: 4px;
  color: inherit;
  margin-bottom: 2px;
  padding: 10px;
}

.todo-list .handle {
  cursor: move;
  display: inline-block;
  font-size: 16px;
  margin: 0 5px;
}

.todo-list > li .label {
  font-size: 9px;
  margin-left: 10px;
}

.check-link {
  font-size: 16px;
}

.todo-completed {
  text-decoration: line-through;
}

.geo-statistic h1 {
  font-size: 36px;
  margin-bottom: 0;
}

.glyphicon.fa {
  font-family: "FontAwesome";
}

/* INPUTS */
.inline {
  display: inline-block !important;
}

.input-s-sm {
  width: 120px;
}

.input-s {
  width: 200px;
}

.input-s-lg {
  width: 250px;
}

.i-checks {
  padding-left: 0;
}

.form-control, .single-line {
  background-color: white;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

.search-input-bar .form-control, .single-line {
  background-color: white;
  background-image: none;
  border-left: 1px solid #329d9c;
  border-top: 1px solid #329d9c;
  border-bottom: 1px solid #329d9c;
  border-right: none;
  border-radius: 3px 0px 0px 3px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 270px;
}

.search-input-bar .form-control:focus, .single-line:focus {
  background-color: white;
  background-image: none;
  border-left: 1px solid #329d9c;
  border-top: 1px solid #329d9c;
  border-bottom: 1px solid #329d9c;
  border-right: none;
  border-radius: 3px 0px 0px 3px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 270px;
}

.search-input-bar ::placeholder {
  text-transform: uppercase;
  font-style: normal;
}

.form-control:focus, .single-line:focus {
  border-color: #1ab394;
}

.has-success .form-control {
  border-color: #1ab394;
}

.has-warning .form-control {
  border-color: #f8ac59;
}

.has-error .form-control {
  border-color: #ed5565;
}

.has-success .control-label {
  color: #1ab394;
}

.has-warning .control-label {
  color: #f8ac59;
}

.has-error .control-label {
  color: #ed5565;
}

.input-group-addon {
  background-color: white;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 12px;
  text-align: center;
}

.spinner-buttons.input-group-btn .btn-xs {
  line-height: 1.13;
}

.spinner-buttons.input-group-btn {
  width: 20%;
}

.noUi-connect {
  background: none repeat scroll 0 0 #1ab394;
  box-shadow: none;
}

.slider_red .noUi-connect {
  background: none repeat scroll 0 0 #ed5565;
  box-shadow: none;
}

/* UI Sortable */
.ui-sortable .ibox-title {
  cursor: move;
}

.ui-sortable-handle {
  background: #DDDEE0;
}

.ui-sortable-helper {
  background: #c2c2c2 !important;
}

.ui-sortable-placeholder {
  border: 2px dashed #c2c2c2 !important;
  visibility: visible !important;
  background: #e7eaec;
}

.ibox.ui-sortable-placeholder {
  margin: 0px 0px 23px !important;
}

/* SWITCHES */
.onoffswitch {
  position: relative;
  width: 54px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #1ab394;
  border-radius: 3px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 16px;
  padding: 0;
  line-height: 16px;
  font-size: 10px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 7px;
  background-color: #1ab394;
  color: white;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 7px;
  background-color: white;
  color: #919191;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 0px;
  background: white;
  border: 2px solid #1ab394;
  border-radius: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 36px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/* jqGrid */
.ui-jqgrid {
  -moz-box-sizing: content-box;
}

.ui-jqgrid-btable {
  border-collapse: separate;
}

.ui-jqgrid-htable {
  border-collapse: separate;
}

.ui-jqgrid-titlebar {
  height: 40px;
  line-height: 15px;
  color: #676a6c;
  background-color: #f9f9f9;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.ui-jqgrid .ui-jqgrid-title {
  float: left;
  margin: 1.1em 1em 0.2em;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  position: relative;
  border-left: 0px solid;
  border-right: 0px solid;
  border-top: 0px solid;
}

.ui-widget-header {
  background: none;
  background-image: none;
  background-color: #f5f5f6;
  text-transform: uppercase;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ui-jqgrid tr.ui-row-ltr td {
  border-right-color: inherit;
  border-right-style: solid;
  border-right-width: 1px;
  text-align: left;
  border-color: #dddddd;
  background-color: inherit;
}

.ui-search-toolbar input[type="text"] {
  font-size: 12px;
  height: 15px;
  border: 1px solid #cccccc;
  border-radius: 0px;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  background: #f9f9f9;
  border: 1px solid #dddddd;
  line-height: 15px;
  font-weight: bold;
  color: #676a6c;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.ui-widget-content {
  box-sizing: content-box;
}

.ui-icon-triangle-1-n {
  background-position: 1px -16px;
}

.ui-jqgrid tr.ui-search-toolbar th {
  border-top-width: 0px !important;
  border-top-color: inherit !important;
  border-top-style: ridge !important;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  background: whitesmoke;
  border-collapse: separate;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  background: #f2fbff;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  border: 1px solid #dddddd;
  background: white;
  font-weight: normal;
  color: #212121;
}

.ui-jqgrid .ui-pg-input {
  font-size: inherit;
  width: 50px;
  border: 1px solid #cccccc;
  height: 15px;
}

.ui-jqgrid .ui-pg-selbox {
  display: block;
  font-size: 1em;
  height: 25px;
  line-height: 18px;
  margin: 0;
  width: auto;
}

.ui-jqgrid .ui-pager-control {
  position: relative;
}

.ui-jqgrid .ui-jqgrid-pager {
  height: 32px;
  position: relative;
}

.ui-pg-table .navtable .ui-corner-all {
  border-radius: 0px;
}

.ui-jqgrid .ui-pg-button:hover {
  padding: 1px;
  border: 0px;
}

.ui-jqgrid .loading {
  position: absolute;
  top: 45%;
  left: 45%;
  width: auto;
  height: auto;
  z-index: 101;
  padding: 6px;
  margin: 5px;
  text-align: center;
  font-weight: bold;
  display: none;
  border-width: 2px !important;
  font-size: 11px;
}

.ui-jqgrid .form-control {
  height: 10px;
  width: auto;
  display: inline;
  padding: 10px 12px;
}

.ui-jqgrid-pager {
  height: 32px;
}

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 0;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 0;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 0;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 0;
}

.ui-widget-content {
  border: 1px solid #dddddd;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  padding: 0;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  border-bottom: 1px solid #dddddd;
}

.ui-jqgrid tr.jqgrow td {
  padding: 6px;
}

.ui-jqdialog .ui-jqdialog-titlebar {
  padding: 10px 10px;
}

.ui-jqdialog .ui-jqdialog-title {
  float: none !important;
}

.ui-jqdialog > .ui-resizable-se {
  position: absolute;
}

/* Nestable list */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333333;
  text-decoration: none;
  border: 1px solid #e7eaec;
  background: whitesmoke;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle span {
  font-weight: bold;
}

.dd-handle:hover {
  background: #f0f0f0;
  cursor: pointer;
  font-weight: bold;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

.dd-item > button:before {
  content: "+";
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
  content: "-";
}

#nestable2 .dd-item > button {
  font-family: FontAwesome;
  height: 34px;
  width: 33px;
  color: #c1c1c1;
}

#nestable2 .dd-item > button:before {
  content: "\f067";
}

#nestable2 .dd-item > button[data-action="collapse"]:before {
  content: "\f068";
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbbbbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white), -webkit-linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white);
  background-image: -moz-linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white), -moz-linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white);
  background-image: linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white), linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

/* Nestable List */
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd;
}

#nestable-menu {
  padding: 0;
  margin: 10px 0 20px 0;
}

#nestable-output,
#nestable2-output {
  width: 100%;
  font-size: 0.75em;
  line-height: 1.33333em;
  font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#nestable2 .dd-handle {
  color: inherit;
  border: 1px dashed #e7eaec;
  background: #f3f3f4;
  padding: 10px;
}

#nestable2 span.label {
  margin-right: 10px;
}

#nestable-output,
#nestable2-output {
  font-size: 12px;
  padding: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

/* CodeMirror */
.CodeMirror {
  border: 1px solid #eeeeee;
  height: auto;
}

.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}

/* Google Maps */
.google-map {
  height: 300px;
}

/* Validation */
label.error {
  color: #cc5965;
  display: inline-block;
  margin-left: 5px;
}

.form-control.error {
  border: 1px dotted #cc5965;
}

/* ngGrid */
.gridStyle {
  border: 1px solid #d4d4d4;
  width: 100%;
  height: 400px;
}

.gridStyle2 {
  border: 1px solid #d4d4d4;
  width: 500px;
  height: 300px;
}

.ngH eaderCell {
  border-right: none;
  border-bottom: 1px solid #e7eaec;
}

.ngCell {
  border-right: none;
}

.ngTopPanel {
  background: #f5f5f6;
}

.ngRow.even {
  background: #f9f9f9;
}

.ngRow.selected {
  background: #ebf2f1;
}

.ngRow {
  border-bottom: 1px solid #e7eaec;
}

.ngCell {
  background-color: transparent;
}

.ngHeaderCell {
  border-right: none;
}

/* Toastr custom style */
#toast-container > .toast {
  background-image: none !important;
}

#toast-container > .toast:before {
  position: fixed;
  font-family: FontAwesome;
  font-size: 24px;
  line-height: 24px;
  float: left;
  color: #FFF;
  padding-right: 0.5em;
  margin: auto 0.5em auto -1.5em;
}

#toast-container > div {
  -moz-box-shadow: 0 0 3px #999;
  -webkit-box-shadow: 0 0 3px #999;
  box-shadow: 0 0 3px #999;
  opacity: .9;
  -ms-filter: alpha(Opacity=90);
  filter: alpha(opacity=90);
}

#toast-container > :hover {
  -moz-box-shadow: 0 0 4px #999;
  -webkit-box-shadow: 0 0 4px #999;
  box-shadow: 0 0 4px #999;
  opacity: 1;
  -ms-filter: alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

.toast {
  background-color: #1ab394;
}

.toast-success {
  background-color: #1ab394;
}

.toast-error {
  background-color: #ed5565;
}

.toast-info {
  background-color: #23c6c8;
}

.toast-warning {
  background-color: #f8ac59;
}

.toast-top-full-width {
  margin-top: 20px;
}

.toast-bottom-full-width {
  margin-bottom: 20px;
}

/* Notifie */
.cg-notify-message.inspinia-notify {
  background: #fff;
  padding: 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  border: none;
  margin-top: 30px;
  color: inherit;
}

.inspinia-notify.alert-warning {
  border-left: 6px solid #f8ac59;
}

.inspinia-notify.alert-success {
  border-left: 6px solid #1c84c6;
}

.inspinia-notify.alert-danger {
  border-left: 6px solid #ed5565;
}

.inspinia-notify.alert-info {
  border-left: 6px solid #1ab394;
}

/* Image cropper style */
.img-container, .img-preview {
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.img-preview-sm {
  height: 130px;
  width: 200px;
}

/* Forum styles  */
.forum-post-container .media {
  margin: 10px 10px 10px 10px;
  padding: 20px 10px 20px 10px;
  border-bottom: 1px solid #f1f1f1;
}

.forum-avatar {
  float: left;
  margin-right: 20px;
  text-align: center;
  width: 110px;
}

.forum-avatar .img-circle {
  height: 48px;
  width: 48px;
}

.author-info {
  color: #676a6c;
  font-size: 11px;
  margin-top: 5px;
  text-align: center;
}

.forum-post-info {
  padding: 9px 12px 6px 12px;
  background: #f9f9f9;
  border: 1px solid #f1f1f1;
}

.media-body > .media {
  background: #f9f9f9;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
}

.forum-post-container .media-body .photos {
  margin: 10px 0;
}

.forum-photo {
  max-width: 140px;
  border-radius: 3px;
}

.media-body > .media .forum-avatar {
  width: 70px;
  margin-right: 10px;
}

.media-body > .media .forum-avatar .img-circle {
  height: 38px;
  width: 38px;
}

.mid-icon {
  font-size: 66px;
}

.forum-item {
  margin: 10px 0;
  padding: 10px 0 20px;
  border-bottom: 1px solid #f1f1f1;
}

.views-number {
  font-size: 24px;
  line-height: 18px;
  font-weight: 400;
}

.forum-container, .forum-post-container {
  padding: 30px !important;
}

.forum-item small {
  color: #999;
}

.forum-item .forum-sub-title {
  color: #999;
  margin-left: 50px;
}

.forum-title {
  margin: 15px 0 15px 0;
}

.forum-info {
  text-align: center;
}

.forum-desc {
  color: #999;
}

.forum-icon {
  float: left;
  width: 30px;
  margin-right: 20px;
  text-align: center;
}

a.forum-item-title {
  color: inherit;
  display: block;
  font-size: 18px;
  font-weight: 600;
}

a.forum-item-title:hover {
  color: inherit;
}

.forum-icon .fa {
  font-size: 30px;
  margin-top: 8px;
  color: #9b9b9b;
}

.forum-item.active .fa {
  color: #1ab394;
}

.forum-item.active a.forum-item-title {
  color: #1ab394;
}

@media (max-width: 992px) {
  .forum-info {
    margin: 15px 0 10px 0px;
    /* Comment this is you want to show forum info in small devices */
    display: none;
  }
  .forum-desc {
    float: none !important;
  }
}

/* New Timeline style */
.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}

.vertical-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

#vertical-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
}

#vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #f1f1f1;
}

.vertical-timeline-content .btn {
  float: right;
}

#vertical-timeline.light-timeline:before {
  background: #e7eaec;
}

.dark-timeline .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent;
}

.dark-timeline.center-orientation .vertical-timeline-content:before {
  border-color: transparent  transparent transparent #f5f5f5;
}

.dark-timeline .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before,
.dark-timeline.center-orientation .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent;
}

.dark-timeline .vertical-timeline-content,
.dark-timeline.center-orientation .vertical-timeline-content {
  background: #f5f5f5;
}

@media only screen and (min-width: 1170px) {
  #vertical-timeline.center-orientation {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #vertical-timeline.center-orientation:before {
    left: 50%;
    margin-left: -2px;
  }
}

@media only screen and (max-width: 1170px) {
  .center-orientation.dark-timeline .vertical-timeline-content:before {
    border-color: transparent #f5f5f5 transparent transparent;
  }
}

.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
}

.vertical-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.vertical-timeline-block:first-child {
  margin-top: 0;
}

.vertical-timeline-block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-block {
    margin: 4em 0;
  }
  .center-orientation .vertical-timeline-block:first-child {
    margin-top: 0;
  }
  .center-orientation .vertical-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #f1f1f1;
  text-align: center;
}

.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px;
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-icon {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    font-size: 19px;
  }
  .center-orientation .vertical-timeline-icon i {
    margin-left: -12px;
    margin-top: -10px;
  }
  .center-orientation .cssanimations .vertical-timeline-icon.is-hidden {
    visibility: hidden;
  }
}

.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
}

.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px;
}

.vertical-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}

.vertical-timeline-content .vertical-date {
  float: left;
  font-weight: 500;
}

.vertical-date small {
  color: #1ab394;
  font-weight: 400;
}

.vertical-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}

@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 18px;
  }
  .vertical-timeline-content p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .center-orientation .vertical-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .center-orientation .vertical-timeline-content .btn {
    float: left;
  }
  .center-orientation .vertical-timeline-content .vertical-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 2px;
    font-size: 14px;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content {
    float: right;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .btn {
    float: right;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .vertical-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .center-orientation .cssanimations .vertical-timeline-content.is-hidden {
    visibility: hidden;
  }
}

.sidebard-panel {
  width: 220px;
  background: #ebebed;
  padding: 10px 20px;
  position: absolute;
  right: 0;
}

.sidebard-panel .feed-element img.img-circle {
  width: 32px;
  height: 32px;
}

.sidebard-panel .feed-element, .media-body, .sidebard-panel p {
  font-size: 12px;
}

.sidebard-panel .feed-element {
  margin-top: 20px;
  padding-bottom: 0;
}

.sidebard-panel .list-group {
  margin-bottom: 10px;
}

.sidebard-panel .list-group .list-group-item {
  padding: 5px 0;
  font-size: 12px;
  border: 0;
}

.sidebar-content .wrapper {
  padding-right: 230px;
}

.sidebar-content .wrapper, .wrapper.sidebar-content {
  padding-right: 230px !important;
}

.body-small .sidebar-content .wrapper, .body-small .wrapper.sidebar-content {
  padding-right: 20px !important;
}

body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #2f4050;
  font-size: 13px;
  color: #676a6c;
}

html, body {
  max-width: 100%;
  overflow: hidden;
}

html {
  overflow-y: auto !important;
}

body.boxed-layout {
  background: url("patterns/shattered.png");
}

body.boxed-layout #wrapper {
  background-color: #2f4050;
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.top-navigation.boxed-layout #wrapper,
.boxed-layout #wrapper.top-navigation {
  max-width: 1300px !important;
}

.block {
  display: block;
}

.clear {
  display: block;
  overflow: hidden;
}

a {
  cursor: pointer;
}

a:hover, a:focus {
  text-decoration: none;
}

.border-bottom {
  border-bottom: 1px solid #e7eaec !important;
}

.font-bold {
  font-weight: 600;
}

.font-noraml {
  font-weight: 400;
}

.text-uppercase {
  text-transform: uppercase;
}

.b-r {
  border-right: 1px solid #e7eaec;
}

.hr-line-dashed {
  border-top: 1px dashed #e7eaec;
  color: white;
  background-color: white;
  height: 1px;
  margin: 20px 0;
}

.hr-line-solid {
  border-bottom: 1px solid #e7eaec;
  background-color: transparent;
  border-style: solid !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

video {
  width: 100% !important;
  height: auto !important;
}

/* GALLERY */
.gallery > .row > div {
  margin-bottom: 15px;
}

.fancybox img {
  margin-bottom: 5px;
  /* Only for demo */
  width: 24%;
}

/* Summernote text editor  */
.note-editor {
  height: auto;
  min-height: 300px;
}

/* MODAL */
.modal-content {
  background-clip: padding-box;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0 none;
  position: relative;
}

.modal-dialog {
  z-index: 1200;
}

.modal-body {
  padding: 20px 30px 30px 30px;
}

.inmodal .modal-body {
  background: #f8fafb;
}

.inmodal .modal-header {
  padding: 30px 15px;
  text-align: center;
}

.animated.modal.fade .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.inmodal .modal-title {
  font-size: 26px;
}

.inmodal .modal-icon {
  font-size: 84px;
  color: #e2e3e3;
}

.modal-footer {
  margin-top: 0;
}

/* WRAPPERS */
#wrapper {
  width: 100%;
}

.wrapper {
  padding: 0 20px;
}

.wrapper-content {
  padding: 20px 10px 40px;
}

#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  position: relative !important;
}

@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 240px;
    min-height: 1000px;
  }
}

.title-action {
  text-align: right;
  padding-top: 30px;
}

.ibox-content h1, .ibox-content h2, .ibox-content h3, .ibox-content h4, .ibox-content h5,
.ibox-title h1, .ibox-title h2, .ibox-title h3, .ibox-title h4, .ibox-title h5 {
  margin-top: 5px;
}

ul.unstyled, ol.unstyled {
  list-style: none outside none;
  margin-left: 0;
}

.big-icon {
  font-size: 160px;
  color: #e5e6e7;
}

/* FOOTER */
.footer {
  background: none repeat scroll 0 0 white;
  border-top: 1px solid #e7eaec;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  position: absolute;
  right: 0;
}

.footer.fixed_full {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: white;
  border-top: 1px solid #e7eaec;
}

.footer.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: white;
  border-top: 1px solid #e7eaec;
  margin-left: 220px;
}

body.mini-navbar .footer.fixed, body.body-small.mini-navbar .footer.fixed {
  margin: 0 0 0 70px;
}

body.fixed-sidebar.body-small.mini-navbar .footer.fixed {
  margin: 0 0 0 220px;
}

body.body-small .footer.fixed {
  margin-left: 0px;
}

/* PANELS */
.page-heading {
  border-top: 0;
  padding: 0px 10px 20px 10px;
}

.panel-heading h1, .panel-heading h2 {
  margin-bottom: 5px;
}

/* TABLES */
.table-bordered {
  border: 1px solid #ebebeb;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  background-color: #f5f5f6;
  border-bottom-width: 1px;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #e7e7e7;
}

.table > thead > tr > th {
  border-bottom: 1px solid #dddddd;
  vertical-align: bottom;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  border-top: 1px solid #e7eaec;
  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
}

/* PANELS */
.panel.blank-panel {
  background: none;
  margin: 0;
}

.blank-panel .panel-heading {
  padding-bottom: 0;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none;
  border-color: #dddddd #dddddd transparent;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  color: white;
  background-color: #329D9C;
  cursor: default;
}

.nav.nav-tabs li {
  background: none;
  border: none;
}

.nav-tabs > li > a {
  color: #a7b1c2;
  font-weight: 600;
  padding: 10px 20px 10px 25px;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  background-color: #e6e6e6;
  color: #676a6c;
}

.ui-tab .tab-content {
  padding: 20px 0px;
}

/* GLOBAL  */
.no-padding {
  padding: 0 !important;
}

.no-borders {
  border: none !important;
}

.no-margins {
  margin: 0 !important;
}

.no-top-border {
  border-top: 0 !important;
}

.ibox-content.text-box {
  padding-bottom: 0px;
  padding-top: 15px;
}

.border-left-right {
  border-left: 1px solid #e7eaec;
  border-right: 1px solid #e7eaec;
  border-top: none;
  border-bottom: none;
}

.full-width {
  width: 100% !important;
}

.link-block {
  font-size: 12px;
  padding: 10px;
}

.nav.navbar-top-links .link-block a {
  font-size: 12px;
}

.link-block a {
  font-size: 10px;
  color: inherit;
}

body.mini-navbar .branding {
  display: none;
}

img.circle-border {
  border: 6px solid white;
  border-radius: 50%;
}

.branding {
  float: left;
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 17px 20px;
  text-align: center;
  background-color: #1ab394;
}

.login-panel {
  margin-top: 25%;
}

.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.icons-box .infont a i {
  font-size: 25px;
  display: block;
  color: #676a6c;
}

.icons-box .infont a {
  color: #a6a8a9;
}

.icons-box .infont a {
  padding: 10px;
  margin: 1px;
  display: block;
}

.ui-draggable .ibox-title {
  cursor: move;
}

.breadcrumb {
  background-color: white;
  padding: 0;
  margin-bottom: 0;
}

.breadcrumb > li a {
  color: inherit;
}

.breadcrumb > .active {
  color: inherit;
}

code {
  background-color: #f9f2f4;
  border-radius: 4px;
  color: #ca4440;
  font-size: 90%;
  padding: 2px 4px;
  white-space: nowrap;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox:after, .ibox:before {
  display: table;
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: white;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 4px 0px 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  height: 48px;
}

.ibox-content {
  background-color: white;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0px;
}

table.table-mail tr td {
  padding: 12px;
}

.table-mail .check-mail {
  padding-left: 20px;
}

.table-mail .mail-date {
  padding-right: 20px;
}

.star-mail, .check-mail {
  width: 40px;
}

.unread td a, .unread td {
  font-weight: 600;
  color: inherit;
}

.read td a, .read td {
  font-weight: normal;
  color: inherit;
}

.unread td {
  background-color: #f9f8f8;
}

.ibox-content {
  clear: both;
}

.ibox-heading {
  background-color: #f3f6fb;
  border-bottom: none;
}

.ibox-heading h3 {
  font-weight: 200;
  font-size: 24px;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: left;
}

.ibox-title .label {
  float: left;
  margin-left: 4px;
}

.ibox-tools {
  display: inline-block;
  float: right;
  margin-top: 0;
  position: relative;
  padding: 0;
}

.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #c4c4c4;
}

.ibox-tools a.btn-primary {
  color: white;
}

.ibox-tools .dropdown-menu > li > a {
  padding: 4px 10px;
  font-size: 12px;
}

.ibox .open > .dropdown-menu {
  left: auto;
  right: 0;
}

/* BACKGROUNDS */
.gray-bg {
  background-color: #f3f3f4;
}

.white-bg {
  background-color: white;
}

.navy-bg {
  background-color: #1ab394;
  color: white;
}

.blue-bg {
  background-color: #1c84c6;
  color: white;
}

.darkblue-bg {
  background-color: #2b669a;
  color: white;
}

.lazur-bg {
  background-color: #23c6c8;
  color: white;
}

.yellow-bg {
  background-color: #f8ac59;
  color: white;
}

.red-bg {
  background-color: #ed5565;
  color: white;
}

.black-bg {
  background-color: #262626;
}

.panel-primary {
  border-color: #1ab394;
}

.panel-primary > .panel-heading {
  background-color: #1ab394;
  border-color: #1ab394;
}

.panel-success {
  border-color: #1c84c6;
}

.panel-success > .panel-heading {
  background-color: #1c84c6;
  border-color: #1c84c6;
  color: white;
}

.panel-info {
  border-color: #23c6c8;
}

.panel-info > .panel-heading {
  background-color: #23c6c8;
  border-color: #23c6c8;
  color: white;
}

.panel-warning {
  border-color: #f8ac59;
}

.panel-warning > .panel-heading {
  background-color: #f8ac59;
  border-color: #f8ac59;
  color: white;
}

.panel-danger {
  border-color: #ed5565;
}

.panel-danger > .panel-heading {
  background-color: #ed5565;
  border-color: #ed5565;
  color: white;
}

.progress-bar {
  background-color: #1ab394;
}

.progress-small, .progress-small .progress-bar {
  height: 10px;
}

.progress-small, .progress-mini {
  margin-top: 5px;
}

.progress-mini, .progress-mini .progress-bar {
  height: 5px;
  margin-bottom: 0px;
}

.progress-bar-navy-light {
  background-color: #3dc7ab;
}

.progress-bar-success {
  background-color: #1c84c6;
}

.progress-bar-info {
  background-color: #23c6c8;
}

.progress-bar-warning {
  background-color: #f8ac59;
}

.progress-bar-danger {
  background-color: #ed5565;
}

.panel-title {
  font-size: inherit;
}

.jumbotron {
  border-radius: 6px;
  padding: 40px;
}

.jumbotron h1 {
  margin-top: 0;
}

/* COLORS */
.text-navy {
  color: #1ab394;
}

.text-primary {
  color: inherit;
}

.text-success {
  color: #1c84c6;
}

.text-info {
  color: #23c6c8;
}

.text-warning {
  color: #f8ac59;
}

.text-danger {
  color: #ed5565;
}

.text-muted {
  color: #888888;
}

.simple_tag {
  background-color: #f3f3f4;
  border: 1px solid #e7eaec;
  border-radius: 2px;
  color: inherit;
  font-size: 10px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px 12px;
  display: inline-block;
}

.img-shadow {
  -webkit-box-shadow: 0px 0px 3px 0px #919191;
  -moz-box-shadow: 0px 0px 3px 0px #919191;
  box-shadow: 0px 0px 3px 0px #919191;
}

/* For handle diferent bg color in AngularJS version */
.dashboards\.dashboard_2 nav.navbar,
.dashboards\.dashboard_3 nav.navbar,
.mailbox\.inbox nav.navbar,
.mailbox\.email_view nav.navbar,
.mailbox\.email_compose nav.navbar,
.dashboards\.dashboard_4_1 nav.navbar {
  background: #fff;
}

/* For handle diferent bg color in MVC version */
.Dashboard_2 .navbar.navbar-static-top,
.Dashboard_3 .navbar.navbar-static-top,
.Dashboard_4_1 .navbar.navbar-static-top,
.ComposeEmail .navbar.navbar-static-top,
.EmailView .navbar.navbar-static-top,
.Inbox .navbar.navbar-static-top {
  background: #fff;
}

/* MARGINS & PADDINGS */
.p-xxs {
  padding: 5px;
}

.p-xs {
  padding: 10px;
}

.p-sm {
  padding: 15px;
}

.p-m {
  padding: 20px;
}

.p-md {
  padding: 25px;
}

.p-lg {
  padding: 30px;
}

.p-xl {
  padding: 40px;
}

.m-xxs {
  margin: 2px 4px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-xl {
  margin: 50px;
}

.m-n {
  margin: 0 !important;
}

.m-l-none {
  margin-left: 0;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l {
  margin-left: 15px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-l-n-lg {
  margin-left: -30px;
}

.m-l-n-xl {
  margin-left: -40px;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n {
  margin-top: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-t-n-lg {
  margin-top: -30px;
}

.m-t-n-xl {
  margin-top: -40px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xxs {
  margin-right: 1px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r {
  margin-right: 15px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-r-xl {
  margin-right: 40px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-r-n-lg {
  margin-right: -30px;
}

.m-r-n-xl {
  margin-right: -40px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xxs {
  margin-bottom: 1px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-b-n-lg {
  margin-bottom: -30px;
}

.m-b-n-xl {
  margin-bottom: -40px;
}

.space-15 {
  margin: 15px 0;
}

.space-20 {
  margin: 20px 0;
}

.space-25 {
  margin: 25px 0;
}

.space-30 {
  margin: 30px 0;
}

body.modal-open {
  padding-right: inherit !important;
}

/* SEARCH PAGE */
.search-form {
  margin-top: 10px;
}

.search-result h3 {
  margin-bottom: 0;
  color: #1e0fbe;
}

.search-result .search-link {
  color: #006621;
}

.search-result p {
  font-size: 12px;
  margin-top: 5px;
}

/* CONTACTS */
.contact-box {
  background-color: white;
  border: 1px solid #e7eaec;
  padding: 20px;
  margin-bottom: 20px;
}

.contact-box a {
  color: inherit;
}

/* INVOICE */
.invoice-table tbody > tr > td:last-child, .invoice-table tbody > tr > td:nth-child(4), .invoice-table tbody > tr > td:nth-child(3), .invoice-table tbody > tr > td:nth-child(2) {
  text-align: right;
}

.invoice-table thead > tr > th:last-child, .invoice-table thead > tr > th:nth-child(4), .invoice-table thead > tr > th:nth-child(3), .invoice-table thead > tr > th:nth-child(2) {
  text-align: right;
}

.invoice-total > tbody > tr > td:first-child {
  text-align: right;
}

.invoice-total > tbody > tr > td {
  border: 0 none;
}

.invoice-total > tbody > tr > td:last-child {
  border-bottom: 1px solid #dddddd;
  text-align: right;
  width: 15%;
}

/* ERROR & LOGIN & LOCKSCREEN */
.middle-box {
  height: 400px;
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -200px;
  z-index: 100;
}

.lockscreen.middle-box {
  width: 200px;
  margin-left: -100px;
  margin-top: -190px;
}

.loginscreen.middle-box {
  width: 300px;
  margin-left: -150px;
  margin-top: -330px;
}

.loginColumns {
  width: 760px;
  margin: 8% auto 0;
}

.logo-name {
  color: #939393;
  font-size: 180px;
  font-weight: 800;
  letter-spacing: -10px;
  margin-bottom: 0px;
}

.middle-box h1 {
  font-size: 170px;
}

.wrapper .middle-box {
  margin-top: 140px;
}

.lock-word {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -180px;
  margin-left: -470px;
}

.lock-word span {
  font-size: 100px;
  font-weight: 600;
  color: #e9e9e9;
  display: inline-block;
}

.lock-word .first-word {
  margin-right: 160px;
}

/* DASBOARD */
.dashboard-header {
  border-top: 0;
  padding: 20px 20px 20px 20px;
}

.dashboard-header h2 {
  margin-top: 10px;
  font-size: 26px;
}

.fist-item {
  border-top: none !important;
}

.statistic-box {
  margin-top: 40px;
}

.dashboard-header .list-group-item span.label {
  margin-right: 10px;
}

.list-group.clear-list .list-group-item {
  border-top: 1px solid #e7eaec;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  padding: 10px 0;
}

ul.clear-list:first-child {
  border-top: none !important;
}

/* Intimeline */
.timeline-item .date i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  width: 30px;
  text-align: center;
  border-top: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
  border-left: 1px solid #e7eaec;
  background: #f8f8f8;
}

.timeline-item .date {
  text-align: right;
  width: inherit;
  position: relative;
  padding-top: 30px;
}

.timeline-item .content {
  border-left: 1px solid #e7eaec;
  border-top: 1px solid #e7eaec;
  padding-top: 10px;
  min-height: 100px;
}

.timeline-item .content:hover {
  background: #f6f6f6;
}

/* PIN BOARD */
ul.notes li, ul.tag-list li {
  list-style: none;
}

ul.notes li h4 {
  margin-top: 20px;
  font-size: 16px;
}

ul.notes li div {
  text-decoration: none;
  color: black;
  background: #ffffcc;
  display: block;
  height: 140px;
  width: 140px;
  padding: 1em;
  position: relative;
}

ul.notes li div small {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 10px;
}

ul.notes li div a {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: inherit;
}

ul.notes li {
  margin: 10px 40px 50px 0px;
  float: left;
}

ul.notes li div p {
  font-size: 12px;
}

ul.notes li div {
  text-decoration: none;
  color: black;
  background: #ffffcc;
  display: block;
  height: 140px;
  width: 140px;
  padding: 1em;
  /* Firefox */
  -moz-box-shadow: 5px 5px 2px #212121;
  /* Safari+Chrome */
  -webkit-box-shadow: 5px 5px 2px rgba(33, 33, 33, 0.7);
  /* Opera */
  box-shadow: 5px 5px 2px rgba(33, 33, 33, 0.7);
}

ul.notes li div {
  -webkit-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
}

ul.notes li:nth-child(even) div {
  -o-transform: rotate(4deg);
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  position: relative;
  top: 5px;
}

ul.notes li:nth-child(3n) div {
  -o-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  position: relative;
  top: -5px;
}

ul.notes li:nth-child(5n) div {
  -o-transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  position: relative;
  top: -10px;
}

ul.notes li div:hover, ul.notes li div:focus {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  position: relative;
  z-index: 5;
}

ul.notes li div {
  text-decoration: none;
  color: black;
  background: #ffffcc;
  display: block;
  height: 210px;
  width: 210px;
  padding: 1em;
  -moz-box-shadow: 5px 5px 7px #212121;
  -webkit-box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  -moz-transition: -moz-transform 0.15s linear;
  -o-transition: -o-transform 0.15s linear;
  -webkit-transition: -webkit-transform 0.15s linear;
}

/* FILE MANAGER */
.file-box {
  float: left;
  width: 220px;
}

.file-manager h5 {
  text-transform: uppercase;
}

.file-manager {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

.folder-list li a {
  color: #666666;
  display: block;
  padding: 5px 0;
}

.folder-list li {
  border-bottom: 1px solid #e7eaec;
  display: block;
}

.folder-list li i {
  margin-right: 8px;
  color: #3d4d5d;
}

.category-list li a {
  color: #666666;
  display: block;
  padding: 5px 0;
}

.category-list li {
  display: block;
}

.category-list li i {
  margin-right: 8px;
  color: #3d4d5d;
}

.category-list li a .text-navy {
  color: #1ab394;
}

.category-list li a .text-primary {
  color: #1c84c6;
}

.category-list li a .text-info {
  color: #23c6c8;
}

.category-list li a .text-danger {
  color: #ef5352;
}

.category-list li a .text-warning {
  color: #f8ac59;
}

.file-manager h5.tag-title {
  margin-top: 20px;
}

.tag-list li {
  float: left;
}

.tag-list li a {
  font-size: 10px;
  background-color: #f3f3f4;
  padding: 5px 12px;
  color: inherit;
  border-radius: 2px;
  border: 1px solid #e7eaec;
  margin-right: 5px;
  margin-top: 5px;
  display: block;
}

.file {
  border: 1px solid #e7eaec;
  padding: 0;
  background-color: white;
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
}

.file-manager .hr-line-dashed {
  margin: 15px 0;
}

.file .icon, .file .image {
  height: 100px;
  overflow: hidden;
}

.file .icon {
  padding: 15px 10px;
  text-align: center;
}

.file-control {
  color: inherit;
  font-size: 11px;
  margin-right: 10px;
}

.file-control.active {
  text-decoration: underline;
}

.file .icon i {
  font-size: 70px;
  color: #dadada;
}

.file .file-name {
  padding: 10px;
  background-color: #f8f8f8;
  border-top: 1px solid #e7eaec;
}

.file .file-title {
  padding: 5px;
  height: 35px;
  background-color: #f8f8f8;
  border-top: 1px solid #e7eaec;
}

.file-name small {
  color: #676a6c;
}

.corner {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  line-height: 0;
  border: 0.6em solid transparent;
  border-right: 0.6em solid #f1f1f1;
  border-bottom: 0.6em solid #f1f1f1;
  right: 0em;
  bottom: 0em;
}

a.compose-mail {
  padding: 8px 10px;
}

.mail-search {
  max-width: 300px;
}

/* PROFILE */
.profile-content {
  border-top: none !important;
}

.feed-activity-list .feed-element {
  border-bottom: 1px solid #e7eaec;
}

.feed-element:first-child {
  margin-top: 0;
}

.feed-element {
  padding-bottom: 15px;
}

.feed-element, .feed-element .media {
  margin-top: 15px;
}

.feed-element, .media-body {
  overflow: hidden;
}

.feed-element > .pull-left {
  margin-right: 10px;
}

.feed-element img.img-circle, .dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}

.feed-element .well {
  border: 1px solid #e7eaec;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}

.feed-element .actions {
  margin-top: 10px;
}

.feed-element .photos {
  margin: 10px 0;
}

.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* MAILBOX */
.mail-box {
  background-color: white;
  border: 1px solid #e7eaec;
  border-top: 0;
  padding: 0px;
  margin-bottom: 20px;
}

.mail-box-header {
  background-color: white;
  border: 1px solid #e7eaec;
  border-bottom: 0;
  padding: 30px 20px 20px 20px;
}

.mail-box-header h2 {
  margin-top: 0px;
}

.mailbox-content .tag-list li a {
  background: white;
}

.mail-body {
  border-top: 1px solid #e7eaec;
  padding: 20px;
}

.mail-text {
  border-top: 1px solid #e7eaec;
}

.mail-text .note-toolbar {
  padding: 10px 15px;
}

.mail-body .form-group {
  margin-bottom: 5px;
}

.mail-text .note-editor .note-toolbar {
  background-color: #f9f8f8;
}

.mail-attachment {
  border-top: 1px solid #e7eaec;
  padding: 20px;
  font-size: 12px;
}

.mailbox-content {
  background: none;
  border: none;
  padding: 10px;
}

.mail-ontact {
  width: 23%;
}

/* PROJECTS */
.project-people, .project-actions {
  text-align: right;
  vertical-align: middle;
}

dd.project-people {
  text-align: left;
  margin-top: 5px;
}

.project-people img {
  width: 32px;
  height: 32px;
}

.project-title a {
  font-size: 14px;
  color: #676a6c;
  font-weight: 600;
}

.project-list table tr td {
  border-top: none;
  border-bottom: 1px solid #e7eaec;
  padding: 15px 10px;
  vertical-align: middle;
}

.project-manager .tag-list li a {
  font-size: 10px;
  background-color: white;
  padding: 5px 12px;
  color: inherit;
  border-radius: 2px;
  border: 1px solid #e7eaec;
  margin-right: 5px;
  margin-top: 5px;
  display: block;
}

.project-files li a {
  font-size: 11px;
  color: #676a6c;
  margin-left: 10px;
  line-height: 22px;
}

/* FAQ */
.faq-item {
  padding: 20px;
  margin-bottom: 2px;
  background: white;
}

.faq-question {
  font-size: 18px;
  font-weight: 600;
  color: #1ab394;
  display: block;
}

.faq-question:hover {
  color: #179d82;
}

.faq-answer {
  margin-top: 10px;
  background: #f3f3f4;
  border: 1px solid #e7eaec;
  border-radius: 3px;
  padding: 15px;
}

.faq-item .tag-item {
  background: #f3f3f4;
  padding: 2px 6px;
  font-size: 10px;
  text-transform: uppercase;
}

/* Chat view */
.message-input {
  height: 90px !important;
}

.chat-avatar {
  white: 36px;
  height: 36px;
  float: left;
  margin-right: 10px;
}

.chat-user-name {
  padding: 10px;
}

.chat-user {
  padding: 8px 10px;
  border-bottom: 1px solid #e7eaec;
}

.chat-user a {
  color: inherit;
}

.chat-view {
  z-index: 20012;
}

.chat-users, .chat-statistic {
  margin-left: -30px;
}

@media (max-width: 992px) {
  .chat-users, .chat-statistic {
    margin-left: 0px;
  }
}

.chat-view .ibox-content {
  padding: 0;
}

.chat-message {
  padding: 10px 20px;
}

.message-avatar {
  height: 48px;
  width: 48px;
  border: 1px solid #e7eaec;
  border-radius: 4px;
  margin-top: 1px;
}

.chat-discussion .chat-message:nth-child(2n+1) .message-avatar {
  float: left;
  margin-right: 10px;
}

.chat-discussion .chat-message:nth-child(2n) .message-avatar {
  float: right;
  margin-left: 10px;
}

.message {
  background-color: #fff;
  border: 1px solid #e7eaec;
  text-align: left;
  display: block;
  padding: 10px 20px;
  position: relative;
  border-radius: 4px;
}

.chat-discussion .chat-message:nth-child(2n+1) .message-date {
  float: right;
}

.chat-discussion .chat-message:nth-child(2n) .message-date {
  float: left;
}

.chat-discussion .chat-message:nth-child(2n+1) .message {
  text-align: left;
  margin-left: 55px;
}

.chat-discussion .chat-message:nth-child(2n) .message {
  text-align: right;
  margin-right: 55px;
}

.message-date {
  font-size: 10px;
  color: #888888;
}

.message-content {
  display: block;
}

.chat-discussion {
  background: #eee;
  padding: 15px;
  height: 400px;
  overflow-y: auto;
}

.chat-users {
  overflow-y: auto;
  height: 400px;
}

.chat-message-form .form-group {
  margin-bottom: 0;
}

/* jsTree */
.jstree-open > .jstree-anchor > .fa-folder:before {
  content: "\f07c";
}

.jstree-default .jstree-icon.none {
  width: 0;
}

/*
    Common
*/
.wizard,
.tabcontrol {
  display: block;
  /*width: 100%;*/
  overflow: hidden;
}

.catalog-import {
  background: #eee;
  display: block;
  margin: 5px 5px 10px 5px;
  min-height: 120px;
  overflow-y: scroll;
  position: relative;
  width: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.wizard a,
.tabcontrol a {
  outline: 0;
}

.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

.wizard ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0;
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em;
}

.wizard > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}

/*
    Wizard
*/
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%;
}

.wizard > .steps > ul > li {
  width: 25%;
}

.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left;
}

.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 8px;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: #eee;
  color: #aaa;
  cursor: default;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: #1AB394;
  color: #fff;
  cursor: default;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
  background: #6fd1bd;
  color: #fff;
}

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active {
  background: #ED5565;
  color: #fff;
}

.wizard > .content {
  background: #eee;
  display: block;
  margin: 5px 5px 10px 5px;
  min-height: 120px;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.wizard-big.wizard > .content {
  min-height: 320px;
}

.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 65%;
}

.wizard > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.wizard > .content > .body ul {
  list-style: disc !important;
}

.wizard > .content > .body ul > li {
  display: list-item;
}

.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}

.wizard > .content > .body input {
  display: block;
  border: 1px solid #ccc;
}

.wizard > .content > .body input[type="checkbox"] {
  display: inline-block;
}

.wizard > .content > .body input.error {
  background: #fbe3e4;
  border: 1px solid #fbc2c4;
  color: #8a1f11;
}

.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: 0.5em;
}

.wizard > .content > .body label.error {
  color: #8a1f11;
  display: inline-block;
  margin-left: 1.5em;
}

.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}

.wizard > .actions > ul {
  display: inline-block;
  text-align: right;
}

.wizard > .actions > ul > li {
  margin: 0 0.5em;
}

.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  background: #1AB394;
  color: #fff;
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  background: #eee;
  color: #aaa;
}

/*
    Tabcontrol
*/
.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}

.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}

.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}

.tabcontrol > .steps > ul > li > a {
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none;
}

.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px;
}

.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px;
}

.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.tabcontrol > .content > .body ul {
  list-style: disc !important;
}

.tabcontrol > .content > .body ul > li {
  display: list-item;
}

body.rtls {
  /* Theme config */
}

body.rtls #page-wrapper {
  margin: 0 220px 0 0;
}

body.rtls .nav-second-level li a {
  padding: 7px 35px 7px 10px;
}

body.rtls .ibox-title h5 {
  float: right;
}

body.rtls .pull-right {
  float: left !important;
}

body.rtls .pull-left {
  float: right !important;
}

body.rtls .ibox-tools {
  float: left;
}

body.rtls .stat-percent {
  float: left;
}

body.rtls .navbar-right {
  float: left !important;
}

body.rtls .navbar-top-links li:last-child {
  margin-left: 40px;
  margin-right: 0;
}

body.rtls .minimalize-styl-2 {
  float: right;
  margin: 14px 20px 5px 5px;
}

body.rtls .feed-element > .pull-left {
  margin-left: 10px;
  margin-right: 0;
}

body.rtls .timeline-item .date {
  text-align: left;
}

body.rtls .timeline-item .date i {
  left: 0;
  right: auto;
}

body.rtls .timeline-item .content {
  border-right: 1px solid #e7eaec;
  border-left: none;
}

body.rtls .theme-config {
  left: 0;
  right: auto;
}

body.rtls .spin-icon {
  border-radius: 0 20px 20px 0;
}

body.rtls .toast-close-button {
  float: left;
}

body.rtls #toast-container > .toast:before {
  margin: auto -1.5em auto 0.5em;
}

body.rtls #toast-container > div {
  padding: 15px 50px 15px 15px;
}

body.rtls .center-orientation .vertical-timeline-icon i {
  margin-left: 0;
  margin-right: -12px;
}

body.rtls .vertical-timeline-icon i {
  right: 50%;
  left: auto;
  margin-left: auto;
  margin-right: -12px;
}

body.rtls .file-box {
  float: right;
}

body.rtls ul.notes li {
  float: right;
}

body.rtls .chat-users, body.rtls .chat-statistic {
  margin-right: -30px;
  margin-left: auto;
}

body.rtls .dropdown-menu > li > a {
  text-align: right;
}

body.rtls .b-r {
  border-left: 1px solid #e7eaec;
  border-right: none;
}

body.rtls .dd-list .dd-list {
  padding-right: 30px;
  padding-left: 0;
}

body.rtls .dd-item > button {
  float: right;
}

body.rtls .theme-config-box {
  margin-left: -220px;
  margin-right: 0;
}

body.rtls .theme-config-box.show {
  margin-left: 0;
  margin-right: 0;
}

body.rtls .spin-icon {
  right: 0;
  left: auto;
}

body.rtls .skin-setttings {
  margin-right: 40px;
  margin-left: 0;
}

body.rtls .skin-setttings {
  direction: ltr;
}

body.rtls .footer.fixed {
  margin-right: 220px;
  margin-left: 0;
}

@media (max-width: 992px) {
  body.rtls .chat-users, body.rtls .chat-statistic {
    margin-right: 0px;
  }
}

body.rtls.mini-navbar .footer.fixed, body.body-small.mini-navbar .footer.fixed {
  margin: 0 70px 0 0;
}

body.rtls.mini-navbar.fixed-sidebar .footer.fixed, body.body-small.mini-navbar .footer.fixed {
  margin: 0 0 0 0;
}

body.rtls.top-navigation .navbar-toggle {
  float: right;
  margin-left: 15px;
  margin-right: 15px;
}

.body-small.rtls.top-navigation .navbar-header {
  float: none;
}

body.rtls.top-navigation #page-wrapper {
  margin: 0;
}

body.rtls.mini-navbar #page-wrapper {
  margin: 0 70px 0 0;
}

body.rtls.mini-navbar.fixed-sidebar #page-wrapper {
  margin: 0 0 0 0;
}

body.rtls.body-small.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0 220px 0 0;
}

body.rtls.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
  width: 220px;
}

.body-small.rtls .navbar-fixed-top {
  margin-right: 0px;
}

.body-small.rtls .navbar-header {
  float: right;
}

body.rtls .navbar-top-links li:last-child {
  margin-left: 20px;
}

body.rtls .top-navigation #page-wrapper, body.rtls.mini-navbar .top-navigation #page-wrapper, body.rtls.mini-navbar.top-navigation #page-wrapper {
  margin: 0;
}

body.rtls .top-navigation .footer.fixed, body.rtls.top-navigation .footer.fixed {
  margin: 0;
}

@media (max-width: 768px) {
  body.rtls .navbar-top-links li:last-child {
    margin-left: 20px;
  }
  .body-small.rtls #page-wrapper {
    position: inherit;
    margin: 0 0 0 0px;
    min-height: 1000px;
  }
  .body-small.rtls .navbar-static-side {
    display: none;
    z-index: 101;
    position: absolute;
    width: 70px;
  }
  .body-small.rtls.mini-navbar .navbar-static-side {
    display: block;
  }
  .rtls.fixed-sidebar.body-small .navbar-static-side {
    display: none;
    z-index: 101;
    position: fixed;
    width: 220px;
  }
  .rtls.fixed-sidebar.body-small.mini-navbar .navbar-static-side {
    display: block;
  }
}

.rtls .ltr-support {
  direction: ltr;
}

/*
 *   SKIN 1 - INSPINIA - Responsive Admin Theme
 *   NAME - Blue light
 */
.skin-1 .minimalize-styl-2 {
  margin: 14px 5px 5px 30px;
}

.skin-1 .navbar-top-links li:last-child {
  margin-right: 30px;
}

.skin-1.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}

.skin-1 .spin-icon {
  background: #0e9aef !important;
}

.skin-1 .nav-header {
  background: #0e9aef;
  background: url("patterns/header-profile-skin-1.png");
}

.skin-1.mini-navbar .nav-second-level {
  background: #3e495f;
}

.skin-1 .breadcrumb {
  background: transparent;
}

.skin-1 .page-heading {
  border: none;
}

.skin-1 .nav > li.active {
  background: #3a4459;
}

.skin-1 .nav > li > a {
  color: #9ea6b9;
}

.skin-1 .nav > li.active > a {
  color: white;
}

.skin-1 .navbar-minimalize {
  background: #0e9aef;
  border-color: #0e9aef;
}

body.skin-1 {
  background: #3e495f;
}

.skin-1 .navbar-static-top {
  background: white;
}

.skin-1 .dashboard-header {
  background: transparent;
  border-bottom: none !important;
  border-top: none;
  padding: 20px 30px 10px 30px;
}

.fixed-nav.skin-1 .navbar-fixed-top {
  background: white;
}

.skin-1 .wrapper-content {
  padding: 30px 15px;
}

.skin-1 #page-wrapper {
  background: #f4f6fa;
}

.skin-1 .ibox-title, .skin-1 .ibox-content {
  border-width: 1px;
}

.skin-1 .ibox-content:last-child {
  border-style: solid solid solid solid;
}

.skin-1 .nav > li.active {
  border: none;
}

.skin-1 .nav-header {
  padding: 35px 25px 25px 25px;
}

.skin-1 .nav-header a.dropdown-toggle {
  color: white;
  margin-top: 10px;
}

.skin-1 .nav-header a.dropdown-toggle .text-muted {
  color: white;
  opacity: 0.8;
}

.skin-1 .profile-element {
  text-align: center;
}

.skin-1 .img-circle {
  border-radius: 5px;
}

.skin-1 .navbar-default .nav > li > a:hover, .skin-1 .navbar-default .nav > li > a:focus {
  background: #3a4459;
  color: white;
}

.skin-1 .nav.nav-tabs > li.active > a {
  color: #555;
}

.skin-1 .nav.nav-tabs > li.active {
  background: transparent;
}

/*
 *   SKIN 2 - INSPINIA - Responsive Admin Theme
 *   NAME - Inspinia Ultra
 */
body.skin-2 {
  color: #565758 !important;
}

.skin-2 .minimalize-styl-2 {
  margin: 14px 5px 5px 25px;
}

.skin-2 .navbar-top-links li:last-child {
  margin-right: 25px;
}

.skin-2 .spin-icon {
  background: #23c6c8 !important;
}

.skin-2 .nav-header {
  background: #23c6c8;
  background: url("patterns/header-profile-skin-2.png");
}

.skin-2.mini-navbar .nav-second-level {
  background: #ededed;
}

.skin-2 .breadcrumb {
  background: transparent;
}

.skin-2.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}

.skin-2 .page-heading {
  border: none;
  background: rgba(255, 255, 255, 0.7);
}

.skin-2 .nav > li.active {
  background: #e0e0e0;
}

.skin-2 .logo-element {
  padding: 17px 0;
}

.skin-2 .nav > li > a, .skin-2 .welcome-message {
  color: #edf6ff;
}

.skin-2 #top-search::-moz-placeholder {
  color: #edf6ff;
  opacity: 0.5;
}

.skin-2 #side-menu > li > a, .skin-2 .nav.nav-second-level > li > a {
  color: #586b7d;
}

.skin-2 .nav > li.active > a {
  color: #213a53;
}

.skin-2.mini-navbar .nav-header {
  background: #213a53;
}

.skin-2 .navbar-minimalize {
  background: #23c6c8;
  border-color: #23c6c8;
}

.skin-2 .border-bottom {
  border-bottom: none !important;
}

.skin-2 #top-search {
  color: white;
}

body.skin-2 #wrapper {
  background-color: #ededed;
}

.skin-2 .navbar-static-top {
  background: #213a53;
}

.fixed-nav.skin-2 .navbar-fixed-top {
  background: #213a53;
  border-bottom: none !important;
}

.skin-2 .nav-header {
  padding: 30px 25px 30px 25px;
}

.skin-2 .dashboard-header {
  background: rgba(255, 255, 255, 0.4);
  border-bottom: none !important;
  border-top: none;
  padding: 20px 30px 20px 30px;
}

.skin-2 .wrapper-content {
  padding: 30px 15px;
}

.skin-2 .dashoard-1 .wrapper-content {
  padding: 0px 30px 25px 30px;
}

.skin-2 .ibox-title {
  background: rgba(255, 255, 255, 0.7);
  border: none;
  margin-bottom: 1px;
}

.skin-2 .ibox-content {
  background: rgba(255, 255, 255, 0.4);
  border: none !important;
}

.skin-2 #page-wrapper {
  background: #f6f6f6;
  background: -webkit-radial-gradient(center, ellipse cover, #f6f6f6 20%, #d5d5d5 100%);
  background: -o-radial-gradient(center, ellipse cover, #f6f6f6 20%, #d5d5d5 100%);
  background: -ms-radial-gradient(center, ellipse cover, #f6f6f6 20%, #d5d5d5 100%);
  background: radial-gradient(ellipse at center, #f6f6f6 20%, #d5d5d5 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#f6f6f6, endColorstr=#d5d5d5)";
}

.skin-2 .ibox-title, .skin-2 .ibox-content {
  border-width: 1px;
}

.skin-2 .ibox-content:last-child {
  border-style: solid solid solid solid;
}

.skin-2 .nav > li.active {
  border: none;
}

.skin-2 .nav-header a.dropdown-toggle {
  color: #edf6ff;
  margin-top: 10px;
}

.skin-2 .nav-header a.dropdown-toggle .text-muted {
  color: #edf6ff;
  opacity: 0.8;
}

.skin-2 .img-circle {
  border-radius: 10px;
}

.skin-2 .nav.navbar-top-links > li > a:hover, .skin-2 .nav.navbar-top-links > li > a:focus {
  background: #1a2d41;
}

.skin-2 .navbar-default .nav > li > a:hover, .skin-2 .navbar-default .nav > li > a:focus {
  background: #e0e0e0;
  color: #213a53;
}

.skin-2 .nav.nav-tabs > li.active > a {
  color: #555;
}

.skin-2 .nav.nav-tabs > li.active {
  background: transparent;
}

/*
 *   SKIN 3 - INSPINIA - Responsive Admin Theme
 *   NAME - Yellow/purple
 */
.skin-3 .minimalize-styl-2 {
  margin: 14px 5px 5px 30px;
}

.skin-3 .navbar-top-links li:last-child {
  margin-right: 30px;
}

.skin-3.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}

.skin-3 .spin-icon {
  background: #ecba52 !important;
}

body.boxed-layout.skin-3 #wrapper {
  background: #3e2c42;
}

.skin-3 .nav-header {
  background: #ecba52;
  background: url("patterns/header-profile-skin-3.png");
}

.skin-3.mini-navbar .nav-second-level {
  background: #3e2c42;
}

.skin-3 .breadcrumb {
  background: transparent;
}

.skin-3 .page-heading {
  border: none;
}

.skin-3 .nav > li.active {
  background: #38283c;
}

.fixed-nav.skin-3 .navbar-fixed-top {
  background: white;
}

.skin-3 .nav > li > a {
  color: #948b96;
}

.skin-3 .nav > li.active > a {
  color: white;
}

.skin-3 .navbar-minimalize {
  background: #ecba52;
  border-color: #ecba52;
}

body.skin-3 {
  background: #3e2c42;
}

.skin-3 .navbar-static-top {
  background: white;
}

.skin-3 .dashboard-header {
  background: transparent;
  border-bottom: none !important;
  border-top: none;
  padding: 20px 30px 10px 30px;
}

.skin-3 .wrapper-content {
  padding: 30px 15px;
}

.skin-3 #page-wrapper {
  background: #f4f6fa;
}

.skin-3 .ibox-title, .skin-3 .ibox-content {
  border-width: 1px;
}

.skin-3 .ibox-content:last-child {
  border-style: solid solid solid solid;
}

.skin-3 .nav > li.active {
  border: none;
}

.skin-3 .nav-header {
  padding: 35px 25px 25px 25px;
}

.skin-3 .nav-header a.dropdown-toggle {
  color: white;
  margin-top: 10px;
}

.skin-3 .nav-header a.dropdown-toggle .text-muted {
  color: white;
  opacity: 0.8;
}

.skin-3 .profile-element {
  text-align: center;
}

.skin-3 .img-circle {
  border-radius: 5px;
}

.skin-3 .navbar-default .nav > li > a:hover, .skin-3 .navbar-default .nav > li > a:focus {
  background: #38283c;
  color: white;
}

.skin-3 .nav.nav-tabs > li.active > a {
  color: #555;
}

.skin-3 .nav.nav-tabs > li.active {
  background: transparent;
}

@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 220px;
    min-height: 1200px;
  }
  .navbar-static-side {
    z-index: 101;
    position: absolute;
    width: 220px;
  }
  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 0px;
    min-height: 1000px;
  }
  .body-small .navbar-static-side {
    display: none;
    z-index: 101;
    position: absolute;
    width: 70px;
  }
  .body-small.mini-navbar .navbar-static-side {
    display: block;
  }
  .lock-word {
    display: none;
  }
  .navbar-form-custom {
    display: none;
  }
  .navbar-header {
    display: inline;
    float: left;
  }
  .sidebard-panel {
    z-index: 2;
    position: relative;
    width: auto;
    min-height: 100% !important;
  }
  .sidebar-content .wrapper {
    padding-right: 0px;
    z-index: 1;
  }
  .fixed-sidebar.body-small .navbar-static-side {
    display: none;
    z-index: 101;
    position: fixed;
    width: 220px;
  }
  .fixed-sidebar.body-small.mini-navbar .navbar-static-side {
    display: block;
  }
}

@media (max-width: 350px) {
  .timeline-item .date {
    text-align: left;
    width: 110px;
    position: relative;
    padding-top: 30px;
  }
  .timeline-item .date i {
    position: absolute;
    top: 0;
    left: 15px;
    padding: 5px;
    width: 30px;
    text-align: center;
    border: 1px solid #e7eaec;
    background: #f8f8f8;
  }
  .timeline-item .content {
    border-left: none;
    border-top: 1px solid #e7eaec;
    padding-top: 10px;
    min-height: 100px;
  }
  .nav.navbar-top-links li.dropdown {
    display: none;
  }
}

@media (max-width: 990px) {
  .col-md-2.ui-scrollfix {
    position: relative;
    top: auto;
    margin-right: auto;
  }
  .catalog-item-view {
    float: none;
    width: auto;
  }
}

@media (min-width: 990px) {
  .col-md-2.ui-scrollfix {
    position: fixed;
    top: 113px;
    margin-right: 16px;
    background-color: white;
  }
  .catalog-item-view {
    float: right;
    width: 83%;
  }
}

@media (max-width: 990px) {
  .col-md-2.ui-scrollfix {
    position: relative;
    top: auto;
    margin-right: auto;
  }
  .standalone-catalog-item-view {
    float: none;
    width: auto;
  }
}

@media (min-width: 990px) {
  .col-md-2.ui-scrollfix {
    position: fixed;
    top: 113px;
    margin-right: 16px;
    background-color: white;
  }
  .standalone-catalog-item-view {
    float: right;
    width: 100%;
    background-color: white;
  }
}

.navbar-full {
  width: 220px;
}

.faq-page {
  font-size: 15px;
  max-width: 100%;
}

.faq-img {
  max-width: 70%;
  margin: 10px;
}

.video-card {
  padding: 20px 0;
}

.imagebox {
  padding: 0px;
  position: relative;
  text-align: center;
  width: 100%;
}

.imagebox img {
  opacity: 1;
  transition: 0.5s opacity;
}

.imagebox .imagebox-desc {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0px;
  color: white;
  font-size: 1.2em;
  left: 0px;
  padding: 10px 15px;
  position: absolute;
  transition: 0.5s padding;
  width: 100%;
}

.imagebox:hover img {
  opacity: 0.7;
}

.imagebox:hover .imagebox-desc {
  padding-bottom: 10%;
}

.lightbox-modal .modal-backdrop.in {
  filter: alpha(opacity=7);
  opacity: 0.7;
}

.lightbox-modal .modal-content {
  background: none;
  border: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.lightbox-modal .modal-content .modal-body {
  padding: 0 25px 25px 25px;
  background: none;
  border: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#scroll {
  position: fixed;
  right: 60px;
  bottom: 60px;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background-color: #1ab394;
  display: block;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
}

#scroll span {
  position: relative;
  top: 30%;
  left: 25%;
  margin-left: -8px;
  margin-top: -12px;
  height: 0;
  width: 0;
  color: #ffffff;
}

#scroll:hover {
  background-color: #2f4050;
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.backtotop {
  color: grey;
  font-size: 12px;
}

img.desaturate {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.detail-star-hover:hover {
  text-shadow: 0px 0px 3px #676a6c;
}

.detail-star-hover-o {
  color: #d1dade !important;
}

.detail-star-hover-o:hover {
  color: #676a6c !important;
}

.star-cell {
  text-align: center;
  vertical-align: middle !important;
  font-size: 20px;
  color: #d1dade;
}

.star-cell .star-hover:hover {
  text-shadow: 0px 0px 3px #676a6c;
}

.star-cell .star-hover-o:hover {
  color: #676a6c;
}

.star-check-cell {
  text-align: center;
  vertical-align: middle !important;
  font-size: 20px;
  color: #d1dade;
}

.star-check-cell .star-hover:hover {
  text-shadow: 0px 0px 3px #676a6c;
}

.star-check-cell .star-hover-o:hover {
  color: #676a6c;
}

.navbar-static-top {
  height: auto;
  margin-bottom: 0;
}

.middle-box h1 {
  font-size: 5em;
  letter-spacing: -.1em;
  margin-top: 2em;
}

.middle-box label {
  display: none;
}

/* General class */
.mb-20 {
  margin-bottom: 20em;
}

/* For input validation */
input.ng-touched.ng-invalid, .itemlist input.ng-touched.error-input {
  box-shadow: #f00 0 0 1em;
}

form.no-ng-check input.ng-touched.ng-invalid, form.no-ng-check .itemlist input.ng-touched.error-input, .itemlist form.no-ng-check input.ng-touched.error-input {
  box-shadow: none !important;
}

a.text-danger:hover {
  color: #ed5565;
}

.fa-star {
  color: #FFD700;
}

.label-select {
  line-height: 2.2;
}

.disable-form label {
  color: #EBEAE8 !important;
}

/*
Bootstrap styles (not quite well done by Inspinia)
*/
.btn-default {
  /* default button is dark gray and makes the button look like they are disabled */
  background-color: #84b5c3;
  border-color: #84b5c3;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active,
.btn-default .open .dropdown-toggle.btn-default {
  background-color: #7aafbe;
  border-color: #7aafbe;
}

.btn-normal {
  /* default button is dark gray and makes the button look like they are disabled */
  background-color: #c2c2c2;
  border-color: #c2c2c2;
  color: white;
}

.btn-normal:hover, .btn-normal:focus, .btn-normal:active, .btn-normal.active,
.btn-normal .open .dropdown-toggle.btn-default {
  background-color: #bababa;
  border-color: #bababa;
}

#search {
  /* default button is dark gray and makes the button look like they are disabled */
  background-color: #329d9c;
  border-color: #329d9c;
  border-radius: 0px 3px 3px 0px;
  font-size: 14.2px;
  color: white;
}

#search:hover, #search:focus, #search:active, #search.active,
#search .open .dropdown-toggle.btn-default {
  background-color: #329d9c;
  color: black;
}

.btn-link {
  color: #4a8697;
  font-weight: bold;
  font-size: 15px;
}

.a-href-btn {
  color: #337ab7;
  font-size: 13px;
  background-color: transparent;
  padding: 0;
  border-width: 0;
}

.a-href-btn:hover, .a-href-btn:focus {
  color: #23527c;
}

.a-href-btn[disabled] {
  cursor: not-allowed;
}

.panel-default > .panel-heading {
  background-color: #E0ECEF;
}

/*
* shopping summary scrollfix
*/
.panel-heading.ui-scrollfix {
  position: fixed;
  top: 0;
  z-index: 100;
  margin-right: 16px;
}

.form-horizontal.ui-scrollfix {
  position: relative;
  top: 0px;
  z-index: 10;
  margin-right: 31px;
}

.right-18 {
  position: relative;
  right: 18px;
}

/*
.col-md-2.ui-scrollfix{
  position: fixed;
  top: 113px;
  z-index: 10;
  margin-right: 16px;
}
*/
/*
Context-based title and action button in the nav bar
*/
.navbar {
  margin-bottom: 1em;
}

h1.navbar-text {
  font-size: 1.5em;
  margin-top: 19px;
  margin-bottom: 17px;
}

.navbar-btn {
  margin: 14px 1em 2px;
}

.minimalize-styl-2 {
  margin-left: 20px;
  margin-right: 5px;
  color: dimgrey;
}

/*
Fix ui.select bootstrap themed styling that has been overriden by Inspinia
*/
.ui-select-toggle {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  text-align: left;
  width: 100%;
}

.ui-select-toggle:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

/*
Fix Bootstrap datepicker styling
(overridden by Inspinia and Metabuyer with non-whitish background which looks ugly in datepicker)
*/
ul[datepicker-popup-wrap] .btn-default {
  background-color: #fff;
  border-color: #ccc;
  color: #676A6C;
}

/*
Fix Bootstrap table styling
(looks horrible with Inspinia gray background without panels)
*/
table {
  background: #fff;
}

.address-primary {
  color: #d3d3d3;
  pointer-events: none !important;
}

/*
Darker table header background colour to match with
*/
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  background-color: #e5e5e5;
}

/*
Darker table border colour to match with Inspinia gray background without panels
*/
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #d7d7d7;
}

/*
.table-condensed is overriden by Inspinia .table's styling
*/
.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

/*
Unbordered table style
*/
.table-unbordered > tbody + tbody,
.table-unbordered > thead > tr > th,
.table-unbordered > tbody > tr > th,
.table-unbordered > tfoot > tr > th,
.table-unbordered > thead > tr > td,
.table-unbordered > tbody > tr > td,
.table-unbordered > tfoot > tr > td {
  border: 0;
}

/*
Common Back button
*/
a.btn-back {
  display: inline-block;
  margin: 0 2em 1em 0;
}

/*
Sidebar
*/
body.mini-navbar #side-menu .dropdown-menu {
  left: 100%;
  top: 0;
}

body.mini-navbar .profile-element {
  cursor: pointer;
  text-align: center;
}

body.mini-navbar .profile-element .profile-photo {
  width: 48px;
  height: 48px;
}

body.mini-navbar .profile-element:hover .dropdown-menu {
  display: block;
}

body.mini-navbar .profile-element ul.dropdown-menu {
  background: #2f4050;
  margin: 0;
}

body.mini-navbar .profile-element ul.dropdown-menu li a {
  color: #a7b1c2;
  background-color: #2f4050;
  font-weight: 600;
}

body.mini-navbar .profile-element ul.dropdown-menu li:hover a {
  background-color: #293846;
  color: white;
}

body.mini-navbar .profile-element ul.dropdown-menu li.divider {
  background-color: #384759;
}

body.mini-navbar #logo-long-title {
  display: none;
}

/*
Toastr
Overriding the icons for three of the alert types that are done wrong by Inspinia
and override the one done right to match the style of the other three
*/
#toast-container {
  z-index: 999999;
}

#toast-container > .toast-warning:before {
  content: "\f06a";
}

#toast-container > .toast-error:before {
  content: "\f056";
}

#toast-container > .toast-success:before {
  content: "\f058";
}

/*
PR listing styling
*/
.state_requisitions_list .nav-tabs {
  margin-bottom: 1em;
}

.state_requisitions_list tr {
  cursor: pointer;
}

.state_requisitions_list tr:hover .icheckbox_minimal-grey {
  background-position: -20px 0 !important;
}

.state_requisitions_list tr:hover .icheckbox_minimal-grey.checked {
  background-position: -40px 0 !important;
}

.state_requisitions_list thead > tr {
  background-color: #e5e5e5;
  font-weight: bold;
}

.state_requisitions_list tr:hover .star-hover-o {
  color: #676a6c;
}

.state_requisitions_list tr.no-data {
  cursor: default;
}

.state_requisitions_list .search-dropdown {
  margin: 10px auto 10px auto;
  max-width: 300px;
}

.state_requisitions_list .search-dropdown-item {
  overflow: hidden;
}

.state_requisitions_list .no-padding {
  padding: 0px;
  margin: 0px;
}

.state_requisitions_list .no-padding-left {
  padding-left: 0px;
  margin-left: 0px;
}

.state_requisitions_list .row-beauty {
  margin-top: 5px;
  margin-bottom: 5px;
}

.state_requisitions_list .fixed-top {
  z-index: 100;
}

.state_requisitions_list .star-width {
  width: 60px;
}

.state_requisitions_list .amount-width {
  width: 150px;
}

.state_requisitions_list .submitted-width {
  width: 80px;
  max-width: 85px;
}

.state_requisitions_list .progress-width {
  width: 120px;
  min-width: 90px;
}

.state_requisitions_list .pagination-focus .btn:focus {
  outline-color: white;
}

.state_requisitions_list .share-tab-focus .btn:focus {
  outline: none;
}

/*
PR details + xeditable inline edit module styling
*/
.state_requisitions_details .editable-wrap {
  position: relative;
  bottom: 5px;
}

.state_requisitions_details .pr-btn {
  position: relative;
  top: 6px;
}

.state_requisitions_details .pr-warning-numberofitems {
  margin-bottom: 5px;
}

.state_requisitions_details .pr-warning-item {
  margin-top: 10px;
}

.state_requisitions_details .pr-warning-step {
  margin-top: 5px;
}

.state_requisitions_details .pr-title {
  font-style: normal;
  min-width: 500px !important;
  height: 30px !important;
  font-size: 24px;
  color: #262626;
  font-weight: 100;
  cursor: pointer;
}

.state_requisitions_details #prTitleSpan {
  display: inline-block;
  max-width: 50%;
  float: left;
  word-break: break-all;
}

.state_requisitions_details .back-buttons {
  display: block;
  margin: 0 1em 1em 0;
  vertical-align: super;
  float: left;
}

.state_requisitions_details .collaborators {
  height: 40px;
  width: 40px;
  margin: 0 .2em;
}

.state_requisitions_details h3 {
  padding-top: 15px;
}

.state_requisitions_details h2.editable {
  border: 0;
  color: #888;
  display: inline-block;
  font-style: normal;
  margin-bottom: 1em;
}

.state_requisitions_details h2.editable + form.editable-wrap {
  margin-top: 1em;
  margin-bottom: 1.35em;
}

.state_requisitions_details i.is_starred {
  margin: 0 0 0 1em;
}

.state_requisitions_details > .row {
  opacity: 1;
  max-height: 90000px;
  clear: both;
  margin: 1em 2em;
}

.state_requisitions_details > .row.ng-hide {
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}

.state_requisitions_details .jumbotron {
  font-size: 1.1em;
  padding: 3em 6em;
}

.state_requisitions_details .jumbotron .editable-wrap {
  white-space: normal;
}

.state_requisitions_details .jumbotron .editable-click,
.state_requisitions_details .jumbotron .editable-click:hover,
.state_requisitions_details .jumbotron .editable-click:focus,
.state_requisitions_details .jumbotron a.editable-click,
.state_requisitions_details .jumbotron a.editable-click:hover,
.state_requisitions_details .jumbotron a.editable-click:focus,
.state_requisitions_details .jumbotron .editable-empty,
.state_requisitions_details .jumbotron .editable-empty:hover,
.state_requisitions_details .jumbotron .editable-empty:focus,
.state_requisitions_details .jumbotron a.editable-empty,
.state_requisitions_details .jumbotron a.editable-empty:hover,
.state_requisitions_details .jumbotron a.editable-empty:focus {
  border: 0;
  display: inline-block;
  font-style: normal;
  margin: .3em 0;
}

.state_requisitions_details .jumbotron hr {
  border-top: 1px solid #ccc;
}

.state_requisitions_details .jumbotron .form-group {
  margin-bottom: 8px;
}

.state_requisitions_details .jumbotron .form-group label {
  text-transform: none;
}

.state_requisitions_details .jumbotron .form-group .input-group button.needed-by-clear-button {
  background: white;
  border: none;
  height: 14px;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  z-index: 999;
  float: right;
  margin-top: 3px;
}

.state_requisitions_details .jumbotron .form-group .input-group button.btn-danger {
  display: none;
}

.state_requisitions_details h3 {
  font-size: 1.5em;
  margin: 0 0 1.5em;
  text-align: right;
}

.state_requisitions_details h3.text-center {
  text-align: center !important;
}

.state_requisitions_details label:not(.tenderMemoAttachment) {
  color: #676A6C;
  font-weight: normal;
  line-height: 2.2;
  text-align: right;
  text-transform: lowercase;
  margin-top: -5px;
}

.state_requisitions_details label.font-bold {
  font-weight: bold;
  color: #676a6c;
  text-transform: none;
}

.state_requisitions_details label#pagination-label {
  color: #aaa;
  font-weight: normal;
  text-align: right;
  font-size: 13px !important;
  margin-top: 0px;
}

.state_requisitions_details label.color-pagination {
  color: #aaa;
  font-weight: normal;
  line-height: 2.2;
  text-align: right;
  text-transform: lowercase;
}

.state_requisitions_details form[name=addNonCatalogItem] h3 {
  text-align: left;
}

.state_requisitions_details .breadcrumb {
  background-color: transparent;
}

.state_requisitions_details .breadcrumb li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.state_requisitions_details .breadcrumb > li {
  display: block;
}

.state_requisitions_details .breadcrumb > li + li {
  padding-left: 15px;
}

.state_requisitions_details .breadcrumb > li + li + li {
  padding-left: 30px;
}

.state_requisitions_details .breadcrumb > li + li + li + li {
  padding-left: 45px;
}

.state_requisitions_details .breadcrumb > li + li + li + li + li {
  padding-left: 60px;
}

.state_requisitions_details .breadcrumb > li a:hover {
  color: #1c84c6;
}

.state_requisitions_details .breadcrumb > li + li::before {
  content: '';
  padding: 0;
  color: inherit;
}

.state_requisitions_details .breadcrumb > li > b + ul {
  list-style: none;
  padding-left: 15px;
}

.state_requisitions_details .editable-empty,
.state_requisitions_details .editable-empty:hover,
.state_requisitions_details .editable-empty:focus,
.state_requisitions_details a.editable-empty,
.state_requisitions_details a.editable-empty:hover,
.state_requisitions_details a.editable-empty:focus {
  color: #ccc;
}

.state_requisitions_details .all-categories {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.state_requisitions_details .all-categories .panel {
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}

.state_requisitions_details .all-categories .panel-default > .panel-heading {
  background-color: #f5f5f5;
}

.state_requisitions_details .all-categories h2 {
  margin: 0;
  font-size: 1.2em;
  line-height: 1.2;
}

.state_requisitions_details .header-section .alert-section {
  float: right;
}

.state_requisitions_details .header-section .alert-change {
  /*background-color: #c4453c;
      background-image: -webkit-linear-gradient(135deg, transparent,
        transparent 25%, hsla(0,0%,0%,.05) 25%,
        hsla(0,0%,0%,.05) 50%, transparent 50%,
        transparent 75%, hsla(0,0%,0%,.05) 75%,
        hsla(0,0%,0%,.05));
      background-image: -moz-linear-gradient(135deg, transparent,
        transparent 25%, hsla(0,0%,0%,.1) 25%,
        hsla(0,0%,0%,.1) 50%, transparent 50%,
        transparent 75%, hsla(0,0%,0%,.1) 75%,
        hsla(0,0%,0%,.1));
      background-image: -ms-linear-gradient(135deg, transparent,
        transparent 25%, hsla(0,0%,0%,.1) 25%,
        hsla(0,0%,0%,.1) 50%, transparent 50%,
        transparent 75%, hsla(0,0%,0%,.1) 75%,
        hsla(0,0%,0%,.1));
      background-image: -o-linear-gradient(135deg, transparent,
        transparent 25%, hsla(0,0%,0%,.1) 25%,
        hsla(0,0%,0%,.1) 50%, transparent 50%,
        transparent 75%, hsla(0,0%,0%,.1) 75%,
        hsla(0,0%,0%,.1));
      background-image: linear-gradient(135deg, transparent,
        transparent 25%, hsla(0,0%,0%,.1) 25%,
        hsla(0,0%,0%,.1) 50%, transparent 50%,
        transparent 75%, hsla(0,0%,0%,.1) 75%,
        hsla(0,0%,0%,.1));
      background-size: 20px 20px;*/
  color: #c4453c;
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  padding-left: 5px;
  padding-right: 5px;
  font-style: normal;
  font-size: 24px;
  font-weight: 100;
  border-bottom-style: none;
  -webkit-animation: alert 5s ease forwards;
  -moz-animation: alert 5s ease forwards;
  -ms-animation: alert 5s ease forwards;
  -o-animation: alert 5s ease forwards;
  animation: alert 5s ease forwards;
}

.state_requisitions_details .alert-non-catalog-item {
  min-height: 85px;
  padding: 15px;
  margin-top: 50px;
}

.state_requisitions_details .alert-non-catalog-item i.fa-warning {
  color: #f8ac59;
}

.state_requisitions_details .duplicate-item {
  margin-top: 15px;
  color: grey;
  font-size: 16px;
  cursor: pointer;
}

.state_requisitions_details .discount-input {
  width: 25% !important;
}

.view-pr-link-on-toaster {
  text-decoration: underline;
}

/* Animation */
@-webkit-keyframes alert {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
  }
}

@-moz-keyframes alert {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
  }
}

@-ms-keyframes alert {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
  }
}

@-o-keyframes alert {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
  }
}

@keyframes alert {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
  }
}

.jumbotron.jumbotron-noneditable-color-v2 {
  background: #E0ECEF;
}

.jumbotron.jumbotron-noneditable-color-v2 .form-group label {
  padding-left: 0px !important;
}

.jumbotron.jumbotron-noneditable-color-v2 input[type=checkbox] + label.prev-version {
  text-decoration: line-through;
}

.jumbotron.jumbotron-noneditable-color-v2 .jumbotron-title {
  word-wrap: break-word;
}

.jumbotron.jumbotron-noneditable-color-v2 .jumbotron-title del {
  background-color: #ffc6c6;
  font-size: 24px;
}

.jumbotron.jumbotron-noneditable-color-v2 .jumbotron-title ins {
  background-color: #c6ffc6;
  text-decoration: none;
  font-size: 24px;
}

.jumbotron.jumbotron-noneditable-color {
  background: #E0ECEF;
}

.jumbotron.jumbotron-noneditable-color .jumbotron-title {
  word-wrap: break-word;
}

.jumbotron.jumbotron-noneditable-color .jumbotron-title del {
  background-color: #ffc6c6;
  font-size: 24px;
}

.jumbotron.jumbotron-noneditable-color .jumbotron-title ins {
  background-color: #c6ffc6;
  text-decoration: none;
  font-size: 24px;
}

.shopping-summary {
  background: transparent;
  margin: 4em auto 1em;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
}

.shopping-summary .product-image-wrapper {
  cursor: default;
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.shopping-summary tr:hover {
  background-color: #eaf2f4;
}

.shopping-summary > thead > tr > th,
.shopping-summary > tbody > tr > th,
.shopping-summary > tfoot > tr > th,
.shopping-summary > thead > tr > td,
.shopping-summary > tbody > tr > td,
.shopping-summary > tfoot > tr > td {
  vertical-align: middle;
}

.shopping-summary input {
  width: 100%;
}

.shopping-summary .column-image {
  width: 56px;
}

.shopping-summary .column-quantity {
  width: 5%;
}

.shopping-summary .column-price {
  width: 10%;
}

.shopping-summary .column-remove {
  width: 46px;
}

.shopping-summary h4 {
  overflow-wrap: break-word;
}

.print-section.pr-table tfoot {
  font-size: 12px;
}

.print-section.pr-table .column-checkbox {
  width: 5%;
}

.print-section.pr-table .column-image {
  width: 5%;
}

.print-section.pr-table .column-item-details {
  width: 35%;
}

.print-section.pr-table .column-item-details-no-check-box {
  width: 40% !important;
}

.print-section.pr-table .column-quantity {
  width: 80px;
  text-align: center;
}

.print-section.pr-table .column-unit-price {
  text-align: center;
}

.print-section.pr-table .column-rcvd {
  width: 80px;
}

.print-section.pr-table .column-amount {
  text-align: center;
}

.print-section.pr-table .column-details-value {
  width: 90%;
}

.print-section.pr-table .column-details-remove {
  width: 10%;
}

.itemlist input,
.itemlist .editable-textarea,
.itemlist textarea {
  width: 100%;
}

.itemlist .table-padding {
  padding: 10px;
}

.itemlist .table-padding-for-small {
  padding: 15px;
}

.itemlist .text-extra-small {
  font-size: 10px;
}

.itemlist button.btn-add-itemlist {
  margin-bottom: 25px;
}

.itemlist button.btn-add-itemlist.other-button {
  float: left;
}

.itemlist textarea {
  height: 6em;
}

.itemlist .product-image-wrapper {
  cursor: default;
  height: 100px;
  line-height: 100px;
  width: 100px;
}

.itemlist .column-checkbox {
  width: 5%;
}

.itemlist .column-image {
  width: 5%;
}

.itemlist .column-item-details {
  width: 35%;
}

.itemlist .column-item-details-no-check-box {
  width: 40% !important;
}

.itemlist .column-quantity {
  width: 80px;
  text-align: center;
}

.itemlist .column-unit-price {
  text-align: center;
}

.itemlist .column-rcvd {
  width: 80px;
}

.itemlist .column-amount {
  text-align: center;
}

.itemlist .column-details-value {
  width: 90%;
}

.itemlist .column-details-remove {
  width: 10%;
}

.itemlist .table-calculations tr:first-child td:nth-child(3n+1) {
  white-space: nowrap;
}

.itemlist .table-calculations td:nth-child(3n+2),
.itemlist .table-calculations td:nth-child(3n+2) input {
  text-align: center;
  white-space: nowrap;
}

.itemlist tbody td {
  text-align: center;
}

.itemlist thead th {
  text-align: center;
}

.itemlist table .table.table-condensed > tbody > tr > td {
  text-align: left !important;
}

.itemlist table .table.table-condensed > tbody > tr > td button.btn-danger {
  display: none;
}

.itemlist .column-calculations-label {
  width: 50%;
}

.itemlist .column-calculations-value {
  text-align: center;
  width: 50%;
}

.itemlist .column-calculations-remove {
  width: 32px;
}

.itemlist .table-calculations td:nth-child(3n+3) {
  min-width: 32px;
  text-align: center;
}

.itemlist .ui-select-toggle {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  height: 22px;
}

.itemlist .ui-select-search {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.itemlist tfoot {
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
}

.itemlist thead, .itemlist tr {
  cursor: default;
}

.itemlist .add-item-scroll-fix {
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 31;
}

.itemlist .add-item-scroll-fix button {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  opacity: 0.6;
}

.itemlist .add-item-scroll-fix .btn:focus, .itemlist .add-item-scroll-fix .btn:active:focus {
  border: none;
  outline: none;
}

.itemlist .add-amount, .itemlist .add-discount, .itemlist .add-gst {
  border-bottom: 1px dashed !important;
}

.itemlist .add-amount.editable-empty {
  color: #337ab7;
  text-decoration: none;
  font-style: normal;
}

.itemlist .gst-scroll-list {
  height: 200px;
  overflow-y: scroll;
}

.itemlist .input-focus-width {
  width: 30%;
  min-width: 50px;
  outline: none;
}

.itemlist .no-border-top td {
  border-top: none;
}

.itemlist .custom-menu td {
  padding-top: 4px;
  padding-bottom: 4px;
}

.itemlist .error-message {
  color: #ed5565;
}

.itemlist .error-input {
  border-color: #ed5565;
  outline: none;
}

.itemlist .pre-line {
  white-space: pre-wrap;
}

.itemlist .grey-text {
  color: #676A6C;
}

.itemlist .grey-text a {
  color: #C8C8C8;
}

.itemlist tr.tr-freight-transportation-split {
  border-top: dashed 1px lightgrey;
  border-bottom: dashed 1px lightgrey;
}

.itemlist tr.tr-freight-transportation-split td {
  border: none !important;
}

/*
 PO listing styling
*/
.state_orders_list .nav-tabs {
  margin-bottom: 1em;
}

.state_orders_list tr {
  cursor: pointer;
}

.state_orders_list tr:hover .icheckbox_minimal-grey {
  background-position: -20px 0 !important;
}

.state_orders_list tr:hover .icheckbox_minimal-grey.checked {
  background-position: -40px 0 !important;
}

.state_orders_list tr:hover .star-hover-o {
  color: #676a6c;
}

.state_orders_list thead > tr {
  background-color: #e5e5e5;
  font-weight: bold;
}

.state_orders_list tr.no-data {
  cursor: default;
}

.state_orders_list .search-dropdown {
  margin: 10px auto 10px auto;
  max-width: 300px;
}

.state_orders_list .search-dropdown-item {
  overflow: hidden;
}

.state_orders_list .no-padding {
  padding: 0px;
  margin: 0px;
}

.state_orders_list .row-beauty {
  margin-top: 5px;
  margin-bottom: 5px;
}

.state_orders_list .fixed-top {
  z-index: 100;
}

.state_orders_list .star-width {
  width: 60px;
}

.pagination-margin-bottom {
  margin-bottom: 5px;
}

.clear-floating {
  clear: both;
}

/*
  Navigation tab cursor
 */
ul.nav-tabs li.active a {
  cursor: pointer !important;
}

/*
 star-cellisting styling
*/
.state_orders_list .nav-tabs {
  margin-bottom: 1em;
}

.state_orders_list tr {
  cursor: pointer;
}

.state_orders_list tr:hover .icheckbox_minimal-grey {
  background-position: -20px 0 !important;
}

.state_orders_list tr:hover .icheckbox_minimal-grey.checked {
  background-position: -40px 0 !important;
}

.state_orders_list tr:hover .star-hover-o {
  color: #676a6c;
}

.state_orders_list thead > tr {
  background-color: #e5e5e5;
}

.state_orders_list tr.no-data {
  cursor: default;
}

.state_orders_list .search-dropdown {
  margin: 10px auto 10px auto;
  max-width: 300px;
}

.state_orders_list .search-dropdown-item {
  overflow: hidden;
}

.state_orders_list .no-padding {
  padding: 0px;
  margin: 0px;
}

.state_orders_list .row-beauty {
  margin-top: 5px;
  margin-bottom: 5px;
}

.state_orders_list .fixed-top {
  z-index: 100;
}

.state_orders_list .star-width {
  width: 60px;
}

/*
PO details + xeditable inline edit module styling
*/
.state_orders_details label#pagination-label {
  color: #aaa;
  font-weight: normal;
  text-align: right;
  font-size: 13px !important;
  margin-top: 0px;
}

.state_orders_details .back-buttons {
  display: block;
  margin: 0 1em 1em 0;
  vertical-align: super;
  float: left;
}

.state_orders_details .collaborators {
  height: 40px;
  width: 40px;
  margin: 0 .2em;
}

.state_orders_details h2.editable {
  border: 0;
  color: #888;
  display: inline-block;
  font-style: normal;
  margin-bottom: 1em;
}

.state_orders_details h2.editable + form.editable-wrap {
  margin-top: 1em;
  margin-bottom: 1.35em;
}

.state_orders_details i.is_starred {
  margin: 0 0 0 1em;
}

.state_orders_details .jumbotron {
  background: #E0ECEF;
  font-size: 1.1em;
  padding: 3em 6em;
}

.state_orders_details .jumbotron .editable-wrap {
  white-space: normal;
}

.state_orders_details .jumbotron .editable-click,
.state_orders_details .jumbotron .editable-click:hover,
.state_orders_details .jumbotron .editable-click:focus,
.state_orders_details .jumbotron a.editable-click,
.state_orders_details .jumbotron a.editable-click:hover,
.state_orders_details .jumbotron a.editable-click:focus,
.state_orders_details .jumbotron .editable-empty,
.state_orders_details .jumbotron .editable-empty:hover,
.state_orders_details .jumbotron .editable-empty:focus,
.state_orders_details .jumbotron a.editable-empty,
.state_orders_details .jumbotron a.editable-empty:hover,
.state_orders_details .jumbotron a.editable-empty:focus {
  border: 0;
  display: inline-block;
  font-style: normal;
  margin: .3em 0;
}

.state_orders_details .jumbotron .editable-empty,
.state_orders_details .jumbotron .editable-empty:hover,
.state_orders_details .jumbotron .editable-empty:focus,
.state_orders_details .jumbotron a.editable-empty,
.state_orders_details .jumbotron a.editable-empty:hover,
.state_orders_details .jumbotron a.editable-empty:focus {
  color: #ccc;
}

.state_orders_details .jumbotron hr {
  border-top: 1px solid #ccc;
}

.state_orders_details .jumbotron .form-group {
  margin-bottom: 8px;
}

.state_orders_details h3 {
  font-size: 1.5em;
  margin: 0 0 1.5em;
  text-align: right;
}

.state_orders_details label {
  color: #676A6C;
  font-weight: normal;
  line-height: 2.2;
  text-align: right;
  margin-top: -5px;
}

.state_orders_details form[name=addNonCatalogItem] h3 {
  text-align: left;
}

.state_orders_details .breadcrumb {
  background-color: transparent;
  padding-left: 15px;
}

.state_orders_details .breadcrumb > li {
  display: block;
}

.state_orders_details .breadcrumb > li a:hover {
  color: #1c84c6;
}

.state_orders_details .breadcrumb > li + li::before {
  content: '';
  padding: 0;
  color: inherit;
}

.state_orders_details .breadcrumb > li > a {
  position: relative;
}

.state_orders_details .breadcrumb > li > a::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f060";
  position: absolute;
  left: -1em;
  line-height: inherit;
}

.state_orders_details .breadcrumb > li > b + ul {
  list-style: none;
  padding-left: 15px;
}

.state_orders_details tr {
  cursor: default;
}

.state_orders_details span[name="scrollToClosedItem"] {
  cursor: pointer;
}

.state_orders_details .supplier-section-min-width {
  min-width: 132px;
}

/*
* items listing styling
*/
.state_items_list .nav-tabs {
  margin-bottom: 1em;
}

.state_items_list tr {
  cursor: pointer;
}

.state_items_list tr:hover .icheckbox_minimal-grey {
  background-position: -20px 0 !important;
}

.state_items_list tr:hover .icheckbox_minimal-grey.checked {
  background-position: -40px 0 !important;
}

.state_items_list tr.no-data {
  cursor: default;
}

.state_items_list thead > tr {
  background-color: #e5e5e5;
  font-weight: bold;
}

.state_items_list .star-width {
  width: 60px;
}

.state_items_list .search-dropdown {
  margin: 10px auto 10px auto;
  max-width: 300px;
}

.state_items_list .search-dropdown-item {
  overflow: hidden;
}

.state_items_list .no-padding {
  padding: 0px;
  margin: 0px;
}

.state_items_list .row-beauty {
  margin-top: 5px;
  margin-bottom: 5px;
}

.state_items_list .fixed-top {
  z-index: 999;
}

.state_items_list .clear-float {
  clear: both;
}

/*
comments
*/
.feed-element .well {
  font-size: 1.1em;
}

.feed-element .hashtag-item {
  margin-top: -120px;
  /* Size of fixed header */
  padding-bottom: 120px;
  display: block;
}

.feed-element .comment-action-margin {
  margin-left: 50px;
}

.feed-element a.po-goods-link-number {
  font-size: 12px;
  margin-left: -10px;
}

.feed-element .comment-reply {
  margin-top: 10px;
}

/*
product listing
*/
.product-image-wrapper {
  cursor: pointer;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.product-image-wrapper img {
  display: inline;
  max-height: 100%;
  max-width: 100%;
}

/*.thumbnail.selected {
  outline: 2px solid $navy;
  border-radius: 0;
  border-color: $navy;
}*/
.caption p, .caption h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.caption input {
  width: 3em;
}

.caption .catalog-description {
  height: 50px;
  white-space: normal !important;
  line-height: normal !important;
}

.blue-title {
  color: #0066c0;
}

.red-price {
  color: #b12704;
}

/*
product details
*/
.product-details {
  font-size: 1.1em;
  margin: 1em;
}

.product-details h4 {
  font-size: 1.4em;
}

.product-details aside {
  width: 40%;
}

.product-details .product-images img {
  max-height: 600px;
  margin: auto;
  height: 300px;
}

.product-details .product-variations table {
  width: 100%;
}

.product-details .product-variations td {
  padding: .5em;
}

.product-details .product-action {
  margin: .5em 0;
  text-align: center;
}

.product-details .product-action input[type="number"] {
  text-align: right;
  width: 5em;
}

.product-details .star-hover-o {
  color: #d1dade;
}

.product-details .star-hover-o:hover {
  color: #676a6c;
}

.product-details .star-hover:hover {
  text-shadow: 0px 0px 3px #676a6c;
}

/*
User & company listing styling
*/
.state_users_companies_list .nav-tabs {
  margin-bottom: 1em;
}

.state_users_companies_list tr {
  cursor: pointer;
}

.state_users_companies_list .expense-header {
  margin-top: 5px;
  font-weight: 600;
  font-size: 16px;
  margin-left: 15px;
}

.state_users_companies_list .margin-right {
  margin-right: 15px;
}

.state_users_companies_list thead > tr {
  background-color: #e5e5e5;
  font-weight: bold;
}

.state_users_companies_list tr:hover .icheckbox_minimal-grey {
  background-position: -20px 0 !important;
}

.state_users_companies_list tr:hover .icheckbox_minimal-grey.checked {
  background-position: -40px 0 !important;
}

.state_users_companies_list tr.no-data {
  cursor: default;
}

.state_users_companies_list .search-dropdown {
  margin: 10px auto 10px auto;
  max-width: 300px;
}

.state_users_companies_list .search-dropdown-item {
  overflow: hidden;
}

.state_users_companies_list .no-padding {
  padding: 0px;
  margin: 0px;
}

.state_users_companies_list .row-beauty {
  margin-top: 5px;
  margin-bottom: 5px;
}

.state_users_companies_list .fixed-top {
  z-index: 999;
}

.state_users_companies_list .fixed-top.ui-scrollfix {
  position: fixed;
  top: 0;
}

/*
* users listing styling
*/
.state_users_list .nav-tabs, .state_roles_list .nav-tabs {
  margin-bottom: 1em;
}

.state_users_list tr, .state_roles_list tr {
  cursor: pointer;
}

.state_users_list tr:hover .icheckbox_minimal-grey, .state_roles_list tr:hover .icheckbox_minimal-grey {
  background-position: -20px 0 !important;
}

.state_users_list tr:hover .icheckbox_minimal-grey.checked, .state_roles_list tr:hover .icheckbox_minimal-grey.checked {
  background-position: -40px 0 !important;
}

.state_users_list tr.no-data, .state_roles_list tr.no-data {
  cursor: default;
}

.state_users_list thead > tr, .state_roles_list thead > tr {
  background-color: #e5e5e5;
  font-weight: bold;
}

.state_users_list .star-width, .state_roles_list .star-width {
  width: 60px;
}

.state_users_list .search-dropdown, .state_roles_list .search-dropdown {
  margin: 10px auto 10px auto;
  max-width: 300px;
}

.state_users_list .search-dropdown-item, .state_roles_list .search-dropdown-item {
  overflow: hidden;
}

.state_users_list .no-padding, .state_roles_list .no-padding {
  padding: 0px;
  margin: 0px;
}

.state_users_list .row-beauty, .state_roles_list .row-beauty {
  margin-top: 5px;
  margin-bottom: 5px;
}

.state_users_list .fixed-top, .state_roles_list .fixed-top {
  z-index: 999;
}

.state_users_list .fixed-top.ui-scrollfix, .state_roles_list .fixed-top.ui-scrollfix {
  position: fixed;
  top: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
  margin-right: 15px;
}

.state_users_list .clear-float, .state_roles_list .clear-float {
  clear: both;
}

.state_users_list .margin-action, .state_roles_list .margin-action {
  margin-left: 1em;
}

.state_users_list .editable-wrap, .state_roles_list .editable-wrap {
  width: 100%;
}

/*
Users & Companies details + xeditable inline edit module styling
*/
.state_users_companies_details h2.editable {
  border: 0;
  color: #888;
  display: inline-block;
  font-style: normal;
  margin-bottom: 1em;
}

.state_users_companies_details h2.editable + form.editable-wrap {
  margin-top: 1em;
  margin-bottom: 1.35em;
}

.state_users_companies_details i.is_starred {
  margin: 0 0 0 1em;
}

.state_users_companies_details .jumbotron {
  background: #E0ECEF;
  font-size: 1.1em;
  padding: 2em;
}

.state_users_companies_details .jumbotron .editable-wrap {
  white-space: normal;
}

.state_users_companies_details .jumbotron .editable-click,
.state_users_companies_details .jumbotron .editable-click:hover,
.state_users_companies_details .jumbotron .editable-click:focus,
.state_users_companies_details .jumbotron a.editable-click,
.state_users_companies_details .jumbotron a.editable-click:hover,
.state_users_companies_details .jumbotron a.editable-click:focus,
.state_users_companies_details .jumbotron .editable-empty,
.state_users_companies_details .jumbotron .editable-empty:hover,
.state_users_companies_details .jumbotron .editable-empty:focus,
.state_users_companies_details .jumbotron a.editable-empty,
.state_users_companies_details .jumbotron a.editable-empty:hover,
.state_users_companies_details .jumbotron a.editable-empty:focus {
  border: 0;
  display: inline-block;
  font-style: normal;
  margin: .3em 0;
}

.state_users_companies_details .jumbotron .editable-empty,
.state_users_companies_details .jumbotron .editable-empty:hover,
.state_users_companies_details .jumbotron .editable-empty:focus,
.state_users_companies_details .jumbotron a.editable-empty,
.state_users_companies_details .jumbotron a.editable-empty:hover,
.state_users_companies_details .jumbotron a.editable-empty:focus {
  color: #ccc;
}

.state_users_companies_details .jumbotron hr {
  border-top: 1px solid #ccc;
}

.state_users_companies_details .jumbotron h2 {
  font-weight: bold;
  margin: 0 0 1.5em;
}

.state_users_companies_details h3 {
  font-size: 1.5em;
  margin: 0 0 1.5em;
  text-align: right;
}

.state_users_companies_details label {
  color: #aaa;
  font-weight: normal;
  line-height: 2.2;
  text-align: right;
  text-transform: lowercase;
}

.state_users_companies_list .editable-wrap {
  width: 100%;
}

.st-sort-ascent:after {
  content: '\25B2';
}

.st-sort-descent:after {
  content: '\25BC';
}

.row-select {
  border-top: 2px solid #1ab394;
  border-left: 2px solid #1ab394;
  border-right: 2px solid #1ab394;
}

.row-select-add-button {
  border-left: 2px solid #1ab394;
  border-right: 2px solid #1ab394;
  border-bottom: 2px solid #1ab394;
}

.row-select-freight-transport-button {
  border-left: 2px solid #1ab394;
  border-right: 2px solid #1ab394;
}

.freight-row-select {
  border-top: none;
  border-left: 2px solid;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-color: #1ab394;
}

.freight-row-select-with-transport {
  border-left: 2px solid;
  border-right: 2px solid;
  border-color: #1ab394;
}

.grey-list {
  background-color: #EBEAE8;
}

.none-active {
  box-shadow: none !important;
}

.action-scroll-fix {
  position: fixed;
  top: 60px;
  width: 20%;
}

.mini-navbar .action-scroll-fix {
  width: 22%;
}

.action-scroll-fix.ui-scrollfix {
  position: fixed;
  top: 0;
}

.deleteComment {
  margin-left: 10px;
}

.media-body {
  width: auto;
}

.media-body textarea {
  width: 100%;
  min-height: 58px;
}

.media-body .well {
  background: #f3f3f4;
  overflow-y: auto;
  padding: 19px;
}

.media-body .no-wrap {
  white-space: nowrap;
}

.supplier-comment .well {
  white-space: normal;
  padding: 10px 30px;
}

.supplier-comment .well span {
  display: block;
  margin-top: 10px;
}

.supplier-comment .well span span {
  display: inline;
  color: #1c84c6;
  cursor: pointer;
}

.mentions-input-box .mentions > div {
  font-size: 14px;
}

.ui-select-match-text {
  display: block;
  overflow: hidden;
  width: 100%;
}

/* Toastr custom style */
#toast-container > .toast {
  background-image: none !important;
}

#toast-container > .toast:before {
  position: fixed;
  font-family: FontAwesome;
  font-size: 24px;
  line-height: 24px;
  float: left;
  color: #FFF;
  padding-right: 0.5em;
  margin: auto 0.5em auto -1.5em;
}

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container > div {
  -moz-box-shadow: 0 0 3px #999;
  -webkit-box-shadow: 0 0 3px #999;
  box-shadow: 0 0 3px #999;
  opacity: .9;
  -ms-filter: alpha(Opacity=90);
  filter: alpha(opacity=90);
}

#toast-container > :hover {
  -moz-box-shadow: 0 0 4px #999;
  -webkit-box-shadow: 0 0 4px #999;
  box-shadow: 0 0 4px #999;
  opacity: 1;
  -ms-filter: alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

.toast {
  background-color: #1ab394;
}

.toast-success {
  background-color: #55bb33;
}

.toast-error {
  background-color: #ed5565;
}

.toast-info {
  background-color: #23c6c8;
}

.toast-warning {
  background-color: #f8ac59;
}

.toast-top-full-width {
  margin-top: 20px;
}

.toast-bottom-full-width {
  margin-bottom: 20px;
}

/*
* pr action menu
*/
.pr-action {
  margin-top: -10px;
}

.pr-action .pr-action-icon {
  margin-right: 8px;
}

.pr-action .pr-action-icon-del {
  color: red;
}

.pr-action .clear-more-padding-right {
  padding-right: 0;
}

.pr-action.pr-action-margin-top {
  margin-top: 0px;
}

.pr-action.pr-action-access-directly {
  margin-top: 0px;
}

.pr-id {
  font-size: 24px;
  margin-left: 20px;
  color: #262626;
  font-weight: 100;
}

.pr-status-access-directly {
  font-size: 24px;
  color: #262626;
  font-weight: 100;
}

.po-status-access-directly {
  font-size: 24px;
  color: #262626;
  font-weight: 100;
}

.pr-share-with .pr-share-title {
  font-weight: 100;
  color: #aaa;
}

.pr-share-with ul.pr-collaborators-avatar {
  padding: 0 10px;
  margin: 0;
}

.pr-share-with ul.pr-collaborators-avatar li {
  display: inline;
  padding-right: 10px;
}

.pr-share-with ul.pr-collaborators-avatar li img {
  width: 27px;
  height: 27px;
}

.pr-share-with.pr-share-with-on-access-directly {
  margin-top: 10px;
}

.pr-share-with.pr-share-with-on-access-directly .pr-share-title {
  margin-left: 0px;
}

/*
user management styling
*/
.state_user_management_list .nav-tabs {
  margin-bottom: 1em;
}

.state_user_management_list .container-fluid .ibox-content .timeline-item .date {
  width: 13%;
}

/*
score chart
*/
.your-core-chart {
  position: relative;
  margin-left: 10px;
  width: 55px;
}

.your-core-chart .chart-progress-bar {
  position: absolute;
  bottom: -8px;
}

.your-core-chart .chart-progress-bar .chart-progress {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 28px;
  border-radius: 3px;
  background: #c2c2c2;
}

.your-core-chart .chart-progress-bar .chart-progress .chart-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  border-radius: 3px;
  display: inline-block;
  background: #1ab394;
}

.your-core-chart .chart-info {
  margin-left: 15px;
  position: absolute;
  bottom: -4px;
}

.your-core-chart .chart-info .chart-label {
  font-size: 12px;
  text-align: center;
  color: #a7b1c2;
  line-height: 10px;
}

.your-core-chart .chart-info .chart-value {
  font-size: 20px;
  line-height: 18px;
  color: #1ab394;
  font-weight: 600;
}

.dropzone.dz-started .dz-message {
  opacity: 1 !important;
}

.state_explore_apps_list .apps-list .app-item {
  width: 150px;
  margin: 5px;
  background: #DDDEE0;
  height: 150px;
  border-radius: 10px;
  float: left;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: black;
  display: table;
}

.state_explore_apps_list .apps-list .app-item span {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  cursor: move;
}

/*
pr text error
*/
.pr-text-error {
  margin-left: 60px;
}

/*
Address editable
*/
.pr-title-disable {
  font-size: 24px;
  color: #262626;
  font-weight: 100;
  display: inline-block;
  max-width: 50%;
  float: left;
  word-break: break-all;
}

.address-text-format {
  white-space: pre-wrap;
}

.pr-needed-by-input {
  cursor: pointer;
  text-align: left;
}

.pr-needed-by-pack {
  display: flex;
}

.pr-needed-by-dates-button {
  margin-left: auto;
}

.top-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 20px;
}

.scrollbar {
  height: 20px;
}

.approval-flow {
  touch-action: none;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  overflow: auto;
  min-height: 85vh;
  /*border: 1px solid $navy;*/
}

.approval-flow > .node {
  /*  border: 1px solid #ffffff;
    box-shadow: 2px 2px 19px #aaa;
    -o-box-shadow: 2px 2px 19px #aaa;
    -webkit-box-shadow: 2px 2px 19px #aaa;
    -moz-box-shadow: 2px 2px 19px #aaa;
    -moz-border-radius: 0.5em;
    border-radius: 0.5em;
  opacity: 0.8;
  filter: alpha(opacity=80);
  cursor: pointer;
  z-index: 20;*/
  position: absolute;
  background-color: #ffffff;
  color: black;
  font-family: helvetica;
  padding: 0.5em;
  max-height: 90%;
  min-height: 80%;
}

.approval-flow > .node > .node-sub {
  border: 1px solid #ffffff;
  box-shadow: 2px 2px 19px #aaa;
  position: relative;
  -o-box-shadow: 2px 2px 19px #aaa;
  -webkit-box-shadow: 2px 2px 19px #aaa;
  -moz-box-shadow: 2px 2px 19px #aaa;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
  opacity: 0.8;
  filter: alpha(opacity=80);
  font-size: 14px;
  cursor: pointer;
  z-index: 20;
  color: black;
  font-family: helvetica;
  padding: 0.5em;
  -webkit-transition: -webkit-box-shadow 0.15s ease-in;
  -moz-transition: -moz-box-shadow 0.15s ease-in;
  -o-transition: -o-box-shadow 0.15s ease-in;
  transition: box-shadow 0.15s ease-in;
}

.approval-flow > .node > .node-sub.add-adhoc-step {
  opacity: 0.3;
}

.approval-flow > .node > .node-sub .delete-adhoc {
  color: #ed5565;
  float: right;
  margin-top: -23px;
  margin-right: -13px;
  font-size: 25px;
}

.approval-flow > .node > .node-sub > .node-child {
  padding: 5px;
  font-size: 14px;
  min-width: 150px;
  border-width: 2px;
  border-color: #ffffff;
  border-style: solid;
}

.approval-flow > .node > .node-sub > .node-child:last-child {
  margin-bottom: 0;
}

.approval-flow > .node > .node-sub > .node-child > i.fa {
  display: none;
}

.approval-flow .node-child i.fa.skipped {
  color: #449d44;
  display: inline-block !important;
}

.approval-flow .node-child i.fa.approved {
  color: #1ab394;
  display: inline-block !important;
}

.approval-flow .node-child i.fa.rejected {
  color: #ed5565;
  display: inline-block !important;
}

.approval-flow .node-child i.fa.on_hold {
  color: #f46435;
  display: inline-block !important;
}

.approval-flow .node-child .i.fa.escalated {
  color: #615f5f;
  display: inline-block !important;
}

.approval-flow .node-child .user {
  margin-left: -5px;
}

.approval-flow .node-child .logs {
  margin-left: 5px;
}

.approval-flow .node-child .grey-text {
  color: #979797;
}

.approval-flow > .node > .node-sub > .node-child > span > i.fa.approved.history {
  color: #1ab394;
  opacity: 0.7;
}

.approval-flow > .node > .node-sub > .node-child > span > i.fa.rejected.history {
  color: #ed5565;
  opacity: 0.7;
}

.approval-history {
  display: block !important;
}

.approval-position {
  position: relative;
}

#node_newApprover {
  opacity: 0.4;
}

.user-approved {
  color: #878787;
}

ul.list-approval-user {
  list-style: none;
  padding: 0;
}

ul.list-approval-user li {
  list-style: none;
  padding: 0;
  margin: 5px 0;
}

.title-role {
  text-align: center;
  border-bottom: 1px solid rgba(150, 138, 138, 0.38);
  margin-bottom: 8px;
  padding-bottom: 5px;
}

._jsPlumb_overlay {
  font-weight: bold !important;
  color: white !important;
  background: rgba(98, 98, 98, 0.85) !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  cursor: pointer;
}

._jsPlumb_endpoint {
  height: 1px !important;
  width: 1px !important;
}

._jsPlumb_endpoint svg {
  height: 1px !important;
  width: 1px !important;
}

/*
 Waiting on
*/
.highlight-item {
  background-color: #ffffcc;
}

.disable-row {
  background-color: #eeeeee;
  pointer-events: none !important;
}

.erp-status-message {
  position: relative;
  bottom: -2px;
  font-weight: bold;
}

.erp-status-success {
  color: #1ab394;
}

.erp-status-pending {
  color: #f8ac59;
}

.erp-status-fail {
  color: #ed5565;
}

.waiting-on-section {
  margin-top: -10px;
}

.waiting-on-section .waiting-on-title {
  font-weight: 100;
  color: #aaa;
  position: relative;
  bottom: -2px;
  margin-left: 85px;
}

.waiting-on-section .dateshift {
  position: relative;
  bottom: -2px;
}

.waiting-on-section.waiting-on-access-directly {
  margin-top: 0px;
}

.waiting-on-section.waiting-on-access-directly .waiting-on-title {
  margin-left: 0px;
}

/*
* po waiting on section
*/
.po-waiting-on-section {
  margin-top: -10px;
  float: left;
  clear: both;
  margin-left: 86px;
}

.po-waiting-on-section .waiting-on-section-disable {
  color: #676A6C;
}

.po-waiting-on-section .waiting-on-title {
  font-weight: 100;
  color: #676A6C;
  position: relative;
  bottom: -2px;
}

.po-waiting-on-section .waiting-on-icon {
  margin-left: 10px;
}

.po-waiting-on-section .dropdown.dropdown-scroll .dropdown-menu {
  max-height: 350px;
  overflow: auto;
}

.po-waiting-on-section .waiting-on-search-title {
  margin-left: 10px;
  margin-top: 10px;
}

.po-waiting-on-section .waiting-on-search-input {
  padding: 5px 5px;
}

.po-waiting-on-section .waiting-on-group-title {
  margin-left: 10px;
  margin-top: 5px;
}

.po-waiting-on-section .waiting-on-item-icon {
  position: absolute;
  margin-top: 5px;
  left: 3px;
}

.po-waiting-on-section.po-waiting-on-access-directly {
  margin-top: 0px;
  margin-left: 0px;
}

/*
progress bar color
*/
.progress-item .progress {
  margin: 0;
}

.progress-item .progress-bar-navy {
  background-color: #1ab394;
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

.text-valign-middle {
  vertical-align: middle !important;
}

.noti-profile-avatar {
  max-width: 100%;
  width: 65px;
}

.state-user-profile thead > tr, .state-tenant-settings thead > tr {
  background-color: #e5e5e5;
}

.state-user-profile .profile-avatar, .state-tenant-settings .profile-avatar {
  max-width: 100%;
}

.state-user-profile .form-group, .state-tenant-settings .form-group {
  margin-bottom: 5px;
}

.state-user-profile label, .state-tenant-settings label {
  color: #676A6C;
}

.state-user-profile ul.unstyled, .state-tenant-settings ul.unstyled {
  margin: 0;
  padding: 0;
}

.state-user-profile ul.unstyled li, .state-tenant-settings ul.unstyled li {
  margin-bottom: 5px;
}

.state-user-profile .company-description, .state-tenant-settings .company-description {
  color: #aaaaaa;
}

/*
*action-pr
*/
.action-pr .action-pr-title {
  text-align: left;
}

.action-pr .action-pr-body {
  width: 100%;
  position: relative;
}

.action-pr .action-pr-top-arrow {
  position: absolute;
  z-index: 100;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  top: 0;
  left: -11px;
}

.action-pr .action-pr-bottom-arrow {
  position: absolute;
  z-index: 101;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  top: 0;
  left: -11px;
}

.action-pr .action-pr-text {
  width: 100%;
  margin-left: 10px;
}

.action-pr .empty-reason {
  border: 1px solid red;
}

.action-pr .action-pr-img {
  border-radius: 50%;
  width: 42px;
}

.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #f3f3f4;
}

/*
* po action menu
*/
.po-action {
  margin-top: -10px;
}

.po-action .po-action-icon {
  margin-right: 8px;
}

.po-action .po-action-icon-del {
  color: red;
}

.po-action .po-action-receive-good-section {
  float: left;
  clear: both;
  margin-bottom: -20px;
}

.po-action .po-action-receive-good-progress {
  margin-left: 40px;
}

.po-action .po-action-receive-good-progress .progress-bar {
  background-color: #1ab394;
}

.po-action .po-action-receive-good-progress_zero .progress-bar-danger {
  color: #707E71;
}

.po-action .clear-more-padding-right {
  padding-right: 0;
}

.po-action.po-action-access-directly {
  margin-top: 0px;
}

/*
*resend PO
*/
.resend-po .resend-po-title {
  text-align: left;
}

.resend-po .resend-po-body {
  width: 100%;
  position: relative;
}

.resend-po .resend-po-left-button {
  float: left;
}

.resend-po .resend-po-right-button {
  float: right;
}

.resend-po .resend-po-item-text {
  border-bottom: none;
  color: #62a0b2;
}

.resend-po .col-xs-9 {
  padding-left: 0;
}

/*
*cancel PO
*/
.cancel-po .cancel-po-title {
  text-align: left;
}

.cancel-po .cancel-po-body {
  width: 100%;
  position: relative;
}

.cancel-po .cancel-po-right-button {
  float: right;
}

.cancel-po .cancel-po-left-button {
  float: left;
}

.cancel-po .cancel-po-item-text {
  border-bottom: none;
}

.cancel-po .cancel-po-texterea {
  width: 100%;
  margin-top: 10px;
}

/*
*close PO
*/
.close-po .close-po-title {
  text-align: left;
}

.close-po .close-po-note {
  text-align: left;
  margin-left: 20px;
}

.close-po .close-po-item-text {
  margin-left: 20px;
}

.close-po .close-po-right-button {
  float: right;
}

.close-po .close-po-container {
  margin-top: 30px;
}

.close-po .close-po-check-text {
  margin-left: 10px;
}

.close-po .close-po-line-item {
  text-align: center;
}

/*
*comment-in-close-po
*/
.comment-in-close-po .comment-in-close-po-title {
  text-align: left;
}

.comment-in-close-po .comment-in-close-po-note {
  text-align: left;
  margin-left: 20px;
}

.comment-in-close-po .comment-in-close-po-body {
  width: 100%;
  position: relative;
}

.comment-in-close-po .comment-in-close-po-top-arrow {
  position: absolute;
  z-index: 100;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #000;
  top: 0;
  left: -11px;
}

.comment-in-close-po .comment-in-close-po-bottom-arrow {
  position: absolute;
  z-index: 101;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #fff;
  top: 0;
  left: -11px;
}

.comment-in-close-po .comment-in-close-po-text {
  width: 100%;
  margin-left: 10px;
}

.comment-in-close-po .comment-in-close-po-left-button {
  float: left;
}

.comment-in-close-po .comment-in-close-po-right-button {
  float: right;
}

.comment-in-close-po .comment-in-close-po-img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

/*
*comment-in-reopen-po
*/
.comment-in-reopen-po .comment-in-reopen-po-title {
  text-align: left;
}

.comment-in-reopen-po .comment-in-reopen-po-note {
  text-align: left;
  margin-left: 20px;
}

.comment-in-reopen-po .comment-in-reopen-po-body {
  width: 100%;
  position: relative;
}

.comment-in-reopen-po .comment-in-reopen-po-top-arrow {
  position: absolute;
  z-index: 100;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #000;
  top: 0;
  left: -11px;
}

.comment-in-reopen-po .comment-in-reopen-po-bottom-arrow {
  position: absolute;
  z-index: 101;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #fff;
  top: 0;
  left: -11px;
}

.comment-in-reopen-po .comment-in-reopen-po-text {
  width: 100%;
  margin-left: 10px;
}

.comment-in-reopen-po .comment-in-reopen-po-left-button {
  float: left;
}

.comment-in-reopen-po .comment-in-reopen-po-right-button {
  float: right;
}

.comment-in-reopen-po .comment-in-reopen-po-img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

/*
*reverse goods
*/
.reverse-goods .reverse-goods-title {
  text-align: left;
}

.reverse-goods .reverse-goods-input {
  width: 100%;
}

.reverse-goods .reverse-goods-right-button {
  float: right;
}

/*
*comments-in-reverse-goods
*/
.comments-in-reverse-goods .comments-in-reverse-goods-title {
  text-align: left;
}

.comments-in-reverse-goods .comments-in-reverse-goods-note {
  text-align: left;
  margin-left: 20px;
}

.comments-in-reverse-goods .comments-in-reverse-goods-body {
  width: 100%;
  position: relative;
}

.comments-in-reverse-goods .comments-in-reverse-goods-top-arrow {
  position: absolute;
  z-index: 100;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #000;
  top: 0;
  left: -11px;
}

.comments-in-reverse-goods .comments-in-reverse-goods-bottom-arrow {
  position: absolute;
  z-index: 101;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #fff;
  top: 0;
  left: -11px;
}

.comments-in-reverse-goods .comments-in-reverse-goods-text {
  width: 100%;
  margin-left: 10px;
}

.comments-in-reverse-goods .comments-in-reverse-goods-left-button {
  float: left;
}

.comments-in-reverse-goods .comments-in-reverse-goods-right-button {
  float: right;
}

.comments-in-reverse-goods .comments-in-reverse-goods-img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

/*
*receive goods
*/
.receive-goods .receive-goods-title {
  text-align: left;
}

.receive-goods .receive-goods-input {
  width: 100%;
}

.receive-goods .receive-goods-right-button {
  float: right;
}

.receive-goods .receive-goods-container {
  margin-top: 30px;
}

.receive-goods .receive-goods-check-text {
  margin-left: 10px;
}

.receive-goods .received-date {
  cursor: pointer;
  text-align: center;
  width: 100%;
}

.highlight-error-field {
  box-shadow: #f00 0 0 1em;
}

/*
*comments-in-receive-goods
*/
.comments-in-receive-goods .comments-in-receive-goods-title {
  text-align: left;
}

.comments-in-receive-goods .comments-in-receive-goods-note {
  text-align: left;
  margin-left: 20px;
}

.comments-in-receive-goods .comments-in-receive-goods-body {
  width: 100%;
  position: relative;
}

.comments-in-receive-goods .comments-in-receive-goods-top-arrow {
  position: absolute;
  z-index: 100;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #000;
  top: 0;
  left: -11px;
}

.comments-in-receive-goods .comments-in-receive-goods-bottom-arrow {
  position: absolute;
  z-index: 101;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #fff;
  top: 0;
  left: -11px;
}

.comments-in-receive-goods .comments-in-receive-goods-text {
  width: 100%;
  margin-left: 10px;
}

.comments-in-receive-goods .comments-in-receive-goods-left-button {
  float: left;
}

.comments-in-receive-goods .comments-in-receive-goods-right-button {
  float: right;
}

.comments-in-receive-goods .comments-in-receive-goods-img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

.comments-in-receive-goods .comments-in-receive-goods-attachment {
  border: none;
  background-color: initial;
  padding: initial;
  min-height: 5px;
}

.po-id {
  font-size: 24px;
  margin-left: 20px;
  color: #262626;
  font-weight: 100;
}

/*
* waiting on for you
*/
.state-waiting-for-you thead > tr {
  background-color: #e5e5e5;
}

.state-waiting-for-you tr {
  cursor: pointer;
}

.first-uppercase:first-letter {
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

/*
*po attachment
*/
.po-attachment .po-attachment-file-title {
  color: #84b5c3;
  font-style: normal;
  border-bottom: dashed 1px #428bca !important;
}

.po-attachment .po-attachment-headding {
  text-align: left;
}

.po-attachment .po-attachment-dropzone {
  min-height: 220px;
}

.po-attachment .po-attachment-file-size {
  text-align: center;
  margin-right: 30px;
}

.po-attachment .po-attachment-delete {
  position: relative;
  top: -20px;
}

.po-attachment .po-attachment-name {
  word-wrap: break-word;
}

.po-attachment .attachment-uploader {
  text-align: center;
  position: relative;
  font-weight: bold;
  color: #84b5c3;
  font-size: 14px;
}

/*
*address book
*/
.address-book .address-book-title {
  text-align: center;
}

.address-book .address-book-filter {
  border-top: 0;
}

.address-book .address-book-filter > input {
  width: 100%;
}

.address-book .table > tbody > tr {
  cursor: pointer;
}

.address-book .address-book-remove-filter {
  margin-top: -22px;
  margin-right: 3px;
}

.address-book thead > tr:first-child {
  background-color: #e5e5e5;
}

/*
* item action scroll fix
*/
.item-action-scrollfix {
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0px;
  left: 0;
  height: 61.5px;
  padding: 0 10px;
  color: white;
  background-color: #1ab394;
}

.item-action-scrollfix .vertical-line {
  font-size: 33px;
}

.item-action-scrollfix .large-size {
  font-size: 30px;
}

.item-action-scrollfix .multi-item-margin {
  margin-top: 6px;
}

.item-action-scrollfix .dropdown-menu {
  color: black;
}

.item-action-scrollfix .btn {
  border-radius: 7px;
  margin-bottom: 6px;
  color: white;
}

.item-action-scrollfix .selected_item-text {
  margin-left: 10px;
  font-size: 18px;
  line-height: 1.33333;
}

.item-action-scrollfix .cancel-selected-item {
  position: relative;
  top: 5px;
  right: 5px;
}

.item-action-scrollfix .close-multiselect {
  padding: 0 10px;
  margin-top: 6px;
}

.pr-attachment-visible-checkbox {
  margin-top: 5px;
  display: inline-block;
  width: 100%;
}

/*
* print section
*/
@media screen {
  .print-section {
    display: none;
    visibility: hidden;
  }
  .all-section {
    display: block;
    visibility: visible;
  }
}

@media print {
  header * {
    visibility: hidden;
  }
  footer * {
    visibility: hidden;
  }
  body * {
    visibility: hidden;
  }
  body {
    margin-left: -58mm;
    width: 130%;
    max-width: 150%;
    overflow-x: visible;
    overflow-y: visible;
  }
  body.mini-navbar {
    margin-left: -18mm;
    width: 108%;
    max-width: 120%;
    overflow-x: visible;
    overflow-y: visible;
  }
  #page-wrapper {
    min-height: 0 !important;
  }
  .screen-section {
    display: none;
    visibility: hidden;
  }
  .print-section, .print-section * {
    visibility: visible;
  }
  .state_requisitions_details .jumbotron {
    padding: 1em;
    margin-bottom: 0;
  }
  .state_requisitions_details > .row {
    opacity: 1;
    max-height: 90000px;
    clear: both;
    margin: 0;
  }
  .state_requisitions_details > .row.ng-hide {
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
  }
  .state_requisitions_details label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: -3px;
  }
  .state_requisitions_details h3 {
    font-size: 1.5em;
    margin: 0 0 1em;
    text-align: right;
  }
  .print-section {
    position: relative;
    left: 0;
    top: 0;
  }
  .pr-title-disable {
    font-size: 5mm;
    color: #aaa;
    font-weight: 100;
  }
  .pr-id {
    font-size: 5mm;
    margin-left: 5mm;
    color: #262626;
    font-weight: 100;
  }
  .itemlist {
    padding: 1mm;
    margin-left: -12mm;
  }
  .itemlist .column-calculations {
    width: 35%;
  }
  .media-body .well {
    overflow-y: hidden;
  }
  .all-section {
    display: block;
    visibility: visible;
  }
}

.printing-page-min-height #page-wrapper {
  min-height: 0 !important;
}

.header-actions-scroll-fix {
  overflow: visible !important;
  margin-bottom: 10px;
}

.row.header-actions-scroll-fix.ui-scrollfix {
  position: fixed;
  top: -14px;
  left: 235px;
  right: 13px;
  padding-top: 1em;
  padding-bottom: 10px;
  z-index: 100;
  background-color: #F4F4F4;
}

.mini-navbar .row.header-actions-scroll-fix.ui-scrollfix {
  position: fixed;
  top: -14px;
  left: 73px;
  right: 3px;
  padding-left: 15px;
  padding-right: 13px;
  padding-top: 1em;
  padding-bottom: 10px;
  z-index: 100;
  background-color: #F4F4F4;
}

.clear-header-action-scroll-fix {
  position: static !important;
}

.margin-title-access-directly {
  margin-left: 0px;
}

/*
*pr attachment
*/
.pr-attachment .pr-attachment-file-title {
  color: #84b5c3;
  font-style: normal;
  border-bottom: dashed 1px #428bca !important;
}

.pr-attachment .pr-attachment-file-title-disable {
  color: #aaa;
  font-style: normal;
}

.pr-attachment .pr-attachment-delete {
  position: relative;
  top: 0px;
}

.pr-attachment .pr-attachment-name {
  word-wrap: break-word;
}

.pr-attachment .attachment-uploader {
  text-align: center;
  position: relative;
  font-weight: bold;
  color: #84b5c3;
  font-size: 14px;
}

.custom-dropdown-multiselect > div > ul {
  overflow: visible !important;
}

/*
*non-catalog
*/
.non-catalog .non-catalog-item-name {
  text-align: center;
}

.non-catalog .non-item-search-supplier {
  padding-left: 15px;
  padding-right: 15px;
}

.non-catalog h4 {
  font-weight: normal;
  line-height: 2.2;
  text-align: right;
}

.non-catalog .supplier-status-tag {
  margin-left: 20px;
  margin-top: 5px;
}

.non-catalog-item-creation .form-control {
  border-radius: 5px;
  border-color: #e5e6e7;
}

.non-catalog-item-creation .input-field-block {
  height: 56px;
}

/*
* filter share with you tab
*/
.filter-share-with-you {
  margin-top: 1em;
  margin-bottom: 1em;
}

.filter-share-with-you .btn-default {
  background-color: inherit;
  color: inherit;
}

/*
*comment-in-withdraw
*/
.comment-in-withdraw .comment-in-withdraw-title {
  text-align: left;
}

.comment-in-withdraw .comment-in-withdraw-body {
  width: 100%;
  position: relative;
}

.comment-in-withdraw .comment-in-withdraw-top-arrow {
  position: absolute;
  z-index: 100;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #000;
  top: 0;
  left: -11px;
}

.comment-in-withdraw .comment-in-withdraw-bottom-arrow {
  position: absolute;
  z-index: 101;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid #fff;
  top: 0;
  left: -11px;
}

.comment-in-withdraw .comment-in-withdraw-text {
  width: 100%;
  margin-left: 10px;
}

.comment-in-withdraw .comment-in-withdraw-left-button {
  float: left;
}

.comment-in-withdraw .comment-in-withdraw-right-button {
  float: right;
}

.comment-in-withdraw .comment-in-withdraw-img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

/*new css for details page*/
.control-label {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: right;
}

.supplierList-details {
  margin-top: 7px;
}

.control-label-nospace {
  padding-top: 0;
  margin-bottom: 0;
  text-align: right;
}

.address-box a i {
  font-size: 25px;
  color: #676a6c;
  margin-top: 5px;
}

.editable-click:hover, a.editable-click:hover {
  text-decoration: none;
}

.star-check-cell-catalog {
  text-align: left;
  font-size: 14px;
  color: #d1dade;
}

.fix-height-supplier-catalog {
  height: 20px;
}

@media (min-width: 990px) {
  .col-md-2.ui-scrollfix {
    position: fixed;
    top: 20px;
    margin-right: 16px;
    background-color: white;
  }
  .catalog-item-view {
    float: right;
    width: 83%;
  }
}

.thumbnail-shadow {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857;
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  -webkit-transition: border .2s ease-in-out;
  transition: border .2s ease-in-out;
}

.thumbnail-shadow:hover {
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.button-full {
  width: 100%;
}

a.cartPreview {
  color: #aaaaaa;
  text-decoration: none;
}

a.cartPreview:hover, a.cartPreview:focus {
  color: #919191;
  text-decoration: none;
}

.text-black {
  color: #676a6c;
}

.text-navy-bold {
  color: #1ab394;
  font-weight: bold;
}

.text-navy-bold:hover {
  color: #0E997D;
  font-weight: bold;
}

.text-grey {
  color: #676A6C;
}

.notification-tooltip {
  position: absolute;
  float: none;
}

.notification-tooltip .notificationInner {
  margin: 11px;
}

/*
*
*/
.activePanel {
  border-left: 4px solid #19aa8d;
  background: #293846;
}

.mini-navbar .activePanel .nav-second-level {
  position: absolute;
  left: 65px;
  top: 0;
  background-color: #2f4050;
  padding: 10px 10px 10px 10px;
  font-size: 12px;
}

.shopping-summary .column-image {
  width: 70px;
}

.shopping-summary .column-quantity {
  width: 15%;
}

.shopping-summary .column-price {
  width: 25%;
}

.shopping-summary .column-remove {
  width: 40px;
}

.pagination-notify {
  display: inline-block;
  padding-left: 0;
  margin: 0 0;
  border-radius: 4px;
}

.layer {
  line-height: 1.2em;
  right: 0;
  top: 38px;
  width: 480px;
  min-height: 430px;
  max-height: 40vh;
  z-index: 20;
  color: #444;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  opacity: 1;
  -ms-filter: none;
  filter: none;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid #dcdcdc;
}

#cartPreview {
  overflow: auto;
  height: 430px;
}

.layer-no-item {
  line-height: 1.2em;
  right: 0;
  top: 38px;
  width: 480px;
  height: 0px;
  min-height: 65px;
  max-height: 40vh;
  z-index: 20;
  color: #444;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  opacity: 1;
  -ms-filter: none;
  filter: none;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid #dcdcdc;
}

#cartPreview .quantity .addtocart .counter {
  overflow: auto;
  width: 32px;
  height: 32px;
  top: 25px;
  left: 0;
}

#cartPreview .quantity .addtocart .counter > span {
  width: 32px;
  height: 24px;
  padding: 4px 0;
  font-size: 1.3em;
  color: #444;
  font-weight: 400;
  text-align: center;
  line-height: 1.2em;
}

#cartPreview .quantity .addtocart > .up {
  display: block;
  top: 0;
  left: 0;
  width: 50px;
  height: 25px;
  text-align: center;
}

#cartPreview .quantity .addtocart > .up:active {
  top: 1px;
}

#cartPreview .quantity .addtocart > .down {
  display: block;
  left: 0;
  bottom: 0;
  top: 57px;
  width: 50px;
  height: 25px;
  text-align: center;
}

#cartPreview .quantity .addtocart > .down:active {
  top: 58px;
}

#cartPreview .price {
  top: 60px;
  left: 0;
  font-size: 1.15rem;
  font-weight: normal;
  color: #1ab394;
  margin: 0 0 .3em;
}

.shopping-summary > tbody > tr > td > .table-productList-width {
  width: 68px;
}

.layer h4 {
  margin-top: 10px;
}

.margin-min {
  margin-top: 5px;
  margin-left: 10px;
}

.layer h4 .instacheckout {
  float: right;
  display: inline-block;
  margin-top: -.5em;
  font-size: 14px;
  font-weight: normal;
  padding: 7px 19px;
  width: auto;
}

a.cartPreview:hover, a.cartPreview:focus {
  color: #919191;
  text-decoration: none;
}

.text-total-price {
  font-size: 24px;
  color: #262626;
  font-weight: 100;
}

.quantity-catalog {
  border-right: 1px solid #18a689;
  background-color: #1ab394;
  display: block;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 32px;
  height: 33px;
  border-radius: 2px 0 0 2px;
}

.quantity-control:hover {
  background-color: #169D81;
}

.btn .btn-text {
  display: inline-block;
  vertical-align: middle;
  padding: 8px;
  text-align: center;
  height: 35px;
}

.btn .btn-quantity {
  display: inline-block;
  vertical-align: middle;
  background: #1ab394;
  width: 35px;
  height: 35px;
  padding: 8px;
  font-size: 14px;
}

.btn .btn-icon {
  display: inline-block;
  vertical-align: middle;
  background: #1ab394;
  width: 35px;
  height: 35px;
  padding: 8px;
  font-size: 14px;
  border-right: 1px solid #1D8973;
}

.no-padding {
  padding: 0px 0px;
}

.clear-header-action-scroll-fix {
  position: relative !important;
}

.quantity .addtocart .editable-wrap .editable-controls > input {
  width: 50px;
  margin-bottom: 0;
}

.p-t-5 {
  padding-top: 5px;
}

#notifications:active > .dropdown-menu {
  display: block;
}

.set-position-relative {
  position: relative;
}

/*
*Item details styling
*/
.state_items_details {
  /*
  * item action
  */
}

.state_items_details .back-buttons {
  display: block;
  margin: 0 1em 1em 0;
  vertical-align: super;
  float: left;
}

.state_items_details i.is_starred {
  margin: 0 0 0 1em;
}

.state_items_details > .row {
  -webkit-transition: all linear .5s;
  transition: all linear .5s;
  opacity: 1;
  max-height: 90000px;
  overflow: hidden;
  clear: both;
  margin: 1em 2em;
}

.state_items_details > .row.ng-hide {
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}

.state_items_details .jumbotron {
  background: #E0ECEF;
  font-size: 1.1em;
  padding: 3em 6em;
}

.state_items_details .jumbotron .editable-wrap {
  white-space: normal;
}

.state_items_details .jumbotron .editable-click,
.state_items_details .jumbotron .editable-click:hover,
.state_items_details .jumbotron .editable-click:focus,
.state_items_details .jumbotron a.editable-click,
.state_items_details .jumbotron a.editable-click:hover,
.state_items_details .jumbotron a.editable-click:focus,
.state_items_details .jumbotron .editable-empty,
.state_items_details .jumbotron .editable-empty:hover,
.state_items_details .jumbotron .editable-empty:focus,
.state_items_details .jumbotron a.editable-empty,
.state_items_details .jumbotron a.editable-empty:hover,
.state_items_details .jumbotron a.editable-empty:focus {
  border: 0;
  display: inline-block;
  font-style: normal;
  margin: .3em 0;
}

.state_items_details .jumbotron hr {
  border-top: 1px solid #ccc;
}

.state_items_details h3 {
  font-size: 1.5em;
  margin: 0 0 1.5em;
  text-align: right;
}

.state_items_details label {
  color: #aaa;
  font-weight: normal;
  line-height: 2.2;
  text-align: right;
}

.state_items_details label.color-pagination {
  color: #676a6c;
  font-weight: bold;
  line-height: 2.2;
  text-align: right;
  text-transform: lowercase;
}

.state_items_details .breadcrumb {
  background-color: transparent;
}

.state_items_details .breadcrumb li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.state_items_details .breadcrumb > li {
  display: block;
}

.state_items_details .breadcrumb > li + li {
  padding-left: 15px;
}

.state_items_details .breadcrumb > li + li + li {
  padding-left: 30px;
}

.state_items_details .breadcrumb > li + li + li + li {
  padding-left: 45px;
}

.state_items_details .breadcrumb > li + li + li + li + li {
  padding-left: 60px;
}

.state_items_details .breadcrumb > li a:hover {
  color: #1c84c6;
}

.state_items_details .breadcrumb > li + li::before {
  content: '';
  padding: 0;
  color: inherit;
}

.state_items_details .breadcrumb > li > b + ul {
  list-style: none;
  padding-left: 15px;
}

.state_items_details .editable-empty,
.state_items_details .editable-empty:hover,
.state_items_details .editable-empty:focus,
.state_items_details a.editable-empty,
.state_items_details a.editable-empty:hover,
.state_items_details a.editable-empty:focus {
  color: #ccc;
}

.state_items_details .all-categories {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.state_items_details .all-categories .panel {
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}

.state_items_details .all-categories .panel-default > .panel-heading {
  background-color: #f5f5f5;
}

.state_items_details .all-categories h2 {
  margin: 0;
  font-size: 1.2em;
  line-height: 1.2;
}

.state_items_details .line-item-list {
  width: 100%;
  min-height: 0;
  max-height: 100px;
  overflow-y: scroll;
  background: white;
  list-style-type: none;
  border: 1px solid grey;
}

.state_items_details .line-item-list li {
  text-align: center;
}

.state_items_details .item-action {
  right: 10px;
}

.state_items_details .item-action .item-action-icon {
  margin-right: 8px;
}

.state_items_details .item-action .item-action-icon-del {
  color: red;
}

/*
* po waiting on section
*/
.item-waiting-on-section {
  float: left;
  clear: right;
}

.item-waiting-on-section .waiting-on-section-disable {
  color: #aaa;
}

.item-waiting-on-section .waiting-on-title {
  font-weight: 100;
  color: #aaa;
  position: relative;
  bottom: -2px;
}

.item-waiting-on-section .waiting-on-icon {
  margin-left: 10px;
}

.item-waiting-on-section .dropdown.dropdown-scroll .dropdown-menu {
  max-height: 350px;
  overflow: auto;
}

.item-waiting-on-section .waiting-on-search-title {
  margin-left: 10px;
  margin-top: 10px;
}

.item-waiting-on-section .waiting-on-search-input {
  padding: 5px 5px;
}

.item-waiting-on-section .waiting-on-group-title {
  margin-left: 10px;
  margin-top: 5px;
}

.item-waiting-on-section .waiting-on-item-icon {
  position: absolute;
  margin-top: 5px;
  left: 3px;
}

.clear-floating-right {
  clear: right;
}

.clear-floating-left {
  clear: left;
}

.state_requisitions_details > .row.full-header-catalog.ui-scrollfix {
  opacity: 1;
  max-height: 90000px;
  clear: both;
  margin: 1em 1em;
}

.state_requisitions_details > .row {
  opacity: 1;
  max-height: 90000px;
  clear: both;
  margin: 1em 2em;
  position: relative;
}

.state_orders_details > .row {
  opacity: 1;
  max-height: 90000px;
  clear: both;
  margin: 1em 2em;
  position: relative;
}

.shopping-summary tr.background-white:hover {
  background-color: #ffffff;
}

span.shopping-cart.open > a.arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

span.shopping-cart.open > a.arrow:after {
  clear: both;
  display: block;
  width: 0;
  margin: 0 auto;
  margin-top: 5px;
  z-index: 99;
  position: relative;
}

span.shopping-cart.open .arrow {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: rgba(213, 61, 61, 0);
  border-style: solid;
  border-width: 10px;
  top: 30px;
  right: 103px;
  z-index: 99;
  border-top-width: 0;
  border-bottom-color: #999;
  /*border-bottom-color: rgba(0, 0, 0, 0.2);*/
  border-bottom-color: #dadada;
}

span.shopping-cart.open .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: rgba(213, 61, 61, 0);
  border-style: solid;
  border-width: 10px;
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  /*border-bottom-color: #FFF;*/
}

div.notification .arrow {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: rgba(213, 61, 61, 0);
  border-style: solid;
  border-width: 10px;
  top: 30px;
  right: 103px;
  z-index: 99;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

div.notification .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: rgba(213, 61, 61, 0);
  border-style: solid;
  border-width: 10px;
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 1px;
  border-bottom-color: #FFF;
}

.editable-click, td.quantity > a.editable-click {
  text-decoration: none;
  color: #1ab394;
  border-bottom: none;
}

#notifications {
  position: absolute;
  right: -25px;
  top: 0;
  width: 270px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10000;
}

.notification.attachedTop:before {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  top: 3px;
  left: 128px;
  border-bottom: 8px solid rgba(50, 50, 50, 0.95);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  -webkit-animation: fadeIn .1s ease-out;
  -moz-animation: fadeIn .1s ease-out;
  -o-animation: fadeIn .1s ease-out;
  -ms-animation: fadeIn .1s ease-out;
  animation: fadeIn .1s ease-out;
}

.notification-tooltip {
  position: absolute;
  float: none;
}

.notification.deliveryMeter.attachedTop:before {
  content: none;
}

.notification.deliveryMeter .arrow {
  clear: both;
  display: block;
  position: absolute;
  top: -8px;
  right: 110px;
  border-bottom: 8px solid #dcdcdc;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.notification.deliveryMeter .notificationInner {
  width: 270px !important;
  background: #fff !important;
  font-size: 15px !important;
  color: #444 !important;
  font-weight: 400;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3) !important;
  border-top: 1px solid #dcdcdc;
}

.notification.deliveryMeter .notificationInner.withIcon .message {
  padding: 15px;
  margin: 0;
}

.notification.deliveryMeter .notificationInner.withIcon .itemsInCart {
  padding: 10px 15px 0;
}

.notification.deliveryMeter .notificationInner.withIcon .itemsInCart .visual {
  font-size: 25px;
  line-height: 35px;
  position: relative;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  float: left;
}

.notification .notificationInner.withIcon .visual.qty {
  color: #fff;
  font-size: 25px;
  font-weight: normal;
  text-align: center;
  line-height: 37px;
  top: 13px;
  left: 13px;
  width: 38px;
  height: 38px;
  -webkit-border-radius: 19px;
  border-radius: 19px;
  background: #1ab394;
}

.notification.deliveryMeter .notificationInner.withIcon .itemsInCart .carttext {
  display: inline-block;
  margin: 5px 0 0 15px;
  line-height: 18px;
}

.notification.deliveryMeter .notificationInner.withIcon .itemsInCart .carttext .label {
  color: #fff;
  background: #91b600;
  padding: 3px 9px;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  right: 15px;
  top: 25px;
}

.notification.deliveryMeter .notificationInner.withIcon .itemsInCart .carttext .label.removed {
  background: #1ab394;
}

.notification.deliveryMeter .notificationInner.withIcon .itemsInCart .carttext a {
  display: inline-block;
  color: #1ab394;
  text-decoration: none;
}

.notification.deliveryMeter .notificationInner .deliveryMeterInner {
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.notification.deliveryMeter .notificationInner .deliveryMeterInner .text {
  display: block;
}

.notification.deliveryMeter .notificationInner.withIcon .itemsInCart {
  padding: 10px 15px 10px 15px;
}

.notification.deliveryMeter .notificationInner .deliveryMeterInner .bar {
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -o-transition: width 1s ease;
  -ms-transition: width 1s ease;
  transition: width 1s ease;
  position: relative;
  display: inline-block;
  width: 330px;
  height: 18px;
  line-height: 10px;
  background: #eee;
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  vertical-align: middle;
  margin: 8px 8px 0 0;
  padding: 0 6px 0 4px;
}

.notification.deliveryMeter .notificationInner .deliveryMeterInner .bar:after {
  position: absolute;
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  top: -5px;
  right: -24px;
  background-color: #eee;
}

.notification.deliveryMeter .notificationInner .deliveryMeterInner .bar .progress {
  width: 0;
  background: #91b600;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  height: 8px;
  margin: 5px 0;
}

.notification.deliveryMeter .notificationInner .deliveryMeterInner .bar .free {
  position: absolute;
  top: -25px;
  right: -30px;
  background: #1ab394;
  padding: 5px 7px;
  font-size: .73em;
  font-weight: 700;
  z-index: 10;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
}

.notification.deliveryMeter .notificationInner .deliveryMeterInner .bar .free:before {
  content: "";
  clear: both;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 17px;
  border-top: 5px solid #1ab394;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.set-notification-shopping {
  top: 25px;
  left: auto;
  display: block;
}

.notification-tooltip {
  position: relative;
  float: none;
}

.state_requisitions_details > .row.set-position-relative.ui-scrollfix > #notifications {
  position: fixed;
  right: 25px;
  top: -30px;
  width: 270px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10000;
}

.state_requisitions_details > .row.full-header-catalog.ui-scrollfix > #notifications > .set-notification-shopping {
  top: 25px;
  right: 25px;
  left: auto;
  display: block;
}

.thumbnail .popover {
  top: -134px;
  left: 554px;
  display: block;
  width: 270px;
  height: 100px;
}

.noscroll {
  overflow: hidden;
}

.layer > .header-myCart {
  position: absolute;
  left: 15px;
  right: 15px;
}

.mini-navbar .row.header-actions-scroll-fix.ui-scrollfix > span.shopping-cart > div.layer {
  top: 50px;
}

.layer > .header-myCart.ui-scrollfix > .header-myCart-fix,
.layer > .header-myCart > .header-myCart-fix {
  position: absolute;
  height: 60px;
  width: 480px;
  left: -15px;
  padding: 10px 15px 10px 15px;
  background-color: #f4f4f4;
}

.mini-navbar .row.header-actions-scroll-fix.ui-scrollfix > span.shopping-cart.open .arrow {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: rgba(213, 61, 61, 0);
  border-style: solid;
  border-width: 10px;
  top: 42px;
  right: 103px;
  z-index: 99;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.mini-navbar .row.header-actions-scroll-fix.ui-scrollfix > span.shopping-cart.open .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: rgba(213, 61, 61, 0);
  border-style: solid;
  border-width: 10px;
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #FFF;
}

@media (max-width: 920px) {
  .mini-navbar .row.header-actions-scroll-fix.ui-scrollfix > span.shopping-cart > div.layer {
    top: 85px;
  }
  .mini-navbar .row.header-actions-scroll-fix.ui-scrollfix > span.shopping-cart.open .arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: rgba(213, 61, 61, 0);
    border-style: solid;
    border-width: 10px;
    top: 77px;
    right: 103px;
    z-index: 99;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .mini-navbar .row.header-actions-scroll-fix.ui-scrollfix > span.shopping-cart.open .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: rgba(213, 61, 61, 0);
    border-style: solid;
    border-width: 10px;
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #FFF;
  }
  .mini-navbar .row.header-actions-scroll-fix > span.shopping-cart > div.layer {
    top: 72px;
  }
  span.shopping-cart.open .arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: rgba(213, 61, 61, 0);
    border-style: solid;
    border-width: 10px;
    top: 64px;
    right: 103px;
    z-index: 99;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  span.shopping-cart.open .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: rgba(213, 61, 61, 0);
    border-style: solid;
    border-width: 10px;
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #FFF;
  }
  .set-notification-shopping {
    top: 60px;
    left: auto;
    display: block;
  }
}

.state_requisitions_details > .row.set-position-relative.ui-scrollfix > span.shopping-cart.open > #layer {
  top: 7px;
  position: fixed;
  right: 40px;
}

.state_requisitions_details > .row.set-position-relative.ui-scrollfix > span.shopping-cart.open .arrow {
  position: fixed;
  display: block;
  width: 0;
  height: 0;
  border-color: rgba(213, 61, 61, 0);
  border-style: solid;
  border-width: 10px;
  top: 0px;
  right: 136px;
  z-index: 99;
  border-top-width: 0;
  border-bottom-color: #999;
  /* border-bottom-color: rgba(0, 0, 0, 0.2);*/
  border-bottom-color: #dadada;
}

.state_requisitions_details > .row.set-position-relative.ui-scrollfix > span.shopping-cart.open .arrow:after {
  position: fixed;
  display: block;
  width: 0;
  height: 0;
  border-color: rgba(213, 61, 61, 0);
  border-style: solid;
  border-width: 10px;
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  /*border-bottom-color: #FFF;*/
}

.set-padding-input-quantity {
  padding: 0px;
}

.ecommerce .tag-list {
  padding: 0;
}

.ecommerce .fa-star {
  color: #d1dade;
}

.ecommerce .fa-star.active {
  color: #f8ac59;
}

.ecommerce .note-editor {
  border: 1px solid #e7eaec;
}

.wrapper-content {
  padding: 20px 10px 20px;
}

.non-catalog > div.modal-body {
  min-height: 543px;
  padding: 0px;
}

.note-editor {
  height: auto;
  min-height: 150px;
}

.note-editor .note-editable {
  padding: 15px;
  overflow: auto;
  outline: 0;
}

.non-catalog label {
  color: #676a6c;
  font-weight: bold;
  line-height: 2.2;
  text-align: right;
  text-transform: none;
}

/* Tabs */
.tabs-container .panel-body {
  background: #fff;
  border: 1px solid #e7eaec;
  border-radius: 2px;
  padding: 20px;
  position: relative;
}

.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
  border: 1px solid #e7eaec;
  border-bottom-color: transparent;
  background-color: #fff;
}

.tabs-container .nav-tabs > li.active > a.details-tab,
.tabs-container .nav-tabs > li.active > a.details-tab:hover,
.tabs-container .nav-tabs > li.active > a.details-tab:focus {
  border: 1px solid #e7eaec;
  border-bottom-color: transparent;
  background-color: #329D9C;
}

.tabs-container .nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.tabs-container .tab-pane .panel-body {
  border-top: none;
}

.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
  border: 1px solid #e7eaec;
  border-bottom-color: transparent;
}

.tabs-container .nav-tabs {
  border-bottom: 1px solid #e7eaec;
}

.tabs-container .tab-pane .panel-body {
  border-top: none;
}

.tabs-container .tabs-left .tab-pane .panel-body,
.tabs-container .tabs-right .tab-pane .panel-body {
  border-top: 1px solid #e7eaec;
}

.tabs-container .nav-tabs > li a:hover {
  background: transparent;
  border-color: transparent;
}

.tabs-container .tabs-below > .nav-tabs,
.tabs-container .tabs-right > .nav-tabs,
.tabs-container .tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tabs-container .tabs-left .panel-body {
  position: static;
}

.tabs-container .tabs-left > .nav-tabs,
.tabs-container .tabs-right > .nav-tabs {
  width: 20%;
}

.tabs-container .tabs-left .panel-body {
  width: 80%;
  margin-left: 20%;
}

.tabs-container .tabs-right .panel-body {
  width: 80%;
  margin-right: 20%;
}

.tabs-container .tab-content > .tab-pane,
.tabs-container .pill-content > .pill-pane {
  display: none;
}

.tabs-container .tab-content > .active,
.tabs-container .pill-content > .active {
  display: block;
}

.tabs-container .tabs-below > .nav-tabs {
  border-top: 1px solid #e7eaec;
}

.tabs-container .tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-container .tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabs-container .tabs-below > .nav-tabs > li > a:hover,
.tabs-container .tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #e7eaec;
  border-bottom-color: transparent;
}

.tabs-container .tabs-left > .nav-tabs > li,
.tabs-container .tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-container .tabs-left > .nav-tabs > li > a,
.tabs-container .tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-container .tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
}

.tabs-container .tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-container .tabs-left > .nav-tabs .active > a,
.tabs-container .tabs-left > .nav-tabs .active > a:hover,
.tabs-container .tabs-left > .nav-tabs .active > a:focus {
  border-color: #e7eaec transparent #e7eaec #e7eaec;
  *border-right-color: #ffffff;
}

.tabs-container .tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
}

.tabs-container .tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.tabs-container .tabs-right > .nav-tabs .active > a,
.tabs-container .tabs-right > .nav-tabs .active > a:hover,
.tabs-container .tabs-right > .nav-tabs .active > a:focus {
  border-color: #e7eaec #e7eaec #e7eaec transparent;
  *border-left-color: #ffffff;
  z-index: 1;
}

.tag-list li a {
  display: inline-block;
}

tags-input .tags .tag-item {
  margin: 2px;
  padding: 0 5px;
  display: inline-block;
  float: left;
  font: 14px "Helvetica Neue",Helvetica,Arial,sans-serif;
  height: 26px;
  line-height: 25px;
  border: 1px solid #acacac;
  border-radius: 3px;
  background: -webkit-linear-gradient(top, #ffffff 0, #ffffff 47%, #ffffff 100%);
  background: linear-gradient(to bottom, #ffffff 0, #ffffff 47%, #ffffff 100%);
}

tags-input .tags {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  padding: 1px;
  overflow: hidden;
  word-wrap: break-word;
  cursor: text;
  background-color: #fff;
  border: 1px solid rgba(169, 169, 169, 0.41);
  box-shadow: 1px 1px 1px 0 rgba(211, 211, 211, 0) inset;
  height: 100%;
}

.asterisk-required {
  color: red;
  font-size: 15px;
}

/*
Supplier details + xeditable inline input width set to 100%
*/
.state_suppliers_details .update-change {
  text-align: left;
  padding-left: 0;
}

.state_suppliers_details .change-action {
  text-align: right;
  padding-right: 0;
}

.state_suppliers_details .add-contact-person {
  margin-left: 0;
}

.state_suppliers_details .add-new-contact-button {
  border-radius: 50%;
}

.state_suppliers_details .edi-contact-button {
  color: #62a0b2;
  border-bottom: dashed 1px #428bca !important;
}

.state_suppliers_details .edit-address-button {
  color: #62a0b2;
  border-bottom: dashed 1px #428bca !important;
}

.state_suppliers_details .editable-wrap {
  display: inline-block;
  white-space: nowrap;
  margin: 0;
  width: 100%;
}

.state_suppliers_details .editable-wrap .editable-controls, .state_suppliers_details .editable-wrap .editable-error {
  margin-bottom: 0;
  width: 100%;
}

.state_suppliers_details .editable-wrap .editable-controls > select {
  margin-bottom: 0;
}

.state_suppliers_details .editable-wrap .editable-controls > input,
.state_suppliers_details .editable-wrap .editable-controls > textarea {
  margin-bottom: 0;
  width: -moz-calc(100%);
  width: -webkit-calc(100%);
  width: calc(100%);
}

.state_suppliers_details .col-sm-10 {
  width: 110%;
}

/*
*supplier listing style
*/
.state_suppliers_list .nav-tabs {
  margin-bottom: 1em;
}

.state_suppliers_list tr {
  cursor: pointer;
}

.state_suppliers_list tr:hover .icheckbox_minimal-grey {
  background-position: -20px 0 !important;
}

.state_suppliers_list tr:hover .icheckbox_minimal-grey.checked {
  background-position: -40px 0 !important;
}

.state_suppliers_list tr:hover .star-hover-o {
  color: #676a6c;
}

.state_suppliers_list thead > tr {
  background-color: #e5e5e5;
  font-weight: bold;
}

.state_suppliers_list tr.no-data {
  cursor: default;
}

.state_suppliers_list .search-dropdown {
  margin: 10px auto 10px auto;
  max-width: 300px;
}

.state_suppliers_list .search-dropdown-item {
  overflow: hidden;
}

.state_suppliers_list .no-padding {
  padding: 0px;
  margin: 0px;
}

.state_suppliers_list .row-beauty {
  margin-top: 5px;
  margin-bottom: 5px;
}

.state_suppliers_list .fixed-top {
  z-index: 100;
}

.state_suppliers_list .pagination-focus .btn:focus {
  outline-color: white;
}

.fa-pr:before {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  content: "PR";
}

.fa-pc:before {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  content: "PC";
}

.fa-po:before {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  content: "PO";
}

#aprvRJ {
  float: right;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mx-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-15 {
  padding: 15px;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pt-10 {
  padding-top: 5px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-37 {
  padding-left: 37px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-2 {
  margin-left: 2px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-2 {
  margin-right: 2px;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.va-bt {
  vertical-align: bottom;
}

.dropdown-h-100 .dropdown-menu {
  height: 100px;
}

.title-details {
  font-size: 20px;
  color: #676A6C;
  padding-left: 10px;
}

.w200 {
  width: 200px;
}

.red-text {
  color: #ed5565;
}

.green-text {
  color: #00c658;
}

.dark-grey {
  color: #9a9a9a;
}

.background-white {
  background-color: #fff !important;
}

.form-only-text {
  padding-top: 7px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default;
}

.checkbox-font-size {
  font-size: 14px;
}

.checkbox-font-size input {
  margin-left: 0 !important;
}

.color-green {
  color: #4dce7e;
}

.color-red {
  color: #ff6557;
}

.color-grey {
  color: grey;
}

.color-white {
  color: #ffffff !important;
}

.line-item-table th {
  color: white;
  background-color: #39475b;
}

.line-item-table-corner {
  background-color: white !important;
}

.pr-line-item-budget-view {
  color: white;
  background-color: #39475b;
  font-weight: bold;
  padding: 20px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.line-item-table-info-cell {
  color: white;
  font-weight: bold;
  background-color: #39475b;
}

.modal-header {
  min-height: 16.42857px;
  padding: 30px 15px;
  text-align: center;
}

.modal-header .modal-title {
  font-size: 26px;
}

.modal-inside .modal-body {
  background-color: #f8fafb;
}

/*
*supplier attachment
*/
.supplier-attachment {
  margin-top: -8px;
}

.supplier-attachment .supplier-attachment-file-title {
  color: #84b5c3;
  font-style: normal;
  border-bottom: dashed 1px #428bca !important;
}

.supplier-attachment .supplier-attachment-headding {
  text-align: left;
}

.supplier-attachment .supplier-attachment-dropzone {
  min-height: 220px;
}

.supplier-attachment .supplier-attachment-file-size {
  text-align: center;
  margin-right: 30px;
}

.supplier-attachment .supplier-attachment-delete {
  position: relative;
  top: -20px;
}

.supplier-attachment .supplier-attachment-name {
  word-wrap: break-word;
}

.supplier-attachment .supported-extensions {
  color: #f00;
}

.action-modal-attachment .attachment-box, .supplier-attachment .attachment-box {
  border: 1px dashed #84b5c3;
  background-color: #f5f5f5;
  color: #84b5c3;
  box-shadow: none;
  padding: 20px;
  line-height: 16px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

.action-modal-attachment .attachment-files, .supplier-attachment .attachment-files {
  border: 1px solid lightgrey;
  background-color: #fbfbfb;
  padding: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.action-modal-attachment .attachment-files .preview, .supplier-attachment .attachment-files .preview {
  font-size: 5em;
  color: #cacaca;
}

.action-modal-attachment .attachment-files .action, .supplier-attachment .attachment-files .action {
  width: 100%;
  height: 30px;
}

.action-modal-attachment .attachment-files .action .delete-button, .supplier-attachment .attachment-files .action .delete-button {
  margin-top: 7px;
}

.action-modal-attachment .attachment-files .row, .supplier-attachment .attachment-files .row {
  background-color: #fbfbfb;
}

.action-modal-attachment .attachment-files .title, .supplier-attachment .attachment-files .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-modal-attachment .attachment-files .category, .supplier-attachment .attachment-files .category {
  font-size: 16px;
  font-weight: bold;
}

/*
*add contact
*/
.add-contact .add-contact-title {
  text-align: left;
}

.add-contact .highlight-error-input input {
  box-shadow: #f00 0 0 1em !important;
}

.add-contact .highlight-error-input div:first-child {
  box-shadow: #f00 0 0 1em !important;
}

/*
*edit contact
*/
.edit-contact .edit-contact-title {
  text-align: left;
}

.edit-contact .highlight-error-input input {
  box-shadow: #f00 0 0 1em !important;
}

.edit-contact .highlight-error-input div:first-child {
  box-shadow: #f00 0 0 1em !important;
}

/*
*edit address
*/
.edit-address .edit-address-title {
  text-align: left;
}

.p-r-md {
  padding-right: 15px;
}

.editable-wrap .editable-input {
  width: 100%;
  min-width: 50px;
}

.text-left-align {
  text-align: left !important;
}

.catalog-item-expiry-date {
  float: right;
  color: #FB001F;
  border-style: solid;
  border-color: #FB001F;
  border-width: 1px;
  font-size: 12px;
  padding: 3px 8px;
  display: inline;
  text-align: center;
  white-space: nowrap;
  border-radius: .25em;
}

.animate-repeat.ng-move, .animate-repeat.ng-enter {
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
}

.animate-repeat.ng-leave {
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
}

.animate-repeat.ng-leave.ng-leave-active {
  opacity: 0;
  max-height: 0;
}

.animate-repeat.ng-move, .animate-repeat.ng-enter {
  opacity: 0;
  max-height: 0;
}

.animate-repeat.ng-leave, .animate-repeat.ng-move.ng-move-active, .animate-repeat.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 40px;
}

.ibox-content {
  background-color: white;
  color: inherit;
  padding: 15px 20px 20px 15px;
  border-color: #e7eaec;
  -webkit-border-image: none;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0px;
}

.cursor-auto {
  cursor: auto !important;
}

.clicked-link {
  cursor: pointer;
  color: #597ec2;
  font-weight: bold;
}

div.editable-controls.form-group, textarea.editable-input {
  width: 100% !important;
}

.half-width-input {
  min-width: 50px;
  width: 30% !important;
}

.grand-total {
  font-size: 1.2em;
  font-weight: bold;
}

.grand-total.double-underlines {
  display: inline-block;
  border-bottom-style: double;
  height: 40px;
}

div[flow-init] span.thumbnail {
  display: inline-block;
}

.gray-text {
  color: #d1dade;
}

.no-padding-margin-top-bottom, .itemlist .custom-menu, .itemlist .custom-menu td table, .itemlist .custom-menu td table tbody > tr > td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

::-webkit-input-placeholder {
  font-style: italic;
  color: #c9c9c9 !important;
}

:-moz-placeholder {
  font-style: italic;
  color: #c9c9c9 !important;
}

::-moz-placeholder {
  font-style: italic;
  color: #c9c9c9 !important;
}

:-ms-input-placeholder {
  font-style: italic;
  color: #c9c9c9 !important;
}

.state_suppliers_details span.-ui-select-placeholder, .state_suppliers_details div.ui-select-match {
  font-style: italic;
}

.text-muted {
  color: #c9c9c9 !important;
}

.state_suppliers_details .row {
  background-color: white;
}

.state_suppliers_details h2 {
  font-weight: bold;
}

.fa-times {
  color: red;
}

.caption .decimals {
  font-size: 0.7em;
  font-weight: normal;
  vertical-align: baseline;
  top: -0.4em;
  position: relative;
}

.hoverApprovalFlow p {
  z-index: 10000000;
  padding: 0;
  margin: 0;
}

.hoverApprovalFlow .tooltip-content {
  display: none;
}

.approval-flow .node-sub .tooltip.in {
  opacity: 1;
}

.edit-non {
  float: right;
  padding: 0px 10px;
  margin-right: 20px;
  font-size: 18px;
}

.state_user_add .highlight-error-input input {
  box-shadow: #f00 0 0 1em !important;
}

.state_user_add .highlight-error-input div:first-child {
  box-shadow: #f00 0 0 1em !important;
}

/*
* master data
*/
.state-master-data thead > tr {
  background-color: #e5e5e5;
}

.state-master-data tr {
  cursor: pointer;
}

.state-master-data .expense-header {
  margin-top: 5px;
  font-weight: 600;
  font-size: 16px;
  margin-left: 15px;
}

.state-master-data .margin-right {
  margin-right: 15px;
}

.btn-blue {
  background-color: #1c84c6;
  color: white;
  font-weight: bold;
}

.btn-blue:hover {
  color: white;
  font-weight: bold;
  background-color: rgba(28, 132, 198, 0.9);
}

.btn-blue:focus {
  color: white;
  font-weight: bold;
}

.general-listing .highlight-error-input input {
  box-shadow: #f00 0 0 1em !important;
}

.general-listing .highlight-error-input div:first-child {
  box-shadow: #f00 0 0 1em !important;
}

.general-listing .row {
  padding-top: 5px;
  padding-bottom: 10px;
}

.general-listing .form-control {
  border-radius: 5px;
}

.general-listing .input-group-addon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.general-listing .table > thead > tr > td {
  font-weight: bold;
}

.master-data-tax .row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.master-data-tax .form-control {
  border-radius: 5px;
}

.master-data-tax .input-group-addon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.master-data-tax .table > thead > tr > td {
  font-weight: bold;
}

.master-data-tax input[readonly] {
  background-color: white;
}

/*
* master data currency styling
*/
.state_currency h3 {
  padding-top: 15px;
}

.state_currency > .row {
  opacity: 1;
  max-height: 90000px;
  clear: both;
  margin: 1em 2em;
}

.state_currency > .row.ng-hide {
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}

.state_currency h3 {
  font-size: 1.5em;
  margin: 0 0 1em;
}

.state_currency label {
  color: #676A6C;
  font-weight: normal;
  line-height: 2.2;
  text-align: right;
  text-transform: lowercase;
  margin-top: -5px;
}

.state_currency .select-dropdown_width {
  width: 45%;
  float: right;
}

.editable-focus .placeholder {
  text-decoration: none;
  color: #ccc;
  font-style: italic;
}

.editable-focus .pre-wrap {
  white-space: pre-wrap;
}

.editable-focus .google-contact-input, .editable-focus .google-contact-textarea {
  border: 1px solid #EBEAE8;
  padding: 10px;
  outline: none !important;
}

.editable-focus .google-contact-textarea {
  resize: none;
  overflow-y: auto;
  max-height: 200px;
}

.text-show-less-more .pre-wrap {
  white-space: pre-wrap;
}

.text-show-less-more a {
  font-size: 14px;
  font-weight: normal;
}

.currency-decimal-select option:not(:first-child) {
  color: black;
}

/*
*grn detail
*/
.grn-detail .grn-detail-title {
  text-align: left;
}

.grn-detail .grn-detail-body {
  width: 100%;
  position: relative;
}

.grn-detail .grn-detail-left-button {
  float: left;
}

.grn-detail .grn-detail-right-button {
  float: right;
}

.grn-detail .grn-detail-item-text {
  border-bottom: none;
  color: #62a0b2;
}

.grn-detail .col-xs-9 {
  padding-left: 0;
}

.grn-detail .column-index {
  text-align: left;
}

.grn-detail .column-description {
  text-align: left;
  width: 35%;
}

.grn-detail .column-quantity {
  text-align: left;
}

.grn-detail .column-accepted {
  text-align: left;
}

.grn-detail .column-rejected {
  text-align: left;
}

.grn-detail .column-uom {
  text-align: left;
}

/*
*ngrn detail
*/
.ngrn-detail .ngrn-detail-title {
  text-align: left;
}

.ngrn-detail .ngrn-detail-body {
  width: 100%;
  position: relative;
}

.ngrn-detail .ngrn-detail-left-button {
  float: left;
}

.ngrn-detail .ngrn-detail-right-button {
  float: right;
}

.ngrn-detail .ngrn-detail-item-text {
  border-bottom: none;
  color: #62a0b2;
}

.ngrn-detail .col-xs-9 {
  padding-left: 0;
}

.ngrn-detail .column-index {
  text-align: left;
}

.ngrn-detail .column-description {
  text-align: left;
  width: 35%;
}

.ngrn-detail .column-quantity {
  text-align: left;
}

.ngrn-detail .column-accepted {
  text-align: left;
}

.ngrn-detail .column-rejected {
  text-align: left;
}

.ngrn-detail .column-uom {
  text-align: left;
}

.state_BU_add .highlight-error-input input, .state_company_add .highlight-error-input input {
  box-shadow: #f00 0 0 1em !important;
}

.state_BU_add .highlight-error-input div:first-child, .state_company_add .highlight-error-input div:first-child {
  box-shadow: #f00 0 0 1em !important;
}

.state_BU_details h2, .state_industry_details h2 {
  font-weight: bold;
}

.state_BU_details .highlight-error-input input, .state_industry_details .highlight-error-input input {
  box-shadow: #f00 0 0 1em !important;
}

.state_BU_details .highlight-error-input div:first-child, .state_industry_details .highlight-error-input div:first-child {
  box-shadow: #f00 0 0 1em !important;
}

.cost-center-box {
  height: auto;
  max-height: 265px;
  overflow: auto;
}

.user_import_red {
  color: #f00;
}

.state_password_reset .highlight-error-input input {
  box-shadow: #f00 0 0 1em !important;
}

.state_password_reset .highlight-error-input div:first-child {
  box-shadow: #f00 0 0 1em !important;
}

.admin_unlock_user .highlight-error-input input, .admin_change_password .highlight-error-input input, .user_change_password .highlight-error-input input {
  box-shadow: #f00 0 0 1em !important;
}

.admin_unlock_user .highlight-error-input div:first-child, .admin_change_password .highlight-error-input div:first-child, .user_change_password .highlight-error-input div:first-child {
  box-shadow: #f00 0 0 1em !important;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1,
.col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
.col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
.col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
.col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
.col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
.col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
.col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
.col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
.col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
.col-xs-11, .col-sm-11, .col-md-11, .col-lg-11,
.col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: inherit;
}

.edit-payment-method .highlight-error-input input, .edit-payment-term .highlight-error-input input, .add-commodity .highlight-error-input input, .edit-commodity .highlight-error-input input, .modal-content .highlight-error-input input {
  box-shadow: #f00 0 0 1em !important;
}

.edit-payment-method .highlight-error-input div:first-child, .edit-payment-term .highlight-error-input div:first-child, .add-commodity .highlight-error-input div:first-child, .edit-commodity .highlight-error-input div:first-child, .modal-content .highlight-error-input div:first-child {
  box-shadow: #f00 0 0 1em !important;
}

.well {
  padding: 0;
  margin: 0;
}

.well.comment-box {
  border-left: solid 3px #1ab394;
}

.well.comment-box .reply-comment-message {
  border: solid 1px grey;
  border-left: solid 3px #1ab394;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.biggerCheckbox {
  /* 1.5x Checkboxes */
  -ms-transform: scale(1.5);
  /* IE */
  -moz-transform: scale(1.5);
  /* FF */
  -webkit-transform: scale(1.5);
  /* Safari and Chrome */
  -o-transform: scale(1.5);
  /* Opera */
  transform: scale(1.5);
  /*for others */
}

.middle-box .password_reset {
  font-size: 15px;
  font-weight: bold;
  color: #a9acae;
}

.currency-info {
  color: #5D5454;
}

div#navigation-bar-top {
  position: relative;
  z-index: 999;
}

#workspace-dropdown {
  position: relative;
  z-index: 99;
}

#workspace-dropdown div.dropdown {
  position: absolute;
  margin-top: -27px;
  margin-left: -245px;
  width: 240px;
}

#workspace-dropdown div.dropdown div > span {
  padding-right: 25px;
}

#workspace-dropdown input {
  width: 240px;
}

a.star-pr {
  color: #d1dade;
}

.roles.ibox-content {
  height: 15em;
}

/* Security Page */
.roles-list {
  padding: 0 20px 20px 20px;
}

.roles-list .role-wrapper {
  padding: 5px;
}

.roles-list .role-wrapper .role {
  font-size: 14px;
  background: #f2f2f2;
  padding: 10px;
  cursor: pointer;
}

.roles-list .role-wrapper .permission {
  font-size: 14px;
  background: #fbfbfb;
  width: 100%;
  padding: 10px;
  text-align: left;
}

.roles-list .role-wrapper .permission label {
  font-weight: normal !important;
}

.roles-list .permission-list {
  max-height: 300px;
  overflow: auto;
}

h3.widget-font {
  margin-top: 10px;
  font-size: 20px;
}

.revision {
  background: #1CB395;
  padding: 5px 8px;
  margin-left: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: #ffffff;
  vertical-align: super;
  cursor: pointer;
  font-size: 12px;
}

.revision ul > li {
  color: #000;
}

.revision-wrapper .modal-dialog.modal-lg {
  width: 85%;
}

.revision-wrapper del {
  background-color: #ffc6c6;
}

.revision-wrapper ins {
  background-color: #c6ffc6;
  text-decoration: none;
}

.revision-wrapper tr.rev-del {
  background-color: #ffc6c6;
  text-decoration: line-through;
}

.revision-wrapper tr.rev-del table {
  background-color: #ffc6c6 !important;
}

.revision-wrapper tr.rev-del .table .table {
  background-color: #ffc6c6 !important;
}

.revision-wrapper tr.rev-ins {
  background-color: #c6ffc6;
}

.revision-wrapper tr.rev-ins table {
  background-color: #c6ffc6 !important;
}

.revision-wrapper tr.rev-ins .table .table {
  background-color: #c6ffc6 !important;
}

.multiselect-directive .dropdown-menu {
  left: 0 !important;
  right: auto !important;
  overflow-y: scroll !important;
}

.user-manage {
  max-width: 300px;
  word-wrap: break-word;
}

.account-code-dropdown div div .ui-select-toggle {
  padding-right: 22px;
}

.glyphicon {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-remove:before {
  color: #ed5565;
  content: '\f00d' !important;
}

.glyphicon-chevron-left:before {
  content: "\f053";
}

.glyphicon-chevron-right:before {
  content: "\f054";
}

.glyphicon-arrow-left:before {
  content: "\f060" !important;
}

.glyphicon-arrow-right:before {
  content: "\f061" !important;
}

.multiselect-parent .dropdown-menu .glyphicon-ok:before {
  content: "\f00c";
}

.multiselect-parent .dropdown-menu .glyphicon-remove:before {
  content: "\f00d";
}

.account-code-dropdown .ui-select-bootstrap .ui-select-choices-row > a {
  padding: 5px 10px !important;
  white-space: normal !important;
}

.btn-create-pr {
  width: 101px;
  height: inherit;
  border-radius: 2px;
}

.btn-print {
  width: auto;
  height: inherit;
  color: #615f5f;
  background-color: whitesmoke;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.btn-more {
  width: 80px;
  height: inherit;
  background-color: whitesmoke;
  color: #615f5f;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.btn-hold {
  width: 80px;
  height: inherit;
  background-color: #FFD300;
  color: white;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.deletedPR {
  background: red;
  padding: 5px 8px;
  margin-left: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: #ffffff;
  vertical-align: super;
  font-size: 12px;
}

.dropzone-supported-file-div {
  color: red;
  top: 75%;
  width: 100%;
  position: absolute;
}

span.dropzone-supported-file-span {
  display: table;
  margin: 0 auto;
}

.label-blacklist {
  background-color: black;
  color: white;
  margin-right: 10px;
}

.bg-primary {
  background: #1ab394;
}

.bg-red {
  background: #ed5565;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.approval-flow-header .title {
  font-size: 20px;
  color: #615f5f;
}

.approval-flow-header .action-button {
  font-size: 14px;
}

.approval-flow-header .template-rule {
  font-size: 14px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.approval-flow-header dt {
  text-align: left !important;
}

.approval-flow-header .rule-detail {
  font-size: 14px !important;
}

.approval-flow-management {
  background: #ffffff;
  overflow: auto;
  padding: 20px;
  min-height: 300px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.approval-flow-management .approval-flow-main {
  display: inline-flex;
  position: relative;
}

.approval-flow-management .approval-flow-main .circle {
  width: 80px;
  height: 80px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  font-size: 30px;
  padding-top: 20px;
  font-weight: bold;
  text-align: center;
}

.approval-flow-management .approval-flow-main .no-dashed {
  border: none !important;
}

.approval-flow-management .approval-flow-main .box-padding {
  padding: 10px;
}

.approval-flow-management .approval-flow-main .approval-flow-step {
  padding: 20px;
  width: 300px;
  float: left;
  border-right: 1px dashed #c0b9bd;
}

.approval-flow-management .approval-flow-main .approval-flow-step .approval-step-title {
  font-size: 25px;
  color: #CCC8C8;
  text-transform: uppercase;
}

.approval-flow-management .approval-flow-main .approval-flow-step .approval-step-title .pull-right {
  color: #ff6462;
  cursor: pointer;
}

.approval-flow-management .approval-flow-main .approval-flow-step .min {
  font-size: 16px;
  color: #615f5f;
  margin-bottom: 30px;
}

.approval-flow-management .approval-flow-main .approval-flow-step .min-text {
  padding-top: 5px;
  padding-bottom: 5px;
}

.approval-flow-management .approval-flow-main .approval-flow-step .approval-position {
  text-align: left;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 20px;
}

.approval-flow-management .approval-flow-main .approval-flow-step .green-border {
  border: #1ab394 solid 1px;
}

.approval-flow-management .approval-flow-main .approval-flow-step .red-border {
  border: #ed5565 solid 1px;
}

.approval-flow-management .approval-flow-main .approval-flow-step .approval-content {
  padding: 20px 10px;
}

.approval-flow-management .approval-flow-main .approval-flow-step .approval-content .position-users {
  font-size: 14px;
  font-weight: bold;
}

.approval-flow-management .approval-flow-main .approval-flow-step .approval-content .position-users ul {
  padding-left: 20px;
}

.approval-flow-management .approval-flow-main .approval-flow-step .approval-title {
  font-weight: bold;
  font-size: 14px;
  padding: 5px;
}

.item-history-detail {
  background-color: #E0ECEF;
}

.item-history-title {
  word-wrap: break-word;
}

.state_escalation_list tr, .state_online_users tr {
  cursor: pointer;
}

.state_escalation_list .nav-tabs, .state_online_users .nav-tabs {
  margin-bottom: 1em;
}

.state_escalation_list tr:hover .icheckbox_minimal-grey, .state_online_users tr:hover .icheckbox_minimal-grey {
  background-position: -20px 0 !important;
}

.state_escalation_list tr:hover .icheckbox_minimal-grey.checked, .state_online_users tr:hover .icheckbox_minimal-grey.checked {
  background-position: -40px 0 !important;
}

.state_escalation_list tr.no-data, .state_online_users tr.no-data {
  cursor: default;
}

.state_escalation_list thead > tr, .state_online_users thead > tr {
  background-color: #e5e5e5;
  font-weight: bold;
}

.state_escalation_list .star-width, .state_online_users .star-width {
  width: 60px;
}

.state_escalation_list .search-dropdown, .state_online_users .search-dropdown {
  margin: 10px auto 10px auto;
  max-width: 300px;
}

.state_escalation_list .search-dropdown-item, .state_online_users .search-dropdown-item {
  overflow: hidden;
}

.state_escalation_list .no-padding, .state_online_users .no-padding {
  padding: 0px;
  margin: 0px;
}

.state_escalation_list .row-beauty, .state_online_users .row-beauty {
  margin-top: 5px;
  margin-bottom: 5px;
}

.state_escalation_list .fixed-top, .state_online_users .fixed-top {
  z-index: 999;
}

.state_escalation_list .fixed-top.ui-scrollfix, .state_online_users .fixed-top.ui-scrollfix {
  position: fixed;
  top: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
  margin-right: 15px;
}

.state_escalation_list .clear-float, .state_online_users .clear-float {
  clear: both;
}

.state_escalation_list .margin-action, .state_online_users .margin-action {
  margin-left: 1em;
}

.state_escalation_list .editable-wrap, .state_online_users .editable-wrap {
  width: 100%;
}

.loading {
  transition: all linear .9s;
}

.loading-full-width {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(80, 79, 79, 0.58);
  text-align: center;
  padding-top: 25%;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: bold;
  z-index: 999;
}

a.delete-comment {
  color: black;
}

.escalation-modal {
  min-width: 200px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination-template label {
  color: #aaa;
  font-weight: normal;
  text-align: right;
}

.pagination-template .active-page {
  background-color: #1ab394;
  border-color: #1ab394;
  color: white;
}

.shopping-cart .grey-text {
  color: #C8C8C8;
  font-size: small;
}

.underline-item-needed-by {
  border-bottom: 2px solid #ff0000;
}

.highlight-selected-catalog {
  border: 1px solid #222c50;
}

.white-background {
  background-color: white !important;
}

.company-select-currencies ul.dropdown-menu {
  overflow: scroll !important;
  right: initial !important;
}

.preFix-modal-window .modal-dialog {
  width: 1500px;
}

.approval-preview {
  color: #62a0b2;
}

.edit-company .dropdown-multiselect {
  margin-left: -15px;
}

/*
supplier note directive in the PR
*/
.supplier-note .editable-wrap {
  width: 100%;
  position: relative;
  bottom: 5px;
}

.supplier-note .editable-wrap .input-sm {
  font-size: 14px;
  height: 120px;
  padding: 0;
}

.supplier-note .textBox {
  width: 100%;
  height: 120px;
  padding: 0;
  background: none;
  color: grey;
  border: none;
  position: relative;
  bottom: 5px;
  font-size: 14px;
  font-family: inherit;
}

.role-title {
  height: 60px;
  font-size: 20px !important;
}

.on-hold-label {
  font-style: normal;
}

.item-details-commodity ul.commodity-root {
  padding-left: 20px;
}

.item-details-commodity ul {
  list-style-type: none;
  margin: 0 -20px;
}

.details-label {
  margin: 2px 10px 0 0;
  font-style: normal;
}

.item-details-modal .modal-dialog {
  width: 1000px;
}

.approval-role-remove {
  padding-top: 10px;
  cursor: pointer;
}

.limit-lines {
  word-break: break-all;
}

.selection-div {
  max-height: 200px;
  overflow: auto;
}

.tree-selection label {
  font-weight: normal;
}

.tree-selection .parent {
  padding: 5px;
  background-color: #cccccc;
}

.tree-selection .list {
  margin-top: 10px;
}

.audit-td {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.highlight-changes-fields {
  background-color: #ffffce;
}

.remove-commodity-in-supplier {
  float: right;
  position: absolute;
  right: 140px;
  top: 120px;
}

.closed-modal-closed-item-label {
  float: left;
  margin-left: -15px;
  margin-top: 5px;
}

.black-text {
  color: #262626;
}

.grey-text {
  color: #979797;
}

table tbody {
  background: #fff;
}

.jumbo-address {
  font-size: 14px !important;
  font-weight: normal !important;
}

.top-border {
  border-top: solid 1px #c8c8c8;
}

.label-black {
  background-color: #000000;
  color: white;
}

.list-of-supported-extensions {
  color: #ff0000;
}

.receive-goods thead > tr, .reverse-goods thead > tr, .close-po thead > tr {
  background-color: #e5e5e5;
}

.receive-goods-modal .modal-dialog, .reverse-goods-modal .modal-dialog {
  width: 70em;
}

modal-more {
  color: #0b58a2;
}

modal-more:hover {
  text-decoration: underline;
}

.column-receive-goods-description, .column-close-goods-description {
  width: 50%;
}

.column-reverse-goods-description {
  width: 75%;
}

.column-reversed-amount, .column-received-amount {
  width: 5%;
}

.history {
  margin: 30px 0;
}

.horizontal-repeat {
  text-align: center;
  display: inline-block;
}

.horizontal-repeat > div.wrap {
  clear: both;
}

.unsortable-column {
  pointer-events: none;
}

.company-groups-list-table {
  background: white;
  overflow: auto;
  height: 300px;
}

.company-groups-list-table .selected-group {
  background-color: #84b5c3 !important;
  color: white;
}

.upload-file-button {
  border: solid 1px #d1dade;
  padding: 4px 10px;
}

.selectCompaniesGroupText {
  font-weight: bold;
  color: lightgrey;
  align-items: center;
}

.updated-data {
  background: #ffffcc !important;
}

.supplier-current-status {
  color: #1ab394;
  font-weight: bolder;
}

table.user-assignment tr {
  border: 1px solid #84b5c3;
}

table.user-assignment tr th {
  color: white;
  background: #84b5c3;
}

table.user-assignment tr td {
  border-bottom: 1px solid #84b5c3;
}

table.user-assignment tr div {
  padding-top: 5px;
}

.address-panel-list {
  background: white;
  overflow: auto;
  height: 270px;
}

.address-panel-list .selected-address {
  background-color: #84b5c3 !important;
  color: white;
}

.address-panel-submit-button {
  margin-right: 30px;
}

.cost-center-address-checkbox {
  padding-top: 7px;
}

.ui-select-multiple.ui-select-bootstrap {
  min-height: 31px;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  margin-top: 3px;
  white-space: normal;
}

.delegation-title-div {
  margin-bottom: 10px;
}

.onoffswitch-inner:before {
  padding-left: 0px;
  padding-right: 15px;
}

.action-modal .modal-dialog {
  width: 28%;
}

.masterdata-add {
  margin-top: 0px;
  padding-top: 23px !important;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 20px !important;
  background-color: white;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 0 3px silver;
}

.contact-version {
  border-top: 1px solid #d1dade;
  padding-top: 15px;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  color: #39475b;
}

.table-pad tbody tr td {
  padding-left: 0;
}

.search-dropdown-list-and-button .dropdown-menu {
  top: 105%;
  text-transform: uppercase;
}

.search-input-bar {
  width: 270px;
}

.search-input-bar-full {
  height: 100%;
  width: 100%;
}

.stretch-table table {
  width: 100%;
}

.stretch-table tr {
  display: flex;
  align-items: stretch;
}

.stretch-table td:last-child {
  flex: 1;
  display: inline-block;
}

.bg-color-lightgrey {
  background-color: #e5e5e5;
}

.text-center {
  text-align: center;
}

.remove-button-search-bar {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  z-index: 999;
}

.overlay {
  background: white;
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  opacity: 0.5;
  z-index: 10;
}

.overlay #loading-img {
  background: url(assets/images/loader_img.gif) center center no-repeat;
  background-size: 15%;
  height: 100%;
  z-index: 20;
}

.dropdown-menu.item-list {
  position: relative;
  top: 26px;
  left: -40px;
  float: left;
  margin-bottom: -180px;
}

.dropdown-menu.taxes-list {
  left: -120px;
  height: 200px;
  overflow: auto;
  z-index: 1;
}

.dropdown-menu.freight-taxes-list {
  left: 60px;
  top: 20px;
  height: 200px;
  overflow: auto;
  z-index: 1;
  position: relative;
  margin-bottom: -200px;
  margin-left: -160px;
}

.dropdown-menu.create-button {
  max-height: 200px;
  overflow: auto;
}

.highlight-error-input {
  box-shadow: #f00 0 0 1em !important;
}

.grey-border-bottom {
  border-bottom: dashed 1px lightgrey;
  padding-top: 5px;
  padding-bottom: 5px;
}

.grey-border-top {
  border-top: dashed 1px lightgrey;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grey-border-top-solid {
  border-top: solid 1px lightgrey;
  padding-top: 10px;
}

.ui-select-error-highlight div.ui-select-container {
  box-shadow: #f00 0 0 1em !important;
}

tr.tr-no-border td {
  border: none !important;
}

table.multiselect-directive tr {
  border: 1px solid #84b5c3;
}

table.multiselect-directive tr th {
  color: white;
  background: #84b5c3;
}

table.multiselect-directive tr td {
  border-bottom: 1px solid #84b5c3;
}

.ui-select-match-item {
  display: block !important;
  color: black !important;
  background: #EBEAE8 !important;
  text-align: left !important;
}

.ui-select-match-item-position-assign .ui-select-match-item {
  display: inline-block !important;
}

.ui-select-multiple .ui-select-search.ng-hide {
  width: 100%;
  display: inline-block !important;
  cursor: not-allowed;
}

.cart-items {
  display: block;
  position: relative;
  right: 5px;
  top: -5px;
}

.delete-multi-address-button {
  position: relative;
  left: 100px;
}

.side-bar-admin-panel-icon {
  pointer-events: none;
}

.fixHeader {
  height: 100px;
  padding: 10px;
  top: 0;
}

.fixHeaderSupplier {
  padding: 10px;
  top: 0;
}

.uib-datepicker-popup .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.uib-datepicker-popup .btn-default:hover {
  background-color: #e6e6e6;
}

.modal {
  z-index: -999;
}

.modal-backdrop {
  z-index: -999;
}

.modal-content {
  z-index: 999;
}

.sweet-alert {
  z-index: 999999;
}

.ui-select-search {
  width: 100% !important;
}

.selected-option {
  padding: 10px 5px;
  font-weight: bold;
}

#loading-bar {
  z-index: 9999999;
  position: fixed;
}

.receive-goods button.btn-danger {
  display: none;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.masterdata-add button.btn-danger {
  display: none;
}

.modal-body .uib-datepicker-popup button.btn-danger, #tender .uib-datepicker-popup button.btn-danger {
  display: none;
}

.modal-body .show-datepicker-clear-button .uib-datepicker-popup button.btn-danger, #tender .show-datepicker-clear-button .uib-datepicker-popup button.btn-danger {
  display: block;
}

body.mobile .nav-tabs {
  display: -webkit-box;
  display: -moz-box;
  background-color: #1ab394;
  border: transparent;
  height: 40px;
}

body.mobile .nav-tabs > li.active > a, body.mobile .nav-tabs > li.active > a:hover, body.mobile .nav-tabs > li.active > a:focus {
  background-color: #f3f3f4;
  border-color: #dddddd #dddddd transparent;
  border-style: solid;
  border-bottom: transparent;
  border-width: 1px;
  color: #555555;
  border-radius: 12px 12px 0 0;
  font-weight: 800;
  margin: 0 0 -1px -1px;
  bottom: -3px;
  -webkit-box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.16), 0 0 16px 2px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.16), 0 0 16px 2px rgba(0, 0, 0, 0.16);
  box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.16), 0 0 16px 2px rgba(0, 0, 0, 0.16);
}

body.mobile .nav-tabs > li > a {
  padding: 10px 20px 10px 20px;
  /*resize to smaller padding*/
  border: none;
  color: #FFFFFF;
  /*tab wording color*/
  margin-right: 0px;
  /*remove tab right margin*/
  border-radius: 0px 0px 0 0;
  /*remove round top corner*/
  bottom: -3px;
}

body.mobile ul.nav-tabs {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

body.mobile ul.nav-tabs li {
  float: none;
}

body.mobile .hide-scrollbar ::-webkit-scrollbar {
  display: none;
}

body.mobile .show-scrollbar {
  word-wrap: break-word;
}

body.mobile .show-scrollbar ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

body.mobile .show-scrollbar ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

body.mobile .pr-status-access-directly {
  font-size: 10px;
  font-weight: bold;
  color: #f5f5f6;
}

body.mobile .pr-header-prid-mobile {
  font-size: 16px;
  color: #aaa;
  font-weight: 100;
  margin-left: 3px;
  font-weight: bold;
}

body.mobile .pr-header-prtitle-mobile {
  font-size: 12px;
  color: black;
  font-weight: 100;
  margin-left: 3px;
}

body.mobile .po-header-prtitle-mobile {
  font-size: 12px;
  color: black;
  font-weight: 100;
  margin-left: 3px;
  padding-left: 15px;
}

body.mobile .status-label {
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid transparent;
  padding: 2px 2px 2px 2px;
  border-radius: 4px 4px 4px 4px;
  background-color: #f58400;
  font-size: 12px;
}

body.mobile .fa-chevron-down {
  color: #1ab394;
}

body.mobile .fa-chevron-up {
  color: #1ab394;
}

body.mobile .details-td td {
  text-align: left;
  padding-top: 1px;
  padding-left: 20px;
  padding-bottom: 1px;
}

body.mobile .details-td tr {
  border-bottom: 1pt solid lightgrey;
}

body.mobile .center {
  margin: auto;
  width: 90%;
  padding: 10px;
}

body.mobile .modal-title {
  font-size: 20px;
}

body.mobile .action-modal-mobile .modal-dialog {
  width: 90%;
  margin: 40% auto;
}

body.mobile .fa-pause {
  color: orange;
}

body.mobile .jumbotron {
  border-radius: 8px;
  padding: 8px 8px 8px 8px;
  margin-bottom: 10px;
  color: inherit;
}

body.mobile .no-padding-mobile {
  padding: 0 0 0 0;
}

body.mobile .field-name {
  word-wrap: break-word;
  font-weight: bold;
  font-size: 13px;
}

body.mobile .field-value {
  word-wrap: break-word;
  font-size: 12px;
}

body.mobile .grand-total {
  font-size: 1em;
  font-weight: bold;
}

body.mobile .borderless td {
  border: none;
}

body.mobile .grey-border-bottom {
  border-bottom: dashed 1px lightgrey;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
}

body.mobile .btn-pr-action {
  padding: 3px;
}

body.mobile .table-bordered > thead > tr > th,
body.mobile .table-bordered > tbody > tr > th,
body.mobile .table-bordered > thead > tr > td {
  background-color: #39475b;
  color: #f5f5f6;
  border: none;
  text-align: center;
}

body.mobile #page-wrapper {
  padding: 0 0px;
}

body.mobile .double-underlines {
  display: inline-block;
  border-bottom-style: double;
  height: 25px;
}

body.mobile .ibox-content-mobile {
  background-color: white;
  color: inherit;
  padding: 5px 5px 5px 5px;
  border-color: #e7eaec;
  -webkit-border-image: none;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0px;
}

body.mobile .row-no-border td {
  border: none;
  background-color: #1ab394;
  color: white;
  vertical-align: middle;
}

body.mobile .fix-header {
  height: auto;
  padding: 10px;
  top: 0;
}

body.mobile .auto-height {
  height: auto;
}

body.mobile .fixed-footer-div {
  position: fixed;
  z-index: 1000;
  background: #f3f3f4;
  bottom: 0px;
  border-top: 2px solid gainsboro;
}

body.mobile .fixed-table-mobile thead > tr > td, body.mobile .fixed-table-mobile tbody > tr > td, body.mobile .fixed-table-mobile thead > tr > th {
  word-wrap: break-word;
}

body.mobile .font-bold {
  font-weight: bold;
}

body.mobile .border-style-rejected {
  border: 3px solid #ED5565;
}

body.mobile .border-style-approved {
  border: 3px solid #55BB33;
}

body.mobile .status-color-approved {
  background-color: #55BB33;
}

body.mobile .status-color-rejected {
  background-color: #ED5565;
}

body.mobile .status-color-escalated {
  background-color: #F8AC59;
}

body.mobile .status-color-pending {
  background-color: #F8AC59;
}

body.mobile .status-color-default {
  background-color: #FFFFFF;
}

body.mobile .approved-role {
  color: #55BB33;
  font-weight: bold;
  vertical-align: top;
}

body.mobile .approved-role-delegated {
  color: #555555;
  font-weight: normal;
  vertical-align: top;
}

body.mobile .rejected-role {
  color: #ED5565;
  font-weight: bold;
  vertical-align: top;
}

body.mobile .rejected-role-delegated {
  color: #555555;
  font-weight: normal;
  vertical-align: top;
}

body.mobile .delegated-color-default {
  color: #000000;
  font-weight: normal;
}

body.mobile .delegated-color-approved {
  color: #55BB33;
  font-weight: bold;
}

body.mobile .delegated-color-rejected {
  color: #ED5565;
  font-weight: normal;
}

body.mobile .expand-btn {
  float: right;
}

body.mobile .alert-non-catalog-item {
  margin-top: 15px;
}

.remove-padding-main {
  padding: 0px 0px !important;
}

.mobile-bar {
  background-color: #1ab394;
  text-align: center;
  margin-left: 15px;
}

li.nav-header-logo {
  height: 58px;
  background-color: #1ab394;
}

li.nav-header-logo .logo-element-title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  padding: 18px 0;
}

.navbar-text-mobile .mobile-buttons {
  padding: 4px 0;
  margin: 14px 5px 5px 20px;
}

.navbar-text-mobile h1 {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
}

.navbar-text-mobile i {
  font-size: 1.5em;
  color: white;
}

body.mobile-navbar {
  min-width: 360px;
}

body.mobile-navbar .label {
  margin-left: 5%;
  padding: .2em .6em .3em !important;
  white-space: normal !important;
}

body.mobile-navbar .nav {
  min-width: 240px !important;
}

body.mobile-navbar .nav .nav-header {
  padding: 5px 25px 5px 25px !important;
}

body.mobile-navbar .nav-label {
  display: block !important;
  font-size: 12px !important;
}

body.mobile-navbar .profile-element {
  padding: 0 !important;
  text-align: left !important;
}

body.mobile-navbar .profile-element .profile-photo {
  margin: 3% 3% 5% 8%;
}

body.mobile-navbar .navbar-static-side {
  display: block !important;
  width: 60% !important;
  max-width: 240px !important;
}

body.mobile-navbar #page-wrapper {
  margin: 0 -60% 0 240px !important;
}

input.mobile-search-bar {
  background-color: #1ab394;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: thin solid rgba(255, 255, 255, 0.2);
  font-size: 1.5em;
  width: 75%;
  color: white;
}

input.mobile-search-bar::-webkit-input-placeholder {
  font-style: italic;
  color: white !important;
}

input.mobile-search-bar::-moz-placeholder {
  font-style: italic;
  color: white !important;
}

input.mobile-search-bar:-moz-placeholder {
  font-style: italic;
  color: white !important;
}

input.mobile-search-bar:-ms-input-placeholder {
  font-style: italic;
  color: white !important;
}

input.mobile-search-bar:focus {
  outline: none;
}

.supplier-tagging {
  margin-top: 5vh;
}

.vertical-scroll-fixed-height {
  margin-top: 5vh;
  margin-bottom: 5vh;
  height: 50vh;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.horizontal-align {
  margin: 0 auto;
  text-align: center;
}

td.fixed-td-width {
  width: 5vw;
}

.colour-black {
  color: black;
}

.clickable-link {
  color: #62a0b2 !important;
  border-bottom: dashed 1px #428bca !important;
}

.action-modal-text-area {
  width: 100%;
}

.mobile-approval .table-style {
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px #888888;
  box-sizing: border-box;
  margin: 3px 0 3px 15px;
  width: 95%;
  clear: both !important;
}

.mobile-approval .status-label {
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  padding: 0 10px;
  line-height: 150%;
}

.mobile-approval .username {
  padding: 0px 5px;
  font-size: 11px;
  text-align: left;
  vertical-align: top;
}

.mobile-approval .action-date {
  padding: 3px 3px 3px;
  font-size: 9px;
  font-weight: bold;
  text-align: right;
  vertical-align: top;
  line-height: 100%;
}

.tab-content-fixed-popup {
  height: 345px !important;
  overflow-y: auto;
  overflow-x: auto;
}

.width-700 {
  width: 700px;
}

.width-full {
  width: 1000px;
}

.approval-waiting-on {
  margin-left: -15px !important;
  margin-top: 5px !important;
}

.company-list, .budget-list {
  max-height: 150px;
  overflow: auto;
}

.rzslider .rz-pointer {
  background-color: #1ab394;
}

.rzslider .rz-pointer.rz-active:after {
  background-color: white;
}

.budget .no-background {
  background: transparent !important;
}

.budget .waiting-on {
  margin-left: 20px;
  margin-top: 10px;
}

.budget .code-text {
  color: #337ab7;
}

.budget .switch {
  margin-top: 10px;
  margin-bottom: 10px;
}

.budget .section-text {
  font-weight: bold;
  font-size: 20px;
}

.budget .onoffswitch-disabled .onoffswitch-label {
  border: 2px solid lightgrey;
  cursor: not-allowed;
}

.budget .onoffswitch-disabled .onoffswitch-switch {
  border: 2px solid lightgrey;
  cursor: not-allowed;
}

.budget .vertical-align-middle td {
  vertical-align: middle;
}

.budget .vertical-align-middle span {
  font-weight: bold;
  vertical-align: middle;
}

.budget .table-striped th {
  color: white;
  background-color: #84b5c3;
}

.budget .table-striped tfoot {
  font-size: 16px;
}

.budget .table-text-center th {
  text-align: center;
  color: white;
  background-color: #84b5c3;
}

.budget .table-text-center td {
  text-align: center;
}

.budget .table-grey-header {
  margin-bottom: 0;
}

.budget .table-grey-header tr {
  text-align: center;
}

.budget .table-grey-header td {
  text-align: center;
}

.budget .apportion-amount-summary {
  margin-top: 20px;
}

.budget .multiselect-directive .dropdown-menu {
  left: auto !important;
}

.budget label#pagination-label {
  color: #aaa;
  font-weight: normal;
  text-align: right;
  font-size: 13px !important;
  margin-top: 0;
}

.budget .row {
  background-color: white;
}

.budget .approval-waiting-on {
  background-color: inherit !important;
}

.budget .dashboard-title {
  font-weight: bold;
  font-size: 20px;
}

.budget button.apportion-details {
  height: 34px;
}

.budget .text-primary {
  color: #1ab394;
}

.budget .back-buttons {
  display: block;
  margin: 0 1em 1em 0;
  vertical-align: super;
  float: left;
}

.budget .budget-title {
  font-style: normal;
  font-size: 24px;
  color: #262626;
  font-weight: 100;
  cursor: pointer;
  display: inline-block;
  max-width: 50%;
  word-break: break-all;
}

.budget .budget-title-disabled {
  font-style: normal;
  font-size: 24px;
  color: #262626;
  font-weight: 100;
  display: inline-block;
  max-width: 50%;
  word-break: break-all;
}

.budget .accordion-budget-assignment .panel-heading {
  padding: 0;
}

.budget .accordion-budget-assignment .panel-title a {
  display: block;
  padding: 3px 5px;
}

.budget .accordion-budget-assignment .row {
  background-color: transparent;
}

.budget .accordion-budget-assignment .header-font {
  color: grey;
}

.budget .default-cursor thead > tr {
  cursor: default;
}

.budget .default-cursor tr {
  cursor: default;
}

.budget .remove-account-code {
  background-color: white;
  border: none;
  vertical-align: middle;
}

.budget-apportion-details .modal-dialog {
  width: 80%;
}

.state-budget-listing thead > tr {
  background-color: #e5e5e5;
  font-weight: bold;
}

.state-budget-listing tr {
  cursor: pointer;
}

.fa-budget:before {
  background-image: url("/assets/images/budgetIcon.png");
  background-size: 16px 16px;
  display: inline-block;
  width: 16px;
  height: 16px;
  content: '';
}

.text-area-block {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.comment-area-block {
  white-space: pre-line;
  word-wrap: break-word;
}

.terms-and-conditions-class {
  overflow-y: scroll;
  overflow-x: hidden;
}

.iti-flag {
  background: url("../assets/images/flags.png") no-repeat 0px 0px;
}

#dev-info {
  background-color: #1ab394;
  border-radius: 5px;
  font-size: 24px;
  color: #ffffff;
  padding: 3px 9px;
  font-weight: 700;
}

.splitting .row {
  background-color: white;
}

.split-accordion .panel-heading {
  padding: 0;
}

.split-accordion .panel-title {
  font-weight: 400;
}

.split-accordion .panel-title a {
  display: block;
  padding: 3px 5px;
  border: 1px solid lightgrey;
}

.split-accordion .row {
  padding: 3px;
  text-align: left;
  background-color: transparent;
}

.split-accordion .header-font {
  color: #262626;
}

.split-accordion .editable-disabled {
  cursor: not-allowed;
}

.split-accordion input {
  text-align: center;
}

.split-accordion .split-freight-transportation-section {
  padding: 10px;
  border-top: dashed 1px lightgrey;
  border-bottom: dashed 1px lightgrey;
}

.cancel-po-line-item {
  color: #8E240E;
  text-decoration: line-through;
}

.row-odd {
  background-color: #f6f6f6;
}

.intl-tel-input {
  width: 100%;
}

.login-border {
  border-width: 1px;
  border-color: #1dc5a3;
}

.login-border::-webkit-input-placeholder {
  font-style: italic;
  color: #676A6C !important;
}

.login-border:-moz-placeholder {
  font-style: italic;
  color: #676A6C !important;
}

.login-border::-moz-placeholder {
  font-style: italic;
  color: #676A6C !important;
}

.login-border:-ms-input-placeholder {
  font-style: italic;
  color: #676A6C !important;
}

.login-btn {
  color: white;
  background-color: #1ab394;
  border-color: #1ab394;
}

.login-btn.disabled, .login-btn[disabled], .login-btn[disabled]:hover {
  opacity: 1;
  background-color: #1ab394;
  border-color: #1ab394;
}

.login-btn:hover, .login-btn:focus {
  color: white;
  background-color: #18a689;
  border-color: #18a689;
}

.line-item-color {
  color: #0366d6;
}

.line-item-text-show-less-more a {
  color: #0366d6;
}

.line-item-text-show-less-more a:hover {
  color: #00060d;
}

.line-item-background {
  background-color: #e3e3e3;
}

.line-item-background .panel-group .panel {
  background-color: #e3e3e3;
}

.line-item-background .split-accordion .panel-title a {
  border: 1px solid #c5c5c5;
}

.line-item-background .panel-group .panel-heading + .panel-collapse > .panel-body, .line-item-background .panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #a7a7a7;
}

.line-item-background .split-accordion .split-freight-transportation-section {
  border-top: dashed 1px #a7a7a7;
  border-bottom: dashed 1px #a7a7a7;
}

.font-italic-bold {
  font-style: italic;
  font-weight: bold;
}

.fa-percentage:before {
  background-image: url("/assets/images/icon-percentage.png");
  background-size: 60px 60px;
  display: inline-block;
  width: 60px;
  height: 60px;
  content: '';
}

.budget-utilization {
  border: 0;
}

.budget-utilization .panel-heading {
  background-color: #e5e5e5;
}

.budget-header-field {
  padding-right: 50px;
}

body {
  color: #262626;
}

.user-setting-font-weight-bold * {
  font-weight: bold !important;
}

.mapping-warning-block {
  font-size: 18px;
}

.blockDoReversal {
  background: lightgrey;
}

.dropzone {
  min-height: 15vh !important;
  max-height: 15vh !important;
}

.dropzone .dz-default.dz-message {
  background-image: none !important;
  width: 100% !important;
  height: auto !important;
  margin: 0 auto !important;
  left: 0 !important;
  top: 25% !important;
}

.supplier-web-terms-and-conditions {
  position: relative;
  width: 200%;
  right: 50%;
}

.terms-and-conditions-confirmation {
  padding: 5%;
}

.view-terms-and-conditions-link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.supplier-web-body {
  position: relative;
  min-height: 100%;
  padding-bottom: 5%;
}

.dropdown-overflow-scrollable > ul {
  max-height: 350px;
  overflow: auto;
}

.btn-outline-primary {
  width: 150px;
  border: solid 1px #d1dade;
  height: 40px;
  background: none;
  color: #d1dade;
  margin-top: 10px;
  text-align: center;
}

.quoted-message {
  font-size: 12px !important;
}

.reply-comment-box {
  padding-left: 10px !important;
  border-left: solid 3px #1ab394;
}

.log-viewer .exclamation-triangle {
  color: red;
}

.company-history {
  table-layout: fixed;
  word-wrap: break-word;
}

.role-assignment-width {
  min-width: 55%;
  max-width: 55%;
  word-wrap: break-word;
}

.user-role-superior {
  min-width: 30%;
  width: 300px;
  max-width: 30%;
}

.role-assignments-buttons {
  padding-top: 10%;
}

.costCenterBox {
  width: auto;
}

.costCenterTextWrap {
  white-space: normal;
}

.no-data {
  height: 40px;
}

.faq-body-list li {
  margin: 7px 0;
}

.faq-red {
  color: red;
}

.faq-green {
  color: #1ab394;
}

.faq-table-light-green tr th {
  background-color: #1ab394 !important;
  color: whitesmoke;
}

.faq-table-padding tr th {
  padding: 20px !important;
}

.faq-li-list {
  list-style: none;
  font-weight: bold;
}

.padding-top-10 {
  padding-top: 10px;
}

.qty-button {
  width: 25px;
}

.catalog-panel-pre-change {
  margin-top: 0;
}

.catalog-panel-post-change {
  margin-top: 4%;
}

.advanced-search-table {
  max-height: 75vh;
  overflow: auto;
}

thead > tr > td, th {
  background-color: #e5e5e5;
}

.ui-select-choices li div {
  white-space: normal !important;
}

.ui-select-bootstrap .ui-select-choices-row:not(.active) > span:hover {
  background-color: #e5e5e5;
}

.pa-note-textarea textarea {
  color: grey;
  resize: vertical;
  height: 130px;
  min-height: 100px;
  overflow-y: auto;
  width: 100%;
  border: 1px solid silver;
  background-color: white;
  padding: 7px;
  margin: 5px;
}

.pa-note-textarea textarea:hover {
  border: 1px solid silver;
  color: grey;
}

tr.right-clickable td a:not([name="poNumLink"]):not([name="prNumLink"]):not([name="delete-button"]) {
  color: #333333;
}

tr.right-clickable td a:not([name="poNumLink"]):not([name="prNumLink"]):not([name="delete-button"]):hover {
  color: grey;
}

tr.right-clickable td {
  overflow: hidden;
}

tr.right-clickable td > a:not([name="delete-button"]) {
  display: block;
  margin: -10em;
  padding: 10em;
}

.child-span-60 > span {
  width: 60%;
}

.highcharts-drillup-button text tspan {
  fill: #ffffff !important;
}

.highcharts-drillup-button:hover > text tspan {
  fill: #000000 !important;
}

.pr-tabs-width-size {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.advanced-search-button {
  margin-left: 5px !important;
  border-radius: 4px !important;
  background-color: white;
  color: #329D9C;
  border-color: #329D9C;
  text-transform: uppercase;
  font-size: 14.2px;
}

.advanced-search-button:hover, .advanced-search-button:focus, .advanced-search-button:active, .advanced-search-button.active,
.advanced-search-button .open {
  background-color: white;
  color: black;
  border-color: #329D9C;
}

.advanced-search-catalog-button {
  float: left;
}

.fs-15 {
  font-size: 15px;
}

.disable-ie-reset-button::-ms-clear {
  display: none;
}

.view-address-history {
  float: right;
  font-size: 14px;
  margin: 20px 0px -20px 0px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.supplier-contract-modal .modal-dialog {
  width: 65vw;
}

.update_all_contracts {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.supplier-status-tag-position {
  margin-left: 20px;
  margin-top: 5px;
}

.catalog-categories .breadcrumb {
  background-color: transparent;
}

.catalog-categories .breadcrumb li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.catalog-categories .breadcrumb > li {
  display: block;
}

.catalog-categories .breadcrumb > li + li {
  padding-left: 15px;
}

.catalog-categories .breadcrumb > li + li + li {
  padding-left: 30px;
}

.catalog-categories .breadcrumb > li + li + li + li {
  padding-left: 45px;
}

.catalog-categories .breadcrumb > li + li + li + li + li {
  padding-left: 60px;
}

.catalog-categories .breadcrumb > li a:hover {
  color: #1c84c6;
}

.catalog-categories .breadcrumb > li + li::before {
  content: '';
  padding: 0;
  color: inherit;
}

.catalog-categories .breadcrumb > li > b + ul {
  list-style: none;
  padding-left: 15px;
}

.catalog-categories .all-categories {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.catalog-categories .all-categories .panel {
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}

.catalog-categories .all-categories .panel-default > .panel-heading {
  background-color: #f5f5f5;
}

.catalog-categories .all-categories h2 {
  margin: 0;
  font-size: 1.2em;
  line-height: 1.2;
}

@media (min-width: 990px) {
  .col-md-2.ui-scrollfix {
    position: fixed;
    top: 20px;
    margin-right: 16px;
    background-color: white;
  }
  .standalone-catalog-item-view {
    float: right;
    width: 100%;
  }
}

.popup-btn-link {
  color: #4a8697;
  font-weight: bold;
  font-size: 10px;
}

.popup-btn-link:hover {
  color: #4a8697;
  font-weight: bold;
  font-size: 10px;
  text-decoration: underline;
}

.modal-header-less-padding {
  padding: 5px;
}

.item-form-header {
  height: 100%;
  padding: 37px 37px 37px 0;
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: 0.38px;
  text-align: left;
  color: black;
}

.item-input {
  border-radius: 5px;
}

.item-upload {
  background-color: #f0f9ff;
  border: 1.5px #93a1aa dashed;
  border-radius: 5px;
  height: 100%;
}

.page {
  transition: 0.3s;
  padding: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page.ng-enter {
  top: -100%;
  bottom: 100%;
}

.page.ng-enter.ng-enter-active {
  top: 0;
  bottom: 0;
}

.page.ng-leave {
  top: 0;
}

.page.ng-leave.ng-leave-active {
  top: 100%;
  bottom: -100%;
}

.item-form-modal .modal-dialog {
  width: 90em;
}

.input-group {
  width: 100%;
  float: left;
}

.input-group-border {
  width: 0px;
  height: 20px;
  border-right: 1px solid grey;
  margin-top: 7.25px;
  margin-bottom: 7.25px;
  float: left;
  position: relative;
  z-index: 100;
}

.input-group-btn {
  left: -1px;
}

.noOfOtherSuppliers {
  width: auto;
  float: left;
  color: blue;
  text-decoration: underline;
}

.item-form-header {
  height: 100px;
  background-color: rgba(32, 80, 114, 0.16);
  padding-bottom: 30px;
}

.item-form-title {
  width: 255px;
  height: 29px;
  font-family: 'SF Display Bold';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.3px;
  text-align: left;
}

.non-catalog-item-form-title {
  height: 29px;
  font-family: 'SF Display Bold';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.3px;
  text-align: left;
}

.item-form-opex-noncatalog-nav-icon {
  margin: auto;
  width: 10%;
  padding: 10px;
}

.item-form-opex-noncatalog-nav-text {
  margin: auto;
  width: 20%;
  padding: 10px;
}

.consolidation-input-search-bar .form-control,
.consolidation-input-search-bar .form-control:focus {
  width: 200px;
}

.consolidation-clear-form {
  color: #329d9c;
  font-weight: 600;
  cursor: pointer;
}

.consolidation-search-bar {
  border-left: 1px solid #329d9c;
  border-top: 1px solid #329d9c;
  border-bottom: 1px solid #329d9c;
  border-radius: 3px 0 0 3px;
}

.po-action-btn {
  border-radius: 3px;
}

.po-action-icon-white {
  color: white;
  margin-right: 8px;
}

.po-gen-vertical-center {
  display: flex;
  align-items: center;
}

.po-gen-header {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .po-gen-header .border-right {
    border-right: solid 1px #d5d7db;
  }
}

.po-gen-header ul {
  list-style-type: none;
}

.po-gen-header label {
  color: #676A6C;
  font-weight: 600;
}

.po-gen-header li a {
  color: #329d9c;
  font-weight: bold;
  text-decoration: underline;
}

.po-gen-header h3 {
  color: #19193e;
}

.po-gen-header .row {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .consolidation-confirmation-modal .modal-dialog {
    width: 75%;
  }
  .consolidation-confirmation-modal .preview-column-width {
    max-width: 300px;
  }
  .consolidation-confirmation-modal .border-bottom-solid {
    border-bottom: solid 1px lightgray;
  }
}

.consolidation-item-list-card {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-top: 20px;
  padding: 10px;
}

.consolidation-items tr {
  cursor: default;
}

.consolidation-items .mt-8 {
  margin-top: 8px;
}

.consolidation-items .grey-dashed-row td {
  border-top: dashed 1px lightgrey;
  border-bottom: dashed 1px lightgrey;
}

.consolidation-items .add-foc-modal {
  background-color: rgba(32, 80, 114, 0.16);
  padding: 15px;
}

.consolidation-items .consolidation-items-button {
  color: #329d9c;
  padding: 5px 10px;
  font-weight: bold;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.consolidation-items .duplicate-foc-button {
  color: #329d9c;
  padding: 5px 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.consolidation-items .consolidation-item-list-card {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-top: 20px;
  padding: 10px;
}

.consolidation-items .right-inner-icon {
  position: relative;
}

.consolidation-items .right-inner-icon input {
  padding-right: 30px;
}

.consolidation-items .right-inner-icon span {
  position: absolute;
  right: 0px;
  padding: 6px 12px;
}

.consolidation-items .right-inner-icon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  color: red;
  pointer-events: none;
}

.consolidation-items .quantity-column {
  width: 85px;
}

.consolidation-items .discount-column {
  width: 70px;
}

.consolidation-items .highlight-tolerance-error {
  border: solid 1px #ed5565;
}

.consolidation-items .form-control {
  border-radius: 4px;
}

.consolidation-items .input-tax {
  width: auto;
}

.consolidation-items .label {
  color: white;
  display: block;
  width: 100px;
  padding: 8px 20px;
  margin-bottom: 8px;
}

.consolidation-items .panel-group {
  margin: 0;
}

.consolidation-items .other-charges-accordion {
  margin: 0px 10px 0 10px;
  background-color: #f8f8fc;
  border: none;
}

.consolidation-items .other-charges-accordion .panel-heading {
  background-color: #f8f8fc;
  color: #56c596;
}

.consolidation-items .other-charges-accordion ul {
  padding: 0px;
  list-style: none;
}

.consolidation-items .other-charges-accordion ul > li {
  border-top: solid 1px #efeff9;
  padding: 5px 0 5px 20px;
  cursor: pointer;
}

.consolidation-items .other-charges-accordion ul > li > a {
  border-radius: 3px;
  color: black;
  line-height: 25px;
  margin: 4px;
  text-align: left;
  font-weight: normal;
}

.consolidation-items .other-charges-accordion .panel-body {
  border-top: none !important;
  padding: 0;
}

.consolidation-items .other-charges-accordion .panel-title > a:focus {
  outline: none;
}

.consolidation-items .dropdown-menu.item-list {
  position: relative;
  width: 100%;
  float: left;
  margin-bottom: -300px;
  padding: 10px 0 10px 0;
  top: 0;
  left: 0;
}

.label-green {
  background-color: #56c596;
}

.label-red {
  background-color: #ff4858;
}

.label-orange {
  background-color: #f3a300;
}

.label-purple {
  background-color: #9400D3;
}

.po-search-border {
  border-radius: 3px 0px 0px 3px !important;
}

.consolidation-list .clear-floating {
  clear: none;
}

.consolidation-list-status {
  color: #56c596;
  margin-top: 7px;
  margin-right: 5px;
  font-weight: bold;
}

.consolidation-list-status span {
  color: #184153;
}

.quotation-list {
  display: flex;
  margin-left: 10px;
}

.display-inline-block {
  display: inline-block;
}

.display-inline {
  display: inline;
}

.display-block {
  display: block;
}

.quotation-item-header {
  color: white;
  background-color: #39475b;
  min-height: 75px;
}

.title-margin {
  margin-top: 7px;
  margin-right: 5px;
}

.pr-item-label-catalog {
  color: #0b58a2;
}

.pr-item-label-foc {
  color: #f3a300;
}

.pr-item-label-stock {
  color: #0E997D;
}

.po-consolidation-status {
  color: #0b58a2;
  font-weight: bold;
}

.create-po-button {
  margin: 25px;
}

div.non-catalog-attachment-box {
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.03);
  padding: 23px;
  min-height: 15vh;
  max-height: 15vh;
}

div.non-catalog-attachment {
  background-color: white;
  color: inherit;
  padding: 15px 20px 20px 15px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0px;
}

.quotation-table tr td,
.quotation-table th {
  width: 300px;
}

.quotation-table tr.border-less > th:first-child {
  border-top: 0px;
  border-left: 0px;
  background-color: #f3f3f4;
}

.quotation-table .quotation-details tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.quotation-table .quotation-border {
  border: 1px solid #e5e6e7;
  border-radius: 3px;
  padding-top: 4px;
  padding-bottom: 6px;
}

.quotation-table .quotation-details tr {
  border-top: solid 1px lightgray;
  border-bottom: solid 1px lightgray;
  padding: 5px;
  font-weight: bold;
}

.quotation-table .quotation-details-data tr:last-child td > div {
  height: 330px;
  overflow-y: scroll;
}

.quotation-table .quotation-details tr td {
  padding: 10px 30px 10px 30px;
  height: 57px;
}

.quotation-table .unselected-quotation {
  background-color: #39475b;
  color: white;
  margin: auto;
  padding: 20px;
  font-weight: bold;
  text-align: center;
}

.quotation-table .selected-quotation {
  background-color: #56c596;
  color: white;
  margin: auto;
  padding: 20px;
}

.quotation-table .quotation-border-background {
  border-bottom: solid 4px #f3f3f4;
}

.quotation-table .quotation-border-background:not(:last-child) {
  border-right: solid 2px #f3f3f4;
}

.quotation-table .quotation-details-header > td,
.quotation-table .quotation-details-header > th {
  border-right: solid 2px lightgray;
}

.quotation-table .quotation-details-row > td,
.quotation-table .quotation-details-row > th {
  border-right: solid 2px lightgray;
  border-bottom: solid 4px #f3f3f4;
}

.quotation-table .quotation-delete-btn {
  background-color: white;
  border-color: white;
  color: #ec4758;
}

.quotation-table .quotation-item-number {
  border: solid 1px white;
  background-color: white;
  border-radius: 50%;
  padding: 2px 7px;
  color: #39475b;
}

.quotation-table .quotation-datepicker tr td {
  padding: 0;
  height: 0;
}

.quotation-table td.quotation-validity {
  padding: 10px 30px 10px 30px !important;
  height: 114px !important;
}

.quotation-table .quotation-add-button {
  color: #329d9c;
  font-size: 35px;
}

.quotation-table .quotation-word-warp {
  width: 280px;
  word-wrap: break-word;
}

.quotation-table .dropdown-menu.item-list {
  position: relative;
  top: 0px;
  left: 0px;
  float: left;
  margin-bottom: -180px;
}

.quotation-table .text-white {
  color: white;
}

.quotation-table .quotation-search-bar {
  background-color: inherit;
  color: white;
  border-radius: 3px;
}

.quotation-table .quotation-input-width {
  max-width: 238px;
}

.quotation-table .quotation-delete-icon {
  border: solid 1px white;
  background-color: white;
  border-radius: 50%;
}

.quotation-table .right-inner-icon {
  position: relative;
}

.quotation-table .right-inner-icon input {
  padding-right: 30px;
}

.quotation-table .right-inner-icon span {
  position: absolute;
  right: 0px;
  padding: 6px 12px;
}

.quotation-table .right-inner-icon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  color: white;
  pointer-events: none;
}

.quotation-table .vertical-align-top {
  vertical-align: top;
  border-top: 4px #f3f3f4 solid;
}

.quotation-other-details-header {
  color: white;
  background-color: #39475b;
  padding: 10px;
  margin-top: 20px;
}

.quotation-other-details-header h2 {
  font-weight: 600;
  margin: 0;
}

.vertical-horizontal-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-dark-gray {
  background-color: gray;
}

.filter-white {
  filter: brightness(0) saturate(100%) invert(1);
}

.cerf-form span,
.adtaf-form span {
  word-break: break-word;
}

.cerf-form .form-group {
  height: 57px;
}

.adtaf-form .form-group {
  height: 57px;
}

.cerf-form .asterisk-required,
.adtaf-form .asterisk-required {
  color: red;
  font-size: 13px;
}

.item-form-title-no-width {
  height: 29px;
  font-family: 'SF Display Bold';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.3px;
  text-align: left;
}

.background-grey {
  background-color: #f5f5f5;
}

.company-item-details .back-buttons {
  display: block;
  margin: 0 1em 1em 0;
  vertical-align: super;
  float: left;
}

.switch-toggle {
  /* Rounded sliders */
}

.switch-toggle .switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

.switch-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-toggle .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-toggle input:checked + .slider {
  background-color: #00875a;
}

.switch-toggle input:focus + .slider {
  box-shadow: 0 0 1px #00875a;
}

.switch-toggle input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.switch-toggle .slider.round {
  border-radius: 34px;
}

.switch-toggle .slider.round:before {
  border-radius: 50%;
}

.switch-toggle .disabled-toggler {
  opacity: 0.5;
}

.po-detail-header h3 {
  text-align: left;
}

.po-detail-header .jumbotron {
  font-size: inherit;
  padding: 40px;
}

.po-detail-header label {
  text-align: left;
}

.general-action-btn {
  padding: 12px 16px;
  border-style: none;
  margin-left: 1em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
}

.btn-hold {
  background-color: #6e6e6e;
  color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-color: #6e6e6e;
  border-radius: 7px;
}

.btn-hold:hover {
  background-color: #868585;
  border-color: #868585;
  color: white;
}

.btn-hold:active, .btn-hold:focus {
  background-color: #585757;
  border-color: #585757;
  color: white;
}

.text-white {
  color: white;
}

.display-flex {
  display: flex;
}

.remarks-textbox {
  margin: 0px -6px 0px 0px;
  width: 500px;
  height: 93px;
}

.text-bold {
  font-weight: bold;
}

.alternate-row {
  background-color: #f5f6fa;
}

.budget-select-cost-center ul.dropdown-menu {
  overflow: scroll !important;
  right: initial !important;
}

.budget-select-cost-center .dropdown-header {
  padding-top: 10px;
}

.vertical-align-flex {
  vertical-align: middle;
  display: flex;
}

.pr-line-item-btn {
  text-decoration: none;
  color: #428bca;
  border-bottom: dashed 1px #428bca;
}

.text-green {
  color: #329d9c;
}

.auto-alternate-row:nth-child(odd) {
  background-color: #f5f6fa;
}

.auto-alternate-row {
  padding: 5px;
}

.approval-border-background {
  border-bottom: solid 4px black;
}

.approval-border-side {
  border: 3px solid black;
  text-align: center;
  vertical-align: top;
}

.company-detail-buttons {
  height: 115px;
}

.sage-modal-header {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e9e9e9;
  min-height: 100px;
}

.custom-budget-import-btn .btn-xs {
  font-size: 14px;
  padding: 6px 12px;
  margin-right: 0px !important;
  line-height: 1.42857;
}

.ul-type-none {
  list-style-type: none;
  padding-left: 10px;
}

.white-bg-tooltip .tooltip > .tooltip-inner {
  background-color: white;
  color: black;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.white-bg-tooltip .tooltip.top .tooltip-arrow {
  border-top-color: white !important;
}

.auto-overflow-horizontal {
  overflow-x: auto;
}

.auto-width {
  width: auto !important;
}

.bottom-border-solid {
  border-bottom: solid 1px lightgray;
  padding: 6px 10px;
}

#scrollable-dropdown-menu .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.align-item-center {
  display: flex !important;
  align-items: center !important;
}

.border-lightgray {
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
}

.inherit-width {
  width: inherit;
}

.custom-white-icheck .icheckbox_minimal-grey {
  background-color: white;
}

.budget-amount-summary-card {
  border-radius: 4px;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.16);
  padding: 10px;
}

.pointer-cursor {
  cursor: pointer;
}

.audit-history-actions {
  color: #1ab394;
}

.audit-history-bottom-border-solid {
  border-bottom: solid 1px lightgray;
}

.price-comparison-table tr td,
.price-comparison-table th {
  width: 400px;
  display: inline-block;
}

.price-comparison-table .header {
  height: 320px;
  border-bottom: solid 1px lightgray !important;
}

.price-comparison-table .comparison-detail-row {
  height: 300px;
  border-bottom: solid 1px lightgray;
}

.price-comparison-table .foreign-currency td {
  width: 200px;
}

.price-comparison-table .foreign-currency td:first-child {
  border-right: solid 1px lightgray;
}

.price-comparison-table .item-details-table {
  display: table;
}

.price-comparison-table .item-details-table tr td {
  width: 150px;
}

.price-comparison-table .tax-row td {
  height: 92px !important;
}

.price-comparison-table tr.border-less > th:first-child {
  border-top: 0px;
  border-left: 0px;
  background-color: #f3f3f4;
}

.price-comparison-table .price-comparison-border {
  border: 1px solid #e5e6e7;
  border-radius: 3px;
  padding-top: 4px;
  padding-bottom: 6px;
}

.price-comparison-table .price-comparison-details tr {
  border-bottom: solid 1px lightgray;
  padding: 5px;
  font-weight: normal;
  border-left: solid 1px lightgray;
}

.price-comparison-table .price-comparison-detail tr:not(:first-child) {
  height: 410px;
}

.price-comparison-table .price-comparison-item-detail {
  width: 250px !important;
  margin-top: 57px;
  border-top: solid 1px lightgray;
}

.price-comparison-table .price-comparison-item-labels {
  width: 150px !important;
  padding: 0 !important;
  margin-top: 57px;
  border-top: solid 1px lightgray;
}

.price-comparison-table .price-comparison-details-data tr:last-child td > div {
  height: 330px;
  overflow-y: scroll;
}

.price-comparison-table .price-comparison-details tr td {
  padding: 10px 30px 10px 30px;
  height: 57px;
}

.price-comparison-table .price-comparison-select-btn {
  padding: 9px 16px;
  border-style: none;
  margin-left: 1em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
}

.price-comparison-table .unselected-price-comparison {
  background-color: #39475b;
  color: white;
  margin: auto;
  padding: 20px;
  font-weight: bold;
  text-align: center;
}

.price-comparison-table .selected-price-comparison {
  background-color: #56c596;
  color: white;
  padding: 10px 10px 10px 30px;
}

.price-comparison-table .selected-price-comparison h4 {
  margin: 0;
}

.price-comparison-table .price-comparison-other-details-header {
  color: white;
  background-color: #39475b;
  padding: 10px 10px 10px 30px;
  margin-top: 20px;
}

.price-comparison-table .price-comparison-other-details-header h2 {
  font-weight: 600;
  margin: 0;
}

.price-comparison-table .price-comparison-other-details-supplier {
  height: 80px !important;
}

.price-comparison-table .price-comparison-total-header {
  color: white;
  background-color: gray;
  padding: 10px 10px 10px 30px;
  margin-top: 20px;
}

.price-comparison-table .price-comparison-total-header h4 {
  font-weight: 600;
  margin: 0;
}

.price-comparison-table .price-comparison-border-background {
  border-bottom: solid 4px #f3f3f4;
}

.price-comparison-table .price-comparison-border-background:not(:last-child) {
  border-right: solid 1px #f3f3f4;
}

.price-comparison-table .price-comparison-details-header > td,
.price-comparison-table .price-comparison-details-header > th {
  border-right: solid 1px lightgray;
}

.price-comparison-table .price-comparison-details-row,
.price-comparison-table .price-comparison-details-row {
  border-left: solid 1px lightgray;
}

.price-comparison-table .price-comparison-delete-btn {
  background-color: white;
  border-color: white;
  color: #ec4758;
}

.price-comparison-table .price-comparison-item-number {
  border: solid 1px white;
  background-color: white;
  border-radius: 50%;
  padding: 2px 7px;
  color: #39475b;
}

.price-comparison-table .price-comparison-datepicker tr td, .price-comparison-table tr th {
  padding: 0;
  height: 0;
  width: auto;
  display: table-cell;
  height: auto;
}

.price-comparison-table td.price-comparison-validity {
  padding: 10px 30px 10px 30px !important;
  height: 114px !important;
}

.price-comparison-table .price-comparison-add-button {
  color: #329d9c;
  font-size: 35px;
}

.price-comparison-table .price-comparison-word-warp {
  width: 280px;
  word-wrap: break-word;
}

.price-comparison-table .dropdown-menu.item-list {
  position: relative;
  top: 0px;
  left: 0px;
  float: left;
  margin-bottom: -180px;
}

.price-comparison-table .text-white {
  color: white;
}

.price-comparison-table .price-comparison-search-bar {
  background-color: inherit;
  color: white;
  border-radius: 3px;
}

.price-comparison-table .price-comparison-input-width {
  max-width: 238px;
}

.price-comparison-table .price-comparison-delete-icon {
  border: solid 1px white;
  background-color: white;
  border-radius: 50%;
}

.price-comparison-table .right-inner-icon {
  position: relative;
}

.price-comparison-table .right-inner-icon input {
  padding-right: 30px;
}

.price-comparison-table .right-inner-icon span {
  position: absolute;
  right: 0px;
  padding: 6px 12px;
}

.price-comparison-table .right-inner-icon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  color: white;
  pointer-events: none;
}

.price-comparison-table .vertical-align-top {
  vertical-align: top;
  border-top: 4px #f3f3f4 solid;
}

.price-comparison-table .currency-split {
  border-right: solid 1px lightgray;
}

.overflow-x-inline-block {
  overflow-x: scroll;
  white-space: nowrap;
}

.modal-overflow-y-scroll {
  height: 600px;
  overflow-y: scroll;
}

.no-available {
  text-align: center;
  margin: 250px auto;
}

.preview-price-comparision-box {
  margin-top: 20px;
}

.preview-price-comparision-content, .preview-price-comparision-content td {
  background-color: rgba(32, 80, 114, 0.16);
}

.pc-label {
  margin-right: 10px;
  margin-bottom: 5px;
}

[uib-typeahead-popup].dropdown-menu {
  display: block;
  max-height: 250px;
  overflow-y: auto;
}

.float-right {
  float: right;
}

.fix-header-pr-items {
  width: calc(100% + 20px);
  padding-top: 10px;
  margin-left: -10px;
  margin-bottom: -30px;
}

.state_faq_list thead > tr {
  background-color: #e5e5e5;
  font-weight: bold;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-forget-password {
  font-size: 17px;
  font-weight: bold;
}
